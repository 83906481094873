import { useSafeCallback } from '@atomica.co/components';
import { FetchUserByExternalIdRequest, SaveUserRequest, User, UserId } from '@atomica.co/irori';
import { builder, Email, EMPTY } from '@atomica.co/utils';
import firebase, { auth } from '../../firebase';
import UserRequest from '../../requests/user-request';
import { toProviderId } from '../../utils/user-util';

interface P {
  getFirebase: () => Promise<firebase.User | null>;
  existFirebase: () => Promise<boolean>;
  getUser: () => Promise<User | undefined>;
  existUser: () => Promise<boolean>;
  createNonActivatedUserIfNotExisted: (firebase: firebase.User, email: Email) => Promise<UserId | undefined>;
  saveActivatedUser: (userToSave: User) => Promise<UserId | undefined>;
}

function useUser() {
  const getFirebase = useSafeCallback(async (): Promise<firebase.User | null> => {
    return await new Promise(resolve => {
      const unsubscribe = auth.onAuthStateChanged(firebaseUser => {
        resolve(firebaseUser);
        unsubscribe();
      });
    });
  }, []);

  const existFirebase = useSafeCallback(async (): Promise<boolean> => {
    const firebase = await getFirebase();
    return !!firebase && !!firebase.uid;
  }, [getFirebase]);

  const getUser = useSafeCallback(async (): Promise<User | undefined> => {
    const firebase = await getFirebase();
    if (!firebase) return undefined;
    const request = builder<FetchUserByExternalIdRequest>().externalId(firebase.uid).build();
    const response = await UserRequest.fetchUserByExternalId(request);
    return response.user;
  }, [getFirebase]);

  const existUser = useSafeCallback(async (): Promise<boolean> => {
    const user = await getUser();
    return !!user && !!user.userId;
  }, [getUser]);

  const createNonActivatedUserIfNotExisted = async (
    firebase: firebase.User,
    email: Email
  ): Promise<UserId | undefined> => {
    await firebase.updateEmail(email);

    const fetchRequest = builder<FetchUserByExternalIdRequest>().externalId(firebase.uid).build();
    const fetchResponse = await UserRequest.fetchUserByExternalId(fetchRequest);
    if (!!fetchResponse.user) return fetchResponse.user.userId;

    const saveRequest = builder<SaveUserRequest>()
      .externalId(firebase.uid)
      .email(email)
      .isActivated(false)
      .familyName(EMPTY)
      .firstName(EMPTY)
      .phone(firebase.phoneNumber || EMPTY)
      .photoURL(firebase.photoURL || EMPTY)
      .providerId(toProviderId(firebase))
      .build();

    const saveResponse = await UserRequest.saveUser(saveRequest);
    return saveResponse.userId;
  };

  const saveActivatedUser = async (userToSave: User): Promise<UserId | undefined> => {
    const firebase = await getFirebase();
    if (!firebase) return;

    const email = !!userToSave.email
      ? userToSave.email
      : !!firebase.email
      ? firebase.email
      : firebase.providerData.filter(data => !!data && !!data.email).map(data => data!.email)[0];

    const request = builder<SaveUserRequest>()
      .userId(userToSave.userId)
      .externalId(firebase.uid)
      .email(email!)
      .isActivated(true)
      .startDate(userToSave.startDate!)
      .endDate(userToSave.endDate!)
      .familyName(userToSave.familyName!)
      .firstName(userToSave.firstName!)
      .campanyName(userToSave.companyName!)
      .dateOfBirth(userToSave.dateOfBirth!)
      .phone(userToSave.phone!)
      .photoURL(userToSave.photoURL || EMPTY) // メアド認証の場合nullのままなので
      .faceRecognitionPhotoURL(userToSave.faceRecognitionPhotoURL!)
      .faceFeatureStr(userToSave.faceFeatureStr! as string)
      .postalCode(userToSave.postalCode!)
      .prefecture(userToSave.prefecture!)
      .address(userToSave.address!)
      .category(userToSave.category!)
      .inflowSource(userToSave.inflowSource!)
      .dateOfAgreement(userToSave.dateOfAgreement!)
      .providerId(toProviderId(firebase))
      .build();

    const response = await UserRequest.saveUser(request);
    return response.userId;
  };

  return {
    getFirebase,
    existFirebase,
    getUser,
    existUser,
    createNonActivatedUserIfNotExisted,
    saveActivatedUser
  } as P;
}

export default useUser;
