import { Category, Gender, UserInflowSource } from '@atomica.co/irori';
import { Labels } from '../models/common-model';

export const USER_GENDER_LABELS: Labels = {
  [Gender.FEMALE]: '女性',
  [Gender.MALE]: '男性',
  [Gender.OTHER]: '回答しない'
};

export const USER_CATEGORY_LABELS: Labels = {
  [Category.WORK]: '社会人',
  [Category.UNIVERSITY]: '大学生',
  [Category.HIGH_SCHOOL]: '高校生',
  [Category.PREFECTURAL_SERVANT]: '県庁職員',
  [Category.CITY_OFFICE_SERVANT]: '市役所職員',
  [Category.OTHER_SERVANT]: 'その他公務員',
  [Category.OTHER]: 'その他'
};

export const USER_INFLOW_SOURCE_LABELS: Labels = {
  [UserInflowSource.INTERNET]: 'インターネット検索',
  [UserInflowSource.TWITTER]: 'Twitter',
  [UserInflowSource.INSTAGRAM]: 'Instagram',
  [UserInflowSource.FACEBOOK]: 'Facebook',
  [UserInflowSource.REFERRAL]: '知人からの紹介',
  [UserInflowSource.TELEVISION]: 'テレビ',
  [UserInflowSource.RADIO]: 'ラジオ',
  [UserInflowSource.MAGAZIN]: '雑誌',
  [UserInflowSource.NEWSPAPER]: '新聞・チラシ',
  [UserInflowSource.OTHER]: 'その他'
};
