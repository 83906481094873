import { theme } from '@atomica.co/components';
import { Sort, UserSortColumn } from '@atomica.co/irori';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

interface P {
  colLabel: string;
  colName: UserSortColumn;
  sortKey: string;
  sort: Sort;
  handleClick: (key: UserSortColumn) => void;
}

const UserPropSortTh: React.FC<P> = React.memo(props => {
  const { colLabel, colName, sortKey, sort, handleClick } = props;

  return (
    <Th onClick={() => handleClick(colName)}>
      <Wrapper>
        <Label>{colLabel}</Label>
        {sortKey === colName && (
          <SortIconWrapper>
            {sort === Sort.ASC ? <KeyboardArrowDown fontSize='small' /> : <KeyboardArrowUp fontSize='small' />}
          </SortIconWrapper>
        )}
      </Wrapper>
    </Th>
  );
});

export default UserPropSortTh;

const Th = styled.th`
  text-align: left;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.sixteen + 2}px;
  cursor: inherit;
  margin-bottom: 0px;
`;

const SortIconWrapper = styled.div`
  margin-left: auto;
  display: inline-block;
`;
