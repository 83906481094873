import { Component, Scrollable, theme } from '@atomica.co/components';
import { BaseDto } from '@atomica.co/irori';
import React from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../constants/common-const';
import firebase from '../../../../firebase';
import atomica from './../../../../assets/atomica.png';
import EmailSignIn from './parts/EmailSignIn';
import GoogleSignIn from './parts/GoogleSignIn';
import LineSignIn from './parts/LineSignIn';
import Remarks from './parts/Remarks';

interface P {
  base: BaseDto | undefined;
  onSignIn(res: firebase.auth.UserCredential): void;
  resetPassword(): void;
}

const SignInOptionList: React.FC<P> = React.memo(props => {
  const { base, onSignIn, resetPassword } = props;

  return (
    <Component className='sign-in-option-list'>
      <Container>
        <Scrollable>
          <Content>
            <LogoWrapper>
              <Logo src={atomica} />
            </LogoWrapper>

            <EmailSignIn onSignIn={onSignIn} resetPassword={resetPassword} />

            <GoogleSignIn />

            <LineSignIn base={base} />

            <Remarks />
          </Content>
        </Scrollable>
      </Container>
    </Component>
  );
});

export default SignInOptionList;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${theme.mixins.spacing * 6}px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
