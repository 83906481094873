import {
  BaseDto,
  EventV2,
  EventIdV2,
} from '@atomica.co/irori';
import {
  builder,
  Name,
  Text,
} from '@atomica.co/utils';

export const toEventV2 = (
  eventIdV2: EventIdV2,
  name: Name,
  description: Text,
  photoURL: string,
  base: BaseDto
): EventV2 => {
  return builder<EventV2>()
    .eventId(eventIdV2)
    .name(name)
    .description(description)
    .photoURL(photoURL)
    .base(base)
    .build();
};
