import {
  Base,
  Contract,
  ContractId,
  ContractPaymentMethod,
  ContractStatus,
  InflowSource,
  PaymentType,
  UseAddress
} from '@atomica.co/irori';
import {
  Amount,
  builder,
  Count,
  Email,
  Month,
  Name,
  Remarks,
  toBeginningOfDay,
  toEndOfDay,
  ZERO
} from '@atomica.co/utils';
import { ContractPlan } from '../constants/base-config';
import PriceService from '../services/price-service';

export const toContract = (
  contractId: ContractId,
  contractName: Name,
  contractStatus: ContractStatus,
  startDate: Date,
  endDate: Date,
  representativeName: Name,
  representativeEmail: Email,
  contractPlan: ContractPlan,
  paymentMethod: ContractPaymentMethod,
  paymentType: PaymentType,
  useAddress: UseAddress,
  numberOfKeys: Count,
  numberOfLockers: Count,
  taxExcludedPlanPrice: Amount,
  taxExcludedKeyIssuePrice: Amount,
  taxExcludedLockerPrice: Amount,
  taxExcludedAddressPrice: Amount,
  serviceRetainer: Amount,
  initDiscountAmount: Amount,
  discountAmount: Amount,
  discountMonths: Month,
  taxIncludedTotalAmount: Amount,
  billingAmount: Amount,
  remarks: Remarks,
  inflowSource: InflowSource,
  base: Base
): Contract => {
  return builder<Contract>()
    .contractId(contractId)
    .contractName(contractName)
    .contractStatus(contractStatus)
    .startDate(toBeginningOfDay(startDate)!)
    .endDate(toEndOfDay(endDate)!)
    .representativeName(representativeName)
    .representativeEmail(representativeEmail)
    .contractPlan(contractPlan)
    .paymentMethod(paymentMethod)
    .paymentType(paymentType)
    .useAddress(useAddress === UseAddress.YES)
    .numberOfKeys(!!numberOfKeys ? numberOfKeys : ZERO)
    .numberOfLockers(!!numberOfLockers ? numberOfLockers : ZERO)
    .taxExcludedPlanPrice(taxExcludedPlanPrice)
    .taxIncludedPlanPrice(PriceService.toTaxIncludedPrice(taxExcludedPlanPrice))
    .taxExcludedKeyIssuePrice(taxExcludedKeyIssuePrice)
    .taxIncludedKeyIssuePrice(PriceService.toTaxIncludedPrice(taxExcludedKeyIssuePrice))
    .taxExcludedLockerPrice(taxExcludedLockerPrice)
    .taxIncludedLockerPrice(PriceService.toTaxIncludedPrice(taxExcludedLockerPrice))
    .taxExcludedAddressPrice(taxExcludedAddressPrice)
    .taxIncludedAddressPrice(PriceService.toTaxIncludedPrice(taxExcludedAddressPrice))
    .serviceRetainer(serviceRetainer!)
    .initDiscountAmount(initDiscountAmount)
    .discountAmount(discountAmount!)
    .discountMonths(discountMonths!)
    .taxIncludedTotalAmount(taxIncludedTotalAmount)
    .billingAmount(!!billingAmount ? billingAmount : ZERO)
    .remarks(remarks)
    .inflowSource(inflowSource)
    .base(base)
    .build();
};
