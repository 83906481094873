import { HeaderArea, TransitionGroup, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseDto, BASE_CODE, FetchBaseRequest, ProviderId, VerifyUserRequest } from '@atomica.co/irori';
import { builder, Code, CODE_CONST, EMAIL_CONST } from '@atomica.co/utils';
import { default as React, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { HEADER_HEIGHT } from '../../constants/common-const';
import { toSignUpInTab } from '../../converters/path-converter';
import { Process } from '../../enums/action-enum';
import firebase, { auth } from '../../firebase';
import useCashedURL from '../../redux/hooks/useCashedURL';
import usePath from '../../redux/hooks/usePath';
import useProcess from '../../redux/hooks/useProcess';
import useUser from '../../redux/hooks/useUser';
import AuthRequest from '../../requests/auth-request';
import BaseRequest from '../../requests/base-request';
import { Path } from '../../router/Routes';
import SignIn from './sign-in/SignIn';
import SignUp from './sign-up/SignUp';
import SignUpInTabs, { SignUpInTabEnum } from './tabs/SignUpInTabs';

interface P extends RouteComponentProps {}

const SignUpInScreen: React.FC<P> = React.memo(() => {
  const { path, params, queryParams, openBasePath } = usePath();
  const { createNonActivatedUserIfNotExisted } = useUser();
  const { process, setProcess } = useProcess();
  const { hasCashedURL, openCashedURL } = useCashedURL();
  const lineCode = useMemo<Code>(() => queryParams[CODE_CONST], [queryParams]);
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<SignUpInTabEnum>(unmountRef, toSignUpInTab(path));
  const [base, setBase] = useSafeState<BaseDto | undefined>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const baseCode = params[BASE_CODE];
    const baseRequest = builder<FetchBaseRequest>().baseCode(baseCode).build();
    const baseResponse = await BaseRequest.fetchBase(baseRequest);
    setBase(baseResponse.base);
  }, [params, setBase]);

  const succeededToSignIn = useSafeCallback(
    async (res: firebase.auth.UserCredential): Promise<void> => {
      const user = res.user;

      if (!user) {
        setProcess(Process.INITIAL);
        return;
      }

      const email =
        !!res.additionalUserInfo && !!res.additionalUserInfo.profile
          ? res.additionalUserInfo.profile[EMAIL_CONST]
          : user.email;

      await createNonActivatedUserIfNotExisted(user, email);

      setProcess(Process.COMPLETED);
      hasCashedURL() ? openCashedURL() : openBasePath(Path.REGISTER_SHOT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setProcess, openBasePath, hasCashedURL, openCashedURL]
  );

  const failedToSignIn = useSafeCallback((): void => {
    setProcess(Process.INITIAL);
  }, [setProcess]);

  const signInWithRedirect = useSafeCallback((): void => {
    auth.getRedirectResult().then(succeededToSignIn).catch(failedToSignIn);
  }, [succeededToSignIn, failedToSignIn]);

  useEffect(() => {
    initialize();
    signInWithRedirect();
  }, [initialize, signInWithRedirect]);

  const signInWithLine = useSafeCallback(
    async (lineCode: Code, base: BaseDto): Promise<void> => {
      const idToken = await AuthRequest.fetchIdToken(lineCode, base);
      const request = builder<VerifyUserRequest>().idToken(idToken).providerId(ProviderId.LINE).build();
      const response = await AuthRequest.verifyUser(request);
      const customToken = response.customToken;

      auth
        .signInWithCustomToken(customToken)
        .then(succeededToSignIn)
        .catch(err => setProcess(Process.INITIAL));
    },
    [setProcess, succeededToSignIn]
  );

  useEffect(() => {
    if (!lineCode || !base) return;
    signInWithLine(lineCode, base);
  }, [lineCode, base, signInWithLine]);

  useEffect(() => {
    setIndex(toSignUpInTab(path));
  }, [setIndex, path]);

  return (
    <Screen loading={process !== Process.INITIAL} className='sign-up-in-screen'>
      <HeaderArea>
        <SignUpInTabs />
      </HeaderArea>

      <TransitionWrapper>
        <TransitionGroup
          index={index}
          components={[<SignIn base={base} onSignIn={succeededToSignIn} />, <SignUp base={base} />]}
        />
      </TransitionWrapper>
    </Screen>
  );
});

export default SignUpInScreen;

const TransitionWrapper = styled.div`
  margin-top: ${HEADER_HEIGHT}px;
`;
