import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { URL } from '@atomica.co/utils';
import { toCashedURLAction } from '../actions/cashed-url-action';
import store from '../store';

const IGNORED_DIRECTORIES = ['signup', 'signin', 'signout', 'account/new'];

const isIgnored = (url: URL): boolean => {
  return !!url ? !!IGNORED_DIRECTORIES.find(dir => url.includes(dir)) : false;
};

interface P {
  cashedURL: URL;
  saveCashedURL: (url: URL) => void;
  saveCurrentURL: () => void;
  hasCashedURL: () => boolean;
  openCashedURL: () => void;
}

function useCashedURL() {
  const unmountRef = useUnmountRef();
  const [cashedURL, setCashedURL] = useSafeState<URL>(unmountRef, store.getState().cashedURL);

  const saveCashedURL = useSafeCallback(
    (url: URL): void => {
      if (isIgnored(url)) return;
      setCashedURL(url);
      const action = toCashedURLAction(url);
      store.dispatch(action);
    },
    [setCashedURL]
  );

  const saveCurrentURL = useSafeCallback((): void => {
    const currentURL = window.location.href;
    saveCashedURL(currentURL);
  }, [saveCashedURL]);

  const hasCashedURL = useSafeCallback((): boolean => {
    return isIgnored(cashedURL) ? false : !!cashedURL;
  }, [cashedURL]);

  const openCashedURL = useSafeCallback((): void => {
    window.location.href = cashedURL;
  }, [cashedURL]);

  return {
    cashedURL,
    saveCashedURL,
    saveCurrentURL,
    hasCashedURL,
    openCashedURL
  } as P;
}

export default useCashedURL;
