import { Screen, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseDto, BASE_CODE, FetchBaseRequest, User } from '@atomica.co/irori';
import { builder, Code, Label } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import media from 'styled-media-query';
import useCashedURL from '../../redux/hooks/useCashedURL';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import BaseRequest from '../../requests/base-request';
import { Path } from '../../router/Routes';
import defaultUserIcon from './../../assets/default_user_icon.png';
import logo from './../../assets/logo_knot_place.png';
import ConsumerSwitcher from './ConsumerSwitcher';

export const DRAWER_WIDTH = 280;

interface ConsumerMenuItem {
  showInMenu: boolean;
  signIn: boolean;
  path: Path;
  label: Label;
}

const CONSUMER_MENU_ITEMS: ConsumerMenuItem[] = [
  {
    showInMenu: true,
    signIn: false,
    path: Path.SIGN_IN,
    label: 'ログイン'
  },
  {
    showInMenu: true,
    signIn: true,
    path: Path.MY_ACCOUNT,
    label: 'アカウント'
  },
  {
    showInMenu: true,
    signIn: true,
    path: Path.RESERVE_SPACE,
    label: '会議室予約'
  }
];

interface P extends RouteComponentProps {}

const EventType = 'click';
const EventTarget = 'menu';

const ConsumerScreen: React.FC<P> = React.memo(() => {
  const { getUser } = useUser();
  const { path, params, openBasePath } = usePath();
  const { saveCurrentURL } = useCashedURL();
  const unmountRef = useUnmountRef();
  const [signInUser, setSignInUser] = useSafeState<User>(unmountRef);
  const [base, setBase] = useSafeState<BaseDto>(unmountRef);
  const [isOpen, setIsOpen] = useSafeState<boolean>(unmountRef, false);

  const baseCode = useMemo<Code>(() => {
    return params[BASE_CODE];
  }, [params]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = (await getUser()) as User;
    const request = builder<FetchBaseRequest>().baseCode(baseCode).build();
    const response = await BaseRequest.fetchBase(request);
    setSignInUser(user);
    setBase(response.base);
  }, [baseCode, getUser, setBase, setSignInUser]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const switchIsOpen = useSafeCallback(
    (event: any) => {
      event.target.closest(`.${EventTarget}`) ? setIsOpen(true) : setIsOpen(false);
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.addEventListener(EventType, switchIsOpen);
    return () => {
      document.removeEventListener(EventType, switchIsOpen);
    };
  }, [switchIsOpen]);

  const handleMenuClicked = useSafeCallback(
    (path: Path): void => {
      switch (path) {
        case Path.SIGN_IN:
          saveCurrentURL();
          openBasePath(path);
          break;
        case Path.SIGN_OUT:
        case Path.MY_ACCOUNT:
        case Path.RESERVE_SPACE:
          openBasePath(path);
          break;

        default:
          throw new Error(`${path} is out of target.`);
      }
    },
    [openBasePath, saveCurrentURL]
  );

  return (
    <Screen className='consumer-screen'>
      <Container>
        <CssBaseline />
        <Header position='fixed'>
          <StyledToolbar>
            <LogoWrapper>
              <Logo src={logo} />
            </LogoWrapper>
            <MenuWrapper>
              <AvatorWrapper className={EventTarget}>
                <Avator
                  src={signInUser ? (!!signInUser.photoURL ? signInUser.photoURL : defaultUserIcon) : defaultUserIcon}
                  onClick={() => setIsOpen(isOpen => !isOpen)}
                />
              </AvatorWrapper>
              {isOpen && (
                <MenuContainer>
                  <MenuLinkWrapper>
                    {CONSUMER_MENU_ITEMS.map(
                      (link, idx) =>
                        !!signInUser === link.signIn && (
                          <MenuLink key={idx} onClick={() => handleMenuClicked(link.path)}>
                            {link.label}
                          </MenuLink>
                        )
                    )}
                  </MenuLinkWrapper>
                  {/* {!!signInUser && (
                    <SignOutWrapper>
                      <Button type='secondary' shape='rect' onClick={() => handleMenuClicked(Path.SIGN_OUT)}>
                        <Text>サインアウト</Text>
                      </Button>
                    </SignOutWrapper>
                  )} */}
                </MenuContainer>
              )}
            </MenuWrapper>
          </StyledToolbar>
        </Header>
        {!!base && (
          <ConsumerSwitcher base={base} path={path} user={signInUser} onChange={openBasePath} setUser={setSignInUser} />
        )}
      </Container>
    </Screen>
  );
});

export default ConsumerScreen;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;
const Header = styled(AppBar)`
  background: ${theme.mixins.background.white};
  width: 100%;
  height: 48px;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 3}px;
  border: 1px solid ${theme.mixins.border.lightGray};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);

  ${media.lessThan('small')`
    padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  `};
`;
const StyledToolbar = styled(Toolbar)`
  height: 32px;
  min-height: 32px;
  padding: 0px;
`;
const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
`;
const Logo = styled.img`
  width: auto;
  height: 32px;

  ${media.lessThan('small')`
    width: 100px;
  `};
`;
const MenuWrapper = styled.div``;
const AvatorWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  borderradius: 50%;
  cursor: pointer;

  ${media.lessThan('small')`
    width: 36px;
    height: 36px;
  `};
`;
const Avator = styled.img`
  width: 100%;
  height: 100%;
  background: ${theme.mixins.background.lightGray};
  border: 1px solid ${theme.mixins.border.lightGray};
  border-radius: 50%;
  object-fit: contain;
`;
const MenuContainer = styled.div`
  width: 295px;
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid ${theme.mixins.border.lightGray};
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
`;
const MenuLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.mixins.spacing}px 0;
  background: ${theme.mixins.background.white};
`;
const MenuLink = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
  &:hover {
    background: ${theme.mixins.background.lightGray};
  }
`;
// const SignOutWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   padding: ${theme.mixins.spacing}px;
//   border-top: 1px solid ${theme.mixins.border.lightGray};
//   background: ${theme.mixins.background.white};
// `;
// const Text = styled(Typography)`
//   font-size: ${theme.mixins.typography.fontSize.sixteen}px;
//   font-family: ${theme.mixins.typography.fontFamily};
// `;
