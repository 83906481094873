import {
  EventParticipantId,
  FetchEventParticipantsByDatesRequest,
  FetchEventParticipantsByDatesResponse,
  FETCH_EVENT_PARTICIPANTS_BY_DATES,
  SaveEventParticipantsRequest,
  SaveEventParticipantsResponse,
  SAVE_EVENT_PARTICIPANTS,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType, uuid } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class EventParticipantRequest {
  public static getEventParticipantId = (): EventParticipantId => uuid();

  public static saveEventParticipants = async (
    request: SaveEventParticipantsRequest
  ): Promise<SaveEventParticipantsResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_EVENT_PARTICIPANTS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchEventParticipantsByDates = async (
    request: FetchEventParticipantsByDatesRequest
  ): Promise<FetchEventParticipantsByDatesResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_EVENT_PARTICIPANTS_BY_DATES));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
