import {
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BASE_CODE, CONTRACT_ID, FetchContractRequest, SaveContractMembersRequest } from '@atomica.co/irori';
import { builder, Message } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { NO_BASE } from '../../constants/error-message-const';
import { returnBaseOnlyIfExisted } from '../../converters/shot-usage-converter';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import ContractRequest from '../../requests/contract-request';
import { Path } from '../../router/Routes';
import mojaco from './../../assets/mojaco/mojaco_thanks.png';

interface P {}

const RegisterContractMemberScreen: React.FC<P> = React.memo(() => {
  const { getUser } = useUser();
  const { params, openBasePath } = usePath();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const base = returnBaseOnlyIfExisted(params[BASE_CODE]);

    if (!base) {
      setErrorMessage(NO_BASE);
      return;
    }

    const contractId = params[CONTRACT_ID];
    const contractRequest = builder<FetchContractRequest>().contractId(contractId).build();

    const [user, contractResponse] = await Promise.all([getUser(), ContractRequest.fetchContract(contractRequest)]);

    if (!user) {
      openBasePath(Path.SIGN_IN);
      return;
    }

    const membersRequest = builder<SaveContractMembersRequest>()
      .contract(contractResponse.contract!)
      .userIds([user.userId])
      .build();

    await ContractRequest.saveContractMembers(membersRequest);
    setLoaded(true);
  }, [params, setErrorMessage, openBasePath, getUser, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Screen loading={!loaded} errorMsg={errorMessage} className='register-contract-member-screen'>
      <Container>
        <Content>
          <MessageWrapper>
            <StyledMessage>登録が完了しました！</StyledMessage>
          </MessageWrapper>

          <Mojaco src={mojaco} />
        </Content>
      </Container>
    </Screen>
  );
});

export default RegisterContractMemberScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px;
`;

const StyledMessage = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  user-select: none;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${theme.mixins.spacing}px;
`;
