import {
  Button,
  Component,
  Skeleton,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { AuthorityDefCodeEnum, UpdateUserQFaceRequest, User } from '@atomica.co/irori';
import { builder, Code, EMPTY, Message, URL } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import InstagramIcon from '@material-ui/icons/Instagram';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { ERROR, QFACE_SET_ERROR, QFACE_SET_SAVED, SUCCESS } from '../../../constants/snackbar-const';
import firebase, { storage } from '../../../firebase';
import UserRequest from '../../../requests/user-request';
import { FaceRecognitionService } from '../../../services/face-recognition-service';
import mojaco from './../../../assets/mojaco/mojaco_break.png';

interface P extends RouteComponentProps {
  authorityDefCode: Code | null;
  user: User;
  setUser: (user: any) => void;
}

const MyAccountProfile: React.FC<P> = React.memo(props => {
  const { authorityDefCode, user, setUser } = props;
  const unmountRef = useUnmountRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenQFaceConfig, setIsOpenQFaceConfig] = useSafeState<boolean>(unmountRef, false);
  const [faceRecognitionPhotoURL, setFaceRecognitionPhotoURL] = useSafeState<URL>(
    unmountRef,
    user.faceRecognitionPhotoURL
  );
  const [facePhotoDownloadURL, setFacePhotoDownloadURL] = useSafeState<URL>(unmountRef);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  const isFaceAccessUser = useMemo<boolean>(() => {
    return authorityDefCode === AuthorityDefCodeEnum.FACE_ACCESS;
  }, [authorityDefCode]);

  useEffect(() => {
    setUser(user => Object.assign({}, user, { faceRecognitionPhotoURL }));
  }, [setUser, faceRecognitionPhotoURL]);

  const onChangeFacePhotoToSave = useSafeCallback(
    async (file: File): Promise<void> => {
      // const arrayBuffer = await file.arrayBuffer();
      // const data = Buffer.from(arrayBuffer).toString('base64');
      // const response = await QFaceRequest.create({ imageData: data });

      // if (!response.result) {
      //   setErrorMessage(response.message!);
      //   enqueueSnackbar(QFACE_SET_ERROR, { variant: ERROR });
      //   return;
      // }

      const facePhotoURL = await FaceRecognitionService.putImageFileToStorage(file);

      const updateUserQFaceRequest = builder<UpdateUserQFaceRequest>()
        .userId(user.userId)
        .faceRecognitionPhotoURL(facePhotoURL)
        .build();

      const updateUserQFaceResponse = await UserRequest.updateUserQFace(updateUserQFaceRequest);

      if (!updateUserQFaceResponse.userId) {
        setErrorMessage(QFACE_SET_ERROR);
        enqueueSnackbar(QFACE_SET_ERROR, { variant: ERROR });
        return;
      }

      setFaceRecognitionPhotoURL(facePhotoURL);
      setErrorMessage(EMPTY);
      enqueueSnackbar(QFACE_SET_SAVED, { variant: SUCCESS });
    },
    [setErrorMessage, enqueueSnackbar, user, setFaceRecognitionPhotoURL]
  );

  const recognitionURLToDownloadURL = useSafeCallback(async (): Promise<void> => {
    if (!faceRecognitionPhotoURL) return;
    const ref: firebase.storage.Reference = storage.ref(faceRecognitionPhotoURL);
    const downloadURL = await ref.getDownloadURL();
    setFacePhotoDownloadURL(downloadURL);
  }, [faceRecognitionPhotoURL, setFacePhotoDownloadURL]);

  const closeQFaceConfig = useSafeCallback((): void => {
    setErrorMessage(EMPTY);
    setIsOpenQFaceConfig(false);
  }, [setErrorMessage, setIsOpenQFaceConfig]);

  const deleteAccount = useSafeCallback((): void => {
    const url = 'https://forms.gle/YgC4eLmY1YEHxChF7';
    window.open(url, '_blank');
  }, []);

  useEffect(() => {
    recognitionURLToDownloadURL();
  }, [recognitionURLToDownloadURL]);

  return (
    <Component className='my-account-profile'>
      <Container>
        <Content>
          {isOpenQFaceConfig && (
            <QFaceConfigWrapper>
              <QFaceConfig>
                <ConfigLabel>現在設定されている顔認証用の写真</ConfigLabel>
                <QFaceWrapper>
                  <Skeleton style={styleForPhoto} src={facePhotoDownloadURL} />
                  <AddPhotoButton>
                    <HiddenInputFile
                      type='file'
                      accept='image/*'
                      onChange={event => onChangeFacePhotoToSave(event.target.files[0])}
                    />
                    <CustomInstagramIcon />
                    <CustomAddIcon />
                  </AddPhotoButton>
                </QFaceWrapper>
              </QFaceConfig>
              {errorMessage && (
                <MessageWrapper>
                  <MessageLabel>{errorMessage}</MessageLabel>
                  <MessageLabel>写真を再度アップロードしてください。</MessageLabel>
                </MessageWrapper>
              )}
              <BackButtonWrapper>
                <Button type='secondary' onClick={closeQFaceConfig}>
                  <BackButtonLabel>戻る</BackButtonLabel>
                </Button>
              </BackButtonWrapper>
            </QFaceConfigWrapper>
          )}

          {!isOpenQFaceConfig && (
            <ProfileWrapper>
              <Profile>
                <ImageWrapper>
                  <Image src={user.photoURL || mojaco} />
                </ImageWrapper>
                <NameWrapper>
                  <NameLabel>{`${user.familyName} ${user.firstName}`}</NameLabel>
                </NameWrapper>
              </Profile>
              {isFaceAccessUser && (
                <ConfigButton onClick={() => setIsOpenQFaceConfig(true)}>
                  <ButtonLabel>顔認証用の写真を設定する</ButtonLabel>
                  <CustomNavigateNextIcon />
                </ConfigButton>
              )}
            </ProfileWrapper>
          )}
        </Content>
        <ButtonArea>
          <Button onClick={deleteAccount}>アカウント削除はこちら</Button>
        </ButtonArea>
      </Container>
    </Component>
  );
});

export default MyAccountProfile;

const Container = styled.div`
  width: 100%;
  height: auto;
`;
const Content = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
  padding-bottom: 0px;
`;
const QFaceConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing * 2}px;
`;
const QFaceConfig = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
  padding: ${theme.mixins.spacing * 2}px;
  barder-radius: ${theme.mixins.spacing / 2}px;
`;
const ConfigLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
`;
const QFaceWrapper = styled.div`
  width: 208px;
  height: auto;
  margin: ${theme.mixins.spacing * 3}px auto;
  position: relative;
`;
const AddPhotoButton = styled.label`
  width: 40px;
  height: 40px;
  margin: 0;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  border-radius: 50%;
  right: ${theme.mixins.spacing}px;
  bottom: ${theme.mixins.spacing}px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const HiddenInputFile = styled.input`
  display: none;
`;
const MessageWrapper = styled.div`
  width: 100%;
`;
const MessageLabel = styled(Typography)`
  text-align: center;
  color: ${theme.mixins.typography.fontColor.pink};
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;
const BackButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${theme.mixins.spacing}px;
  * {
    cursor: pointer;
  }
`;
const BackButtonLabel = styled.label`
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-size: 14px;
  margin: 0;
  padding: 0 ${theme.mixins.spacing * 4}px;
`;
const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing * 2}px;
`;
const Profile = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
  padding: ${theme.mixins.spacing * 2}px;
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 1px solid ${theme.mixins.border.lightGray};
`;
const NameWrapper = styled.div`
  margin-top: ${theme.mixins.spacing}px;
`;
const NameLabel = styled(Typography)`
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.twentyEight}px;
`;
const ConfigButton = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
  padding: ${theme.mixins.spacing * 2}px;
  display: flex;
`;
const ButtonLabel = styled(Typography)`
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
`;
const CustomInstagramIcon = styled(InstagramIcon)`
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;
const CustomAddIcon = styled(AddIcon)`
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  margin-left: -4px;
`;
const CustomNavigateNextIcon = styled(NavigateNextIcon)`
  margin-left: auto;
`;
const ButtonArea = styled.div`
  width: 180px;
  height: auto;
  margin-right: 0;
  margin-left: auto;
  padding-bottom: 80px;
`;

const styleForPhoto: CSSProperties = {
  width: 208,
  minHeight: 208,
  background: theme.mixins.background.lightGray,
  objectFit: 'contain',
  borderRadius: `${theme.mixins.spacing * 2}px`
};
