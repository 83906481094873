import { CashedSpaceReservationActionEnum } from '../../enums/action-enum';
import { CashedSpaceReservationAction } from '../../models/action-model';
import { CashedSpaceReservation } from '../hooks/useCashedSpaceReservation';

export const toSpaceReservationAction = (
  cashedSpaceReservation: CashedSpaceReservation
): CashedSpaceReservationAction => {
  return {
    type: CashedSpaceReservationActionEnum.SET,
    cashedSpaceReservation
  };
};
