import React from 'react';
import { Path } from '../../../router/Routes';
import ConsumerScreen from '../../consumer/ConsumerScreen';
import RegisterAccountScreen from '../../account/RegisterAccountScreen';
import LiffScreen from '../../liff/LiffScreen';
import RedirectScreen from '../../redirect/RedirectScreen';
import SignOutScreen from '../../sign-out/SignOutScreen';
import SignUpInScreen from '../../sign-up-in/SignUpInScreen';

interface P {
  path: Path;
}

const NoGuardedScreens: React.FC<P> = React.memo(props => {
  const { path } = props;
  return (
    <>
      {(path === Path.SIGN_UP || path === Path.SIGN_IN) && <SignUpInScreen />}

      {path === Path.LIFF && <LiffScreen />}

      {path === Path.REGISTER_ACCOUNT && <RegisterAccountScreen />}

      {path === Path.SIGN_OUT && <SignOutScreen />}

      {path === Path.REDIRECT && <RedirectScreen />}

      {path === Path.RESERVE_SPACE && <ConsumerScreen />}
    </>
  );
});

export default NoGuardedScreens;
