import { Base, BaseDto, PersonInCharge, ProviderId, User, UserEntity } from '@atomica.co/irori';
import { Name } from '@atomica.co/utils';
import firebase from 'firebase';

export const toProviderId = (fbUser: firebase.User): ProviderId => {
  const providerId = fbUser.displayName;

  switch (providerId) {
    case ProviderId.LINE:
      return ProviderId.LINE;

    default:
      return ProviderId.GOOGLE;
  }
};

export const toFullName = (user: UserEntity | User): Name => (!!user ? `${user.familyName} ${user.firstName}` : '');

export const isPIC = (user: User, base: Base): boolean => {
  if (!user.personInCharge || !user.personInCharge.length) return false;
  return !!user.personInCharge.find(pic => pic.base === Base.ALL || pic.base === base);
};

export const isBasePIC = (user: User, base: BaseDto): boolean => {
  if (!user.personInCharge || !user.personInCharge.length) return false;
  return !!user.personInCharge.find(pic => pic.baseDto.baseCode === base.baseCode);
};

export const getBasePIC = (user: User, base: BaseDto): PersonInCharge => {
  return user.personInCharge?.find(charge => charge.baseDto.baseId === base.baseId)!;
};
