import { MOBILE_MIN_WIDTH, Scrollable, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Base, BASE_CODE, Event, EVENT_ID, FetchEventRequest, User } from '@atomica.co/irori';
import { builder, hasLength } from '@atomica.co/utils';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { returnBaseOnlyIfExisted } from '../../converters/shot-usage-converter';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import EventRequest from '../../requests/event-request';
import { Path } from '../../router/Routes';
import EventSchedules from './event-schedules/EventSchedules';
import RegisterAsEventWaiting from './event-waiting/RegisterAsEventWaiting';

interface P {}

const JoinEventScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { getUser } = useUser();
  const { params, openBasePath } = usePath();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [existSchedules, setExistSchedules] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<User>(unmountRef);
  const [event, setEvent] = useSafeState<Event>(unmountRef);

  const base = useMemo<Base | undefined>(() => {
    return returnBaseOnlyIfExisted(params[BASE_CODE]);
  }, [params]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchEventRequest>().eventId(params[EVENT_ID]).build();

    const [user, response] = await Promise.all([getUser(), EventRequest.fetchEvent(request)]);

    if (!user) {
      openBasePath(Path.SIGN_IN);
      return;
    }

    const event = response.event!;
    setExistSchedules(hasLength(event.schedules));
    setUser(user);
    setEvent(event);
    setLoaded(true);
  }, [openBasePath, getUser, params, setExistSchedules, setUser, setEvent, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Screen loading={!loaded} className='join-event-screen'>
      <Container>
        <Content>
          <Scrollable>
            {!!base && existSchedules && <EventSchedules base={base} user={user} event={event} />}

            {!!base && !existSchedules && <RegisterAsEventWaiting base={base} user={user} event={event} />}
          </Scrollable>
        </Content>
      </Container>
    </Screen>
  );
});

export default JoinEventScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: 768px;
  height: 100%;
`;
