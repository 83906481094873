import { Component, theme } from '@atomica.co/components';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import '../../../styles/bootstrap-journal.min.css';

interface P {
  handleClick: (date: DateClickArg) => void;
}

const SelectDate: React.FC<P> = React.memo(props => {
  const { handleClick } = props;
  return (
    <Component className='select-date'>
      <Container>
        <Content>
          <ItemNameWrapper>
            <ItemName>2. 日付の選択（2/3）</ItemName>
          </ItemNameWrapper>
          <Description>空き状況を確認したい日付を選択してください。</Description>
          <CalendarWrapper>
            <FullCalendar
              locale='ja'
              headerToolbar={{
                left: 'prev next',
                center: 'title',
                right: 'today'
              }}
              buttonText={{
                prev: '<',
                next: '>',
                today: '今日'
              }}
              timeZone='local'
              initialView='dayGridMonth'
              themeSystem='bootstrap'
              contentHeight='auto'
              validRange={{
                start: new Date()
              }}
              slotMinTime={{ day: 20 }}
              plugins={[bootstrapPlugin, dayGridPlugin, interactionPlugin]}
              dateClick={handleClick}
            />
          </CalendarWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default SelectDate;

const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing}px;
  padding: ${theme.mixins.spacing * 3}px;

  ${media.lessThan('small')`
  padding: ${theme.mixins.spacing * 2}px;
  `}
`;

const ItemNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ItemName = styled(Typography)`
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  border-left: 4px solid ${theme.mixins.border.lightGray};
  padding-left: ${theme.mixins.spacing * 2}px;

  ${media.lessThan('small')`
    font-size: 14px;
    padding: ${theme.mixins.spacing}px;
  `}
`;

const Description = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;

  ${media.lessThan('small')`
    font-size: 14px;
  `}
`;

const CalendarWrapper = styled.div`
  padding: ${theme.mixins.spacing * 3}px;
  border: 1px solid ${theme.mixins.border.lightGray};
  border-top: 4px solid ${theme.mixins.border.orange};

  .fc-daygrid-day-events {
    min-height: 5em !important;
  }

  ${media.lessThan('small')`
    padding: ${theme.mixins.spacing}px;
    .btn {
      padding: 4px 8px;
      font-size: ${theme.mixins.typography.fontSize.twelve}px;
    }
    .fc-toolbar-title {
      font-size: ${theme.mixins.typography.fontSize.twenty}px;
    }
    .fc-daygrid-body-natural .fc-daygrid-day-events {
      margin-bottom: 0;
    }
    .fc-daygrid-day-events {
      min-height: 1em !important;
    }
  `}
`;
