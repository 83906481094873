import {
  Button,
  Component,
  DateBox,
  InputBox,
  MasterSearchBox,
  MasterSearchOption,
  RadioBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef,
  VerticalEllipsisButton,
  VerticalEllipsisButtonOption
} from '@atomica.co/components';
import {
  Base,
  BaseDto,
  Contract,
  ContractPaymentMethod,
  CONTRACT_ID,
  DeleteContractRequest,
  FetchContractRequest,
  InflowSource,
  PaymentType,
  SaveContractRequest,
  SaveSpaceRequest,
  SearchSpacesForContractRequest,
  SendInvitationToContractMembersRequest,
  Space,
  UseAddress,
  User
} from '@atomica.co/irori';
import {
  Amount,
  builder,
  Count,
  Email,
  embedIdInPath,
  EMPTY,
  hasLength,
  Month,
  Name,
  noop,
  Option,
  Remarks,
  scroll,
  Width,
  ZERO
} from '@atomica.co/utils';
import { List as MuiList, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  ContractPlan,
  ContractPlanLabelByBase,
  CONTRACT_ADDRESS_PRICE,
  CONTRACT_KEY_ISSUE_PRICE,
  CONTRACT_LOCKER_PRICE,
  toContractPlanPrice,
  toContractPlans
} from '../../constants/base-config';
import { CONTRACT_DELETED, CONTRACT_UPDATED, EMAIL_SENT, SUCCESS } from '../../constants/snackbar-const';
import { toContract } from '../../converters/contract-converter';
import { setContractId, toMasterSearchOptions } from '../../converters/space-converter';
import usePath from '../../redux/hooks/usePath';
import ContractRequest from '../../requests/contract-request';
import SpaceRequest from '../../requests/space-request';
import { Path, PATH_IDS } from '../../router/Routes';
import PriceService from '../../services/price-service';
import {
  CONTRACT_INFLOW_SOURCE_LABELS,
  CONTRACT_PAYMENT_METHOD_LABELS,
  CONTRACT_PAYMENT_TYPE_LABELS,
  CONTRACT_USE_ADDRESS_LABELS
} from '../../texts/contract-text';
import { toFullName } from '../../utils/user-util';
import ContractModal from './contract-modal/ContractModal';

const TOP = 'top';

const MASTER_SEARCH_LIMIT = 10;

enum VerticalEllipsisButtonType {
  EDIT_CONTRACT = 'edit_contract',
  INVITE_MEMBERS = 'invite_members',
  DELETE_CONTRACT = 'delete_contract'
}

const VERTICAL_ELLIPSIS_BUTTON_OPTIONS: VerticalEllipsisButtonOption[] = [
  {
    id: VerticalEllipsisButtonType.EDIT_CONTRACT,
    label: '契約内容を編集する'
  },
  {
    id: VerticalEllipsisButtonType.INVITE_MEMBERS,
    label: 'メンバーを招待する'
  },
  {
    id: VerticalEllipsisButtonType.DELETE_CONTRACT,
    label: '契約を削除する'
  }
];

interface P {
  base: BaseDto;
  onChange(path: Path): void;
}

const ContractDetailsScreen: React.FC<P> = React.memo(props => {
  const { base, onChange } = props;
  const unmountRef = useUnmountRef();
  const { params } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [editable, setEditable] = useSafeState<boolean>(unmountRef, false);
  const [disabledUpdateButton, setDisabledUpdateButton] = useSafeState<boolean>(unmountRef, false);
  const [isContractDeleted, setIsContractDeleted] = useSafeState<boolean>(unmountRef, false);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [spaces, setSpaces] = useSafeState<Space[]>(unmountRef, []);
  const [user, setUser] = useSafeState<User>(unmountRef);
  const [initialContract, setInitialContract] = useSafeState<Contract>(unmountRef);
  const [contractNameToUpdate, setContractNameToUpdate] = useSafeState<Name>(unmountRef, EMPTY);
  const [representativeNameToUpdate, setReprensentativeNameToUpdate] = useSafeState<Name>(unmountRef, EMPTY);
  const [representativeEmailToUpdate, setReprensentativeEmailToUpdate] = useSafeState<Email>(unmountRef, EMPTY);
  const [startDateToUpdate, setStartDateToUpdate] = useSafeState<Date>(unmountRef, new Date());
  const [endDateToUpdate, setEndDateToUpdate] = useSafeState<Date>(unmountRef, new Date());
  const contractPlans = useMemo<Option[]>(() => toContractPlans(base.baseCode as Base), [base]);
  const [contractPlanToUpdate, setContractPlanToUpdate] = useSafeState<ContractPlan>(unmountRef, EMPTY as ContractPlan);
  const [selectedSpaceToUpdate, setSelectedSpaceToUpdate] = useSafeState<Space | undefined>(unmountRef);
  const [numberOfKeysToUpdate, setNumberOfKeysToUpdate] = useSafeState<Count>(unmountRef, ZERO);
  const [numberOfLockersToUpdate, setNumberOfLockersToUpdate] = useSafeState<Count>(unmountRef, ZERO);
  const [useAddressToUpdate, setUseAdressToUpdate] = useSafeState<UseAddress>(unmountRef, UseAddress.NO);
  const [paymentMethodToUpdate, setPaymentMethodToUpdate] = useSafeState<ContractPaymentMethod>(
    unmountRef,
    ContractPaymentMethod.CREDIT_CARD
  );
  const [paymentTypeToUpdate, setPaymentTypeToUpdate] = useSafeState<PaymentType>(unmountRef, PaymentType.SINGLE);
  const [inflowSourceToUpdate, setInflowSourceToUpdate] = useSafeState<InflowSource>(unmountRef, InflowSource.ORGANIC);
  const [serviceRetainerToUpdate, setServiceRetainerToUpdate] = useSafeState<Amount>(unmountRef, ZERO);
  const [taxIncludedInitialDiscountAmountToUpdate, setTaxIncludedInitialDiscountAmountToUpdate] = useSafeState<Amount>(
    unmountRef,
    ZERO
  );
  const [taxIncludedLongTermDiscountAmountToUpdate, setTaxIncludedLongTermDiscountAmountToUpdate] =
    useSafeState<Amount>(unmountRef, ZERO);
  const [longTermDiscountMonthsToUpdate, setLongTermDiscountMonthsToUpdate] = useSafeState<Month>(unmountRef, ZERO);
  const [billingAmountToUpdate, setBillingAmountToUpdate] = useSafeState<Amount>(unmountRef, ZERO);
  const [remarksToUpdate, setRemarksToUpdate] = useSafeState<Remarks>(unmountRef, EMPTY);
  const [contractMembers, setContractMembers] = useSafeState<User[]>(unmountRef, []);

  const taxExcludedPlanPriceToUpdate = useMemo<Amount>(
    () => toContractPlanPrice(base.baseCode as Base, contractPlanToUpdate),
    [base, contractPlanToUpdate]
  );

  const taxExcludedKeyIssuePriceToUpdate = useMemo<Amount>(
    () => numberOfKeysToUpdate * CONTRACT_KEY_ISSUE_PRICE,
    [numberOfKeysToUpdate]
  );

  const taxExcludedLockerPriceToUpdate = useMemo<Amount>(
    () => numberOfLockersToUpdate * CONTRACT_LOCKER_PRICE,
    [numberOfLockersToUpdate]
  );

  const taxExcludedAddressPriceToUpdate = useMemo<Amount>(
    () => (useAddressToUpdate === UseAddress.YES ? CONTRACT_ADDRESS_PRICE : ZERO),
    [useAddressToUpdate]
  );

  const taxIncludedTotalAmountToUpdate = useMemo<Amount>(() => {
    const taxExcludedAmount =
      taxExcludedPlanPriceToUpdate + taxExcludedLockerPriceToUpdate + taxExcludedAddressPriceToUpdate;
    return PriceService.toTaxIncludedPrice(taxExcludedAmount);
  }, [taxExcludedPlanPriceToUpdate, taxExcludedLockerPriceToUpdate, taxExcludedAddressPriceToUpdate]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const contractId = params[CONTRACT_ID];
    const request = builder<FetchContractRequest>().contractId(contractId).build();
    const response = await ContractRequest.fetchContract(request);
    const contract = response.contract;

    if (!contract) {
      setLoaded(true);
      return;
    }

    setUser(user);
    setSpaces(!!contract.spaces ? contract.spaces : []);
    setInitialContract(contract);
    setContractNameToUpdate(contract.contractName);
    setReprensentativeNameToUpdate(contract.representativeName);
    setReprensentativeEmailToUpdate(contract.representativeEmail);
    setStartDateToUpdate(contract.startDate);
    setEndDateToUpdate(contract.endDate);
    setContractPlanToUpdate(contract.contractPlan as ContractPlan);
    setSelectedSpaceToUpdate(hasLength(contract.spaces) ? contract.spaces![0] : undefined);
    setNumberOfKeysToUpdate(contract.numberOfKeys);
    setNumberOfLockersToUpdate(contract.numberOfLockers);
    setUseAdressToUpdate(contract.useAddress ? UseAddress.YES : UseAddress.NO);
    setPaymentMethodToUpdate(contract.paymentMethod);
    setPaymentTypeToUpdate(contract.paymentType);
    setInflowSourceToUpdate(contract.inflowSource);
    setServiceRetainerToUpdate(!!contract.serviceRetainer ? contract.serviceRetainer : ZERO);
    setTaxIncludedInitialDiscountAmountToUpdate(contract.initDiscountAmount);
    setTaxIncludedLongTermDiscountAmountToUpdate(!!contract.discountAmount ? contract.discountAmount : ZERO);
    setLongTermDiscountMonthsToUpdate(!!contract.discountMonths ? contract.discountMonths : ZERO);
    setBillingAmountToUpdate(contract.billingAmount);
    setRemarksToUpdate(contract.remarks!);
    setContractMembers(contract.users!);
    setLoaded(true);
  }, [
    params,
    user,
    setLoaded,
    setUser,
    setSpaces,
    setInitialContract,
    setContractNameToUpdate,
    setReprensentativeNameToUpdate,
    setReprensentativeEmailToUpdate,
    setStartDateToUpdate,
    setEndDateToUpdate,
    setContractPlanToUpdate,
    setSelectedSpaceToUpdate,
    setNumberOfKeysToUpdate,
    setNumberOfLockersToUpdate,
    setUseAdressToUpdate,
    setPaymentMethodToUpdate,
    setPaymentTypeToUpdate,
    setInflowSourceToUpdate,
    setServiceRetainerToUpdate,
    setTaxIncludedInitialDiscountAmountToUpdate,
    setTaxIncludedLongTermDiscountAmountToUpdate,
    setLongTermDiscountMonthsToUpdate,
    setBillingAmountToUpdate,
    setRemarksToUpdate,
    setContractMembers
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    setBillingAmountToUpdate(taxIncludedTotalAmountToUpdate);
  }, [setBillingAmountToUpdate, taxIncludedTotalAmountToUpdate]);

  const openContractListScreen = useSafeCallback((): void => {
    if (!base) return;
    onChange(embedIdInPath(Path.CONTRACT_LIST, PATH_IDS, [base.baseCode]));
  }, [onChange, base]);

  const updateContract = useSafeCallback(async (): Promise<void> => {
    if (!base) return;

    setEditable(false);
    setDisabledUpdateButton(true);

    const contractToUpdate = toContract(
      initialContract.contractId,
      contractNameToUpdate,
      initialContract.contractStatus,
      startDateToUpdate,
      endDateToUpdate,
      representativeNameToUpdate,
      representativeEmailToUpdate,
      contractPlanToUpdate,
      paymentMethodToUpdate,
      paymentTypeToUpdate,
      useAddressToUpdate,
      numberOfKeysToUpdate,
      numberOfLockersToUpdate,
      taxExcludedPlanPriceToUpdate,
      taxExcludedKeyIssuePriceToUpdate,
      taxExcludedLockerPriceToUpdate,
      taxExcludedAddressPriceToUpdate,
      serviceRetainerToUpdate,
      taxIncludedInitialDiscountAmountToUpdate,
      taxIncludedLongTermDiscountAmountToUpdate,
      longTermDiscountMonthsToUpdate,
      taxIncludedTotalAmountToUpdate,
      billingAmountToUpdate,
      remarksToUpdate,
      inflowSourceToUpdate,
      base.baseCode as Base
    );

    const request = builder<SaveContractRequest>().contract(contractToUpdate).build();
    const response = await ContractRequest.saveContract(request);
    !!response.contractId && enqueueSnackbar(CONTRACT_UPDATED, { variant: SUCCESS });
    setInitialContract(contractToUpdate);

    if (!!selectedSpaceToUpdate) {
      setContractId(selectedSpaceToUpdate, contractToUpdate);
      const spaceRequest = builder<SaveSpaceRequest>().space(selectedSpaceToUpdate).build();
      await SpaceRequest.saveSpace(spaceRequest);
    }

    setDisabledUpdateButton(false);
  }, [
    setEditable,
    setDisabledUpdateButton,
    initialContract,
    contractNameToUpdate,
    startDateToUpdate,
    endDateToUpdate,
    representativeNameToUpdate,
    representativeEmailToUpdate,
    contractPlanToUpdate,
    paymentMethodToUpdate,
    paymentTypeToUpdate,
    useAddressToUpdate,
    numberOfKeysToUpdate,
    numberOfLockersToUpdate,
    taxExcludedPlanPriceToUpdate,
    taxExcludedKeyIssuePriceToUpdate,
    taxExcludedLockerPriceToUpdate,
    taxExcludedAddressPriceToUpdate,
    serviceRetainerToUpdate,
    taxIncludedInitialDiscountAmountToUpdate,
    taxIncludedLongTermDiscountAmountToUpdate,
    longTermDiscountMonthsToUpdate,
    taxIncludedTotalAmountToUpdate,
    billingAmountToUpdate,
    remarksToUpdate,
    inflowSourceToUpdate,
    base,
    setInitialContract,
    enqueueSnackbar,
    selectedSpaceToUpdate
  ]);

  const deleteContract = useSafeCallback(async (): Promise<void> => {
    setDisabledUpdateButton(true);
    const request = builder<DeleteContractRequest>().contractId(initialContract.contractId).build();
    const response = await ContractRequest.deleteContract(request);
    !!response.contractId && enqueueSnackbar(CONTRACT_DELETED, { variant: SUCCESS });
    setDisabledUpdateButton(false);
  }, [setDisabledUpdateButton, initialContract, enqueueSnackbar]);

  const handleVerticalEllipsisButtonClicked = useSafeCallback(
    (id: VerticalEllipsisButtonType): void => {
      switch (id) {
        case VerticalEllipsisButtonType.EDIT_CONTRACT:
          setEditable(true);
          scroll(TOP);
          break;

        case VerticalEllipsisButtonType.INVITE_MEMBERS:
          setIsModalOpen(true);
          break;

        case VerticalEllipsisButtonType.DELETE_CONTRACT:
          deleteContract();
          setIsContractDeleted(true);
          break;
      }
    },
    [setEditable, setIsModalOpen, deleteContract, setIsContractDeleted]
  );

  const sendInvitationEmails = useSafeCallback(
    async (emails: Email[]): Promise<void> => {
      setIsModalOpen(false);

      const request = builder<SendInvitationToContractMembersRequest>()
        .base(base)
        .contract(initialContract)
        .emails(emails)
        .build();

      const response = await ContractRequest.sendInvitationToContractMembers(request);
      hasLength(response.emails) && enqueueSnackbar(EMAIL_SENT, { variant: SUCCESS });
    },
    [setIsModalOpen, base, initialContract, enqueueSnackbar]
  );

  const handleSpaceNameInput = useSafeCallback(
    async (spaceName: Name): Promise<void> => {
      const request = builder<SearchSpacesForContractRequest>()
        .base(base)
        .limit(MASTER_SEARCH_LIMIT)
        .offset(ZERO)
        .word(spaceName)
        .build();
      const response = await SpaceRequest.fetchSpacesForContract(request);
      setSpaces(response.spaces);
    },
    [base, setSpaces]
  );

  const handleSpaceSelected = useSafeCallback(
    (option: MasterSearchOption): void => {
      const selectedSpace = spaces.find(space => space.spaceId === option.value);
      setSelectedSpaceToUpdate(selectedSpace);
    },
    [spaces, setSelectedSpaceToUpdate]
  );

  return (
    <Component
      loading={!loaded}
      style={{ width: '100%', height: `calc(100vh - 64px)` }}
      className='contract-details-screen'
    >
      <Container>
        <Content>
          <SubTitle>契約詳細</SubTitle>
          <Title>Contract Details</Title>

          <FullInputWrapper>
            <InputBox
              required
              editable={editable}
              type='text'
              label='契約名'
              text={contractNameToUpdate}
              onChange={setContractNameToUpdate}
            />
          </FullInputWrapper>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                required
                editable={editable}
                type='text'
                label='代表者のお名前'
                text={representativeNameToUpdate}
                onChange={setReprensentativeNameToUpdate}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                required
                editable={editable}
                type='email'
                label='代表者のメールアドレス'
                text={representativeEmailToUpdate}
                onChange={setReprensentativeEmailToUpdate}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <MultiDateArea>
            <HalfDateWrapper>
              <DateBox
                required
                editable={editable}
                label='契約開始日'
                value={startDateToUpdate}
                onChange={setStartDateToUpdate}
              />
            </HalfDateWrapper>

            <HalfDateWrapper>
              <DateBox editable={editable} label='契約終了日' value={endDateToUpdate} onChange={setEndDateToUpdate} />
            </HalfDateWrapper>
          </MultiDateArea>

          {!!base && (
            <FullRadioWrapper>
              <RadioBox
                required
                title='プラン'
                editable={editable}
                options={contractPlans}
                labels={ContractPlanLabelByBase[base.baseCode]}
                value={contractPlanToUpdate}
                onChange={setContractPlanToUpdate}
              />
            </FullRadioWrapper>
          )}

          <FullInputWrapper>
            <MasterSearchBox
              editable={editable}
              placeholder='会議室'
              initLabel={!!selectedSpaceToUpdate ? selectedSpaceToUpdate.name : undefined}
              options={toMasterSearchOptions(spaces)}
              onInputChange={handleSpaceNameInput}
              onChange={handleSpaceSelected}
            />
          </FullInputWrapper>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                required
                editable={editable}
                type='number'
                label='利用登録人数（鍵発行数）'
                text={numberOfKeysToUpdate}
                onChange={setNumberOfKeysToUpdate}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                required
                editable={editable}
                type='number'
                label='ロッカー利用数'
                text={numberOfLockersToUpdate}
                onChange={setNumberOfLockersToUpdate}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <FullRadioWrapper>
            <RadioBox
              required
              title='住所利用'
              editable={editable}
              options={Object.values(UseAddress)}
              labels={CONTRACT_USE_ADDRESS_LABELS}
              value={useAddressToUpdate}
              onChange={setUseAdressToUpdate}
            />
          </FullRadioWrapper>

          <MultiRadioArea>
            <FlexibleRadioWrapper width={65}>
              <RadioBox
                required
                title='支払い方法'
                editable={editable}
                options={Object.values(ContractPaymentMethod)}
                labels={CONTRACT_PAYMENT_METHOD_LABELS}
                value={paymentMethodToUpdate}
                onChange={setPaymentMethodToUpdate}
              />
            </FlexibleRadioWrapper>

            <FlexibleRadioWrapper width={33}>
              <RadioBox
                required
                title='支払い回数'
                editable={editable}
                options={Object.values(PaymentType)}
                labels={CONTRACT_PAYMENT_TYPE_LABELS}
                value={paymentTypeToUpdate}
                onChange={setPaymentTypeToUpdate}
              />
            </FlexibleRadioWrapper>
          </MultiRadioArea>

          <FullRadioWrapper>
            <RadioBox
              editable={editable}
              title='流入経路'
              options={Object.values(InflowSource)}
              labels={CONTRACT_INFLOW_SOURCE_LABELS}
              value={inflowSourceToUpdate}
              onChange={setInflowSourceToUpdate}
            />
          </FullRadioWrapper>

          <SubTitle>料金</SubTitle>
          <Title>Prices</Title>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='プラン料金（税込）'
                text={PriceService.toTaxIncludedPrice(taxExcludedPlanPriceToUpdate)}
                onChange={noop}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='鍵発行料金（税込）'
                text={PriceService.toTaxIncludedPrice(taxExcludedKeyIssuePriceToUpdate)}
                onChange={noop}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='ロッカー利用料金（税込）'
                text={PriceService.toTaxIncludedPrice(taxExcludedLockerPriceToUpdate)}
                onChange={noop}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='住所利用料金（税込）'
                text={PriceService.toTaxIncludedPrice(taxExcludedAddressPriceToUpdate)}
                onChange={noop}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                editable={editable}
                type='number'
                label='サービスリテイナー'
                text={serviceRetainerToUpdate}
                onChange={setServiceRetainerToUpdate}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                editable={editable}
                type='number'
                label='初月割引額（税込）'
                text={taxIncludedInitialDiscountAmountToUpdate}
                onChange={setTaxIncludedInitialDiscountAmountToUpdate}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                editable={editable}
                type='number'
                label='1ヶ月あたりの長期割引額（税込）'
                text={taxIncludedLongTermDiscountAmountToUpdate}
                onChange={setTaxIncludedLongTermDiscountAmountToUpdate}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                editable={editable}
                type='number'
                label='割引期間（月）'
                text={longTermDiscountMonthsToUpdate}
                onChange={setLongTermDiscountMonthsToUpdate}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='合計金額（税込）'
                text={taxIncludedTotalAmountToUpdate}
                onChange={noop}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                editable={editable}
                type='number'
                label='請求金額（税込）'
                text={billingAmountToUpdate}
                onChange={setBillingAmountToUpdate}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <FullInputWrapper>
            <InputBox
              editable={editable}
              type='text'
              label='備考'
              text={remarksToUpdate}
              onChange={setRemarksToUpdate}
            />
          </FullInputWrapper>

          {hasLength(contractMembers) && (
            <ListWrapper>
              <ListTitle>登録しているメンバー</ListTitle>

              <List>
                {contractMembers.map((member, index) => (
                  <ListItem key={index} onClick={noop}>
                    <ListItemIcon>
                      <EmojiPeopleIcon />
                    </ListItemIcon>

                    <ListItemText primary={toFullName(member)} />
                  </ListItem>
                ))}
              </List>
            </ListWrapper>
          )}

          {!editable && (
            <MultiButtonsWrapper>
              <Button type='secondary' onClick={openContractListScreen}>
                <Label>契約一覧に戻る</Label>
              </Button>

              {!isContractDeleted && (
                <VerticalEllipsisButton
                  options={VERTICAL_ELLIPSIS_BUTTON_OPTIONS}
                  onClick={handleVerticalEllipsisButtonClicked}
                />
              )}
            </MultiButtonsWrapper>
          )}

          {editable && (
            <MultiButtonsWrapper>
              <Button disabled={disabledUpdateButton} type='primary' onClick={updateContract}>
                <Label>更新する</Label>
              </Button>
            </MultiButtonsWrapper>
          )}

          <ContractModal isOpen={isModalOpen} onClick={sendInvitationEmails} onClose={() => setIsModalOpen(false)} />
        </Content>
      </Container>
    </Component>
  );
});

export default ContractDetailsScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  margin: ${theme.mixins.spacing * 4}px 0px 0px ${theme.mixins.spacing * 3}px;
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 56px;
  margin: 0px 0px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
`;

const MultiRadioArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const FullRadioWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const FlexibleRadioWrapper = styled.div<{ width: Width }>`
  width: ${props => props.width}%;
  height: auto;
`;

const MultiInputArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const FullInputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const HalfInputWrapper = styled.div`
  width: 49%;
  height: auto;
`;

const MultiDateArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const HalfDateWrapper = styled.div`
  width: 49%;
  height: auto;
`;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px;
`;

const ListTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.54);
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding: 0px 0px ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
`;

const List = styled(MuiList)`
  background: ${theme.mixins.background.white};
  border-radius: ${theme.mixins.spacing * 2}px;
  padding: 0px;
`;

const MultiButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 4}px;
`;

const Label = styled(Typography)`
  width: 160px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;
