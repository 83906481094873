import {
  CardData,
  CardDeckRef,
  Component,
  PositionEnum,
  ReactUseGestureEventHandlers,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef,
  YSwipableDeck
} from '@atomica.co/components';
import { hasLength } from '@atomica.co/utils';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SpaceReservationCard } from '../../../../converters/my-account-converter';
import { MOBILE_HEADER_HEIGHT } from '../../../consumer/ConsumerSwitcher';
import ReservationCard from './ReservationCard';

interface P {
  showCompleted: boolean;
  spaceReservationCards: SpaceReservationCard[];
}

const ReservationCards: React.ForwardRefExoticComponent<P & React.RefAttributes<CardDeckRef>> = React.forwardRef<
  CardDeckRef,
  P
>((props, ref) => {
  const { showCompleted, spaceReservationCards } = props;
  const unmountRef = useUnmountRef();
  const [cardDataList, setCardDataList] = useSafeState<CardData[]>(unmountRef, []);

  const initialize = useSafeCallback((): void => {
    const cards = spaceReservationCards.map((spaceReservationCard, propsIndex) => {
      return {
        id: spaceReservationCard.spaceReservationId,
        propsIndex,
        visible: true,
        position: PositionEnum.CENTER,
        node: (bind: () => ReactUseGestureEventHandlers, isFront: boolean): React.ReactNode => {
          return (
            <ReservationCard
              showCompleted={showCompleted}
              spaceReservationCard={spaceReservationCard}
              bind={bind}
              isFront={isFront}
            />
          );
        }
      };
    });

    setCardDataList(cards);
  }, [showCompleted, spaceReservationCards, setCardDataList]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    !!(ref as React.RefObject<CardDeckRef>).current &&
      (ref as React.RefObject<CardDeckRef>).current!.moveCard(PositionEnum.CENTER);
  }, [ref]);

  return (
    <Component className='reservation-cards'>
      <Container>
        <Content>
          {hasLength(cardDataList) && (
            <YSwipableDeck
              ref={ref}
              cardDataList={cardDataList}
              headerHeight={MOBILE_HEADER_HEIGHT + theme.mixins.spacing * 8}
            />
          )}
        </Content>
      </Container>
    </Component>
  );
});

export default ReservationCards;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
`;
