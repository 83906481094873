import {
  Button,
  Component,
  DateBox,
  RadioBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  Access,
  Base,
  BASE_CODE,
  Contract,
  ContractUsage,
  EventParticipant,
  FetchAccessesByDatesRequest,
  FetchContractsByDatesRequest,
  FetchShotUsagesByDatesRequest,
  Shot
} from '@atomica.co/irori';
import { builder, hasLength, toBeginningOfDay, toEndOfDay } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { convertToAccessesCSV, convertToContractsCSV, convertToShotCSV } from '../../converters/export-converter';
import { ExportTarget } from '../../enums/export-enum';
import { CSVTemplate } from '../../models/common-model';
import usePath from '../../redux/hooks/usePath';
import AccessRequest from '../../requests/access-request';
import ContractRequest from '../../requests/contract-request';
import ShotUsageRequest from '../../requests/shot-request';
import { ACCESS_HEADERS, CONTRACT_HEADERS, EXPORT_LABELS, SHOT_HEADERS } from '../../texts/export-text';

interface P extends RouteComponentProps {}

const ExportScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { params } = usePath();
  const csvInstance = useRef<any>();
  const [disabledExportButton, setDisabledExportButton] = useSafeState<boolean>(unmountRef, true);
  const [exportTarget, setExportTarget] = useSafeState<ExportTarget>(unmountRef, ExportTarget.SHOT_USAGE);
  const [startDate, setStartDate] = useSafeState<Date>(unmountRef, toBeginningOfDay(new Date()));
  const [endDate, setEndDate] = useSafeState<Date>(unmountRef, toEndOfDay(new Date()));
  const [csvHeaders, setCsvHeaders] = useSafeState<CSVTemplate[]>(unmountRef, []);
  const [csvContent, setCsvContent] = useSafeState<
    Shot[] | Contract[] | ContractUsage[] | Access[] | EventParticipant[]
  >(unmountRef, []);

  const base = useMemo<Base>(() => {
    return params[BASE_CODE];
  }, [params]);

  useEffect(() => {
    setDisabledExportButton(!base || !startDate || !endDate);
  }, [setDisabledExportButton, base, startDate, endDate]);

  const exportShotUsagesCSV = useSafeCallback(async (): Promise<void> => {
    const usageRequest = builder<FetchShotUsagesByDatesRequest>().base(base).start(startDate).end(endDate).build();
    const usageResponse = await ShotUsageRequest.fetchShotUsagesByDates(usageRequest);
    setCsvHeaders(SHOT_HEADERS);
    setCsvContent(convertToShotCSV(usageResponse.shots));
  }, [base, startDate, endDate, setCsvHeaders, setCsvContent]);

  const exportConstactsCSV = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchContractsByDatesRequest>().base(base).start(startDate).end(endDate).build();
    const response = await ContractRequest.fetchContractsByDates(request);
    setCsvHeaders(CONTRACT_HEADERS);
    setCsvContent(convertToContractsCSV(base, response.contracts));
  }, [base, startDate, endDate, setCsvHeaders, setCsvContent]);

  // const exportConstactUsagesCSV = useSafeCallback(async (): Promise<void> => {
  //   const request = builder<FetchContractUsagesByDatesRequest>().base(base).start(startDate).end(endDate).build();
  //   const response = await ContractRequest.fetchContractUsagesByDate(request);
  //   setCsvHeaders(CONTRACT_USAGE_HEADERS);
  //   setCsvContent(convertToContractUsagesCSV(base, response.usages));
  // }, [base, startDate, endDate, setCsvHeaders, setCsvContent]);

  const exportAccessesCSV = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchAccessesByDatesRequest>().base(base).start(startDate).end(endDate).build();
    const response = await AccessRequest.fetchAccessesByDates(request);
    setCsvHeaders(ACCESS_HEADERS);
    setCsvContent(convertToAccessesCSV(response.accesses));
  }, [base, startDate, endDate, setCsvHeaders, setCsvContent]);

  // const exportEventParticipantsCSV = useSafeCallback(async (): Promise<void> => {
  //   const participantsRequest = builder<FetchEventParticipantsByDatesRequest>().base(base).start(startDate).end(endDate).build();
  //   const participantsResponse = await EventParticipantRequest.fetchEventParticipantsByDates(participantsRequest);
  //   setCsvHeaders(PARTICIPANT_HEADERS);
  //   setCsvContent(convertToParticipantsCSV(participantsResponse.participants));
  // }, [base, startDate, endDate, setCsvHeaders, setCsvContent]);

  const exportCSV = useSafeCallback(async (): Promise<void> => {
    setDisabledExportButton(true);

    switch (exportTarget) {
      case ExportTarget.SHOT_USAGE:
        await exportShotUsagesCSV();
        break;

      case ExportTarget.CONTRACT:
        await exportConstactsCSV();
        break;

      // case ExportTarget.CONTRACT_USAGE:
      //   await exportConstactUsagesCSV();
      //   break;

      case ExportTarget.ACCESS:
        await exportAccessesCSV();
        break;

      // case ExportTarget.MEETAT_PARTICIPANT:
      //   await exportEventParticipantsCSV();
      //   break;

      default:
        throw new Error(`${exportTarget} is out of target.`);
    }

    setDisabledExportButton(false);
  }, [
    setDisabledExportButton,
    exportTarget,
    exportShotUsagesCSV,
    // exportEventParticipantsCSV,
    exportConstactsCSV,
    // exportConstactUsagesCSV,
    exportAccessesCSV
  ]);

  useEffect(() => {
    const isReadyToExport = !!csvContent && !!csvInstance && !!csvInstance.current && !!csvInstance.current.link;
    if (!isReadyToExport) return;

    setTimeout(() => {
      csvInstance.current.link.click();
      setCsvContent([]);
    });
  }, [csvContent, setCsvContent]);

  const handleStartDateChanged = useSafeCallback(
    (date: Date) => {
      if (!date) return;
      setStartDate(toBeginningOfDay(date)!);
    },
    [setStartDate]
  );

  const handleEndDateChanged = useSafeCallback(
    (date: Date) => {
      if (!date) return;
      setEndDate(toEndOfDay(date)!);
    },
    [setEndDate]
  );

  return (
    <Component className='export-screen'>
      <Container>
        <Content>
          <SubTitle>データ出力</SubTitle>

          <Title>Data Export</Title>

          <RadioWrapper>
            <RadioBox
              title='対象'
              options={Object.values(ExportTarget)}
              labels={EXPORT_LABELS}
              value={exportTarget}
              onChange={setExportTarget}
            />
          </RadioWrapper>

          <DateWrapper>
            <PartialDateWrapper>
              <DateBox label='開始日' value={startDate} onChange={handleStartDateChanged} />
            </PartialDateWrapper>

            <PartialDateWrapper>
              <DateBox label='終了日' value={endDate} onChange={handleEndDateChanged} />
            </PartialDateWrapper>
          </DateWrapper>

          <ButtonWrapper>
            <Button type='primary' disabled={disabledExportButton} onClick={exportCSV}>
              <Label>CSV出力</Label>
            </Button>

            {hasLength(csvContent) && <CSVLink ref={csvInstance} headers={csvHeaders} data={csvContent} />}
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default ExportScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  margin: ${theme.mixins.spacing * 4}px 0px 0px ${theme.mixins.spacing * 3}px;
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 56px;
  margin: 0px 0px ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const RadioWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const DateWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const PartialDateWrapper = styled.div`
  width: calc(50% - ${theme.mixins.spacing / 2}px);
  height: auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const Label = styled(Typography)`
  width: 160px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;
