import { SpaceParticipantDiv } from '@atomica.co/irori';
export const LICENSE_KEY = '0316164955-fcs-1646218598';

export const EVENT_PARTICIPANT_DIV_COLOR = {
  [SpaceParticipantDiv.MEMBER]: '#f57f17',
  [SpaceParticipantDiv.OWNER]: '#ff0000',
  default: '#3788d8'
};

export const DAY_OF_WEEK_IDX = [0, 1, 2, 3, 4, 5, 6];
