import {
  Component,
  SearchBox,
  Skeleton,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseAuthority,
  BaseDto,
  FetchBaseAuthoritiesRequest,
  SavePersonInChargeRequest,
  SearchUsersRequest,
  Sort,
  User,
  UserId,
  UserSortColumn
} from '@atomica.co/irori';
import { builder, EMPTY, hasLength, Offset, Word, ZERO } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Add, CheckCircle, RemoveCircle } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ERROR, SUCCESS, USER_AUTH_ERROR, USER_AUTH_SAVED } from '../../constants/snackbar-const';
import { toPersonInChargeToSave } from '../../converters/user-converter';
import BaseAuthorityRequest from '../../requests/base-authority-request';
import PersonInChargeRequest from '../../requests/person-in-charge-request';
import UserRequest from '../../requests/user-request';
import mojaco from './../../assets/mojaco/mojaco_bow.png';
import UserAuthorityModal from './user-authority-modal/UserAuthorityModal';
import UserPropSortTh from './user-authority-sort-th/UserAuthoritySortTh';

const LIMIT = 20;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P {
  base: BaseDto;
}

const UserSearchScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const unmountRef = useUnmountRef();
  const { enqueueSnackbar } = useSnackbar();
  const bottomRef = useRef<HTMLDivElement>();
  const loaded = useRef<boolean>(false);
  const offset = useRef<Offset>(ZERO);
  const hasMore = useRef<boolean>(true);
  const { SORT_EMAIL, SORT_FAMILY_NAME, SORT_PHONE, SORT_AUTHORITY } = UserSortColumn;
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [searchingWord, setSearchingWord] = useSafeState<string>(unmountRef, EMPTY);
  const [authorities, setAuthorities] = useSafeState<BaseAuthority[]>(unmountRef, []);
  const [users, setUsers] = useSafeState<User[]>(unmountRef, []);
  const [selectedUserIds, setSelectedUserIds] = useSafeState<UserId[]>(unmountRef, []);
  const [sortKey, setSortKey] = useSafeState<UserSortColumn>(unmountRef, SORT_AUTHORITY);
  const [sort, setSort] = useSafeState<Sort>(unmountRef, Sort.ASC);
  const isUserSelected = useMemo<boolean>(() => hasLength(selectedUserIds), [selectedUserIds]);

  const fetchBaseAuthorities = useSafeCallback(async (): Promise<BaseAuthority[]> => {
    const request = builder<FetchBaseAuthoritiesRequest>().baseCode(base.baseCode).build();
    const response = await BaseAuthorityRequest.fetchAuthorities(request);
    return response.authorities;
  }, [base]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const authorities = await fetchBaseAuthorities();
    setAuthorities(authorities);
  }, [fetchBaseAuthorities, setAuthorities]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const searchUsers = useSafeCallback(
    async (sortKey: UserSortColumn, sort: Sort, word: Word): Promise<User[]> => {
      if (!hasMore.current) return [];

      const request = builder<SearchUsersRequest>()
        .baseCode(base.baseCode)
        .limit(LIMIT)
        .offset(offset.current)
        .sortCol(sortKey)
        .sort(sort)
        .word(word)
        .build();
      const response = await UserRequest.searchUsers(request);
      const users = response.users;

      offset.current += LIMIT;
      hasMore.current = users.length === LIMIT;
      return users;
    },
    [base]
  );

  const onScroll = useSafeCallback(
    async (entries: IntersectionObserverEntry[]): Promise<void> => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        const usersToAdd = await searchUsers(sortKey, sort, searchingWord);
        setUsers(prevUsers => [...prevUsers, ...usersToAdd]);
      }
    },
    [searchUsers, sortKey, sort, searchingWord, setUsers]
  );

  useEffect(() => {
    if (loaded.current) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);
    bottomRef.current && observer.observe(bottomRef.current);
    loaded.current = true;

    return () => {
      observer.disconnect();
    };
  }, [loaded, onScroll]);

  const refreshUsers = useSafeCallback(
    async (sortKey: UserSortColumn, sort: Sort, word: Word): Promise<void> => {
      offset.current = ZERO;
      hasMore.current = true;
      setSearchingWord(word);
      const users = await searchUsers(sortKey, sort, word);
      setUsers(users);
      setSelectedUserIds([]);
    },
    [setSearchingWord, searchUsers, setUsers, setSelectedUserIds]
  );

  useEffect(() => {
    refreshUsers(sortKey, sort, searchingWord);
  }, [refreshUsers, sortKey, sort, searchingWord]);

  const handleClickSortTh = useSafeCallback(
    (key: UserSortColumn): void => {
      const { ASC, DESC } = Sort;
      setSortKey(prevSortKey => {
        setSort(prevSort => {
          return key === prevSortKey ? (prevSort === ASC ? DESC : ASC) : ASC;
        });
        return key;
      });
    },
    [setSortKey, setSort]
  );

  const handleClickHeadCheck = useSafeCallback((): void => {
    setSelectedUserIds(selectedUserId =>
      users.length === selectedUserId.length ? [] : users.map(user => user.userId)
    );
  }, [setSelectedUserIds, users]);

  const handleClickRow = useSafeCallback(
    (index: number): void => {
      setSelectedUserIds(selectedUserId => {
        const userId: UserId = users[index].userId;
        const newSelected: UserId[] = selectedUserId.concat();
        newSelected.includes(userId) ? newSelected.splice(newSelected.indexOf(userId), 1) : newSelected.push(userId);
        return newSelected;
      });
    },
    [setSelectedUserIds, users]
  );

  const openModal = useSafeCallback((): void => {
    if (!isUserSelected) return;
    setIsModalOpen(true);
  }, [isUserSelected, setIsModalOpen]);

  const savePersonInCharge = useSafeCallback(
    async (selectedIdx: number): Promise<void> => {
      const targetUsers = users.filter(targetUser => selectedUserIds.includes(targetUser.userId));
      const personInCharges = toPersonInChargeToSave(base, targetUsers, authorities[selectedIdx]);
      const request = builder<SavePersonInChargeRequest>().personInCharges(personInCharges).build();
      const response = await PersonInChargeRequest.savePersonInCharge(request);
      enqueueSnackbar(hasLength(response.personInChargeIds) ? USER_AUTH_SAVED : USER_AUTH_ERROR, {
        variant: hasLength(response.personInChargeIds) ? SUCCESS : ERROR
      });
      refreshUsers(sortKey, sort, searchingWord);
      setIsModalOpen(false);
    },
    [
      users,
      selectedUserIds,
      base,
      authorities,
      enqueueSnackbar,
      refreshUsers,
      sortKey,
      sort,
      searchingWord,
      setIsModalOpen
    ]
  );

  return (
    <Component className='user-search-screen'>
      <Container>
        <Content>
          <SubTitle>ユーザー一覧</SubTitle>

          <Title>Users</Title>

          <FunctionWrapper>
            <UserAuthChangeLabel isUserSelected={isUserSelected} onClick={openModal}>
              <AddIcon />
              権限を変更する
            </UserAuthChangeLabel>
            <SearchBox type='text' placeholder='検索' text={searchingWord} onChange={setSearchingWord} />
          </FunctionWrapper>
          <Table>
            <Thead>
              <Tr>
                <Th onClick={handleClickHeadCheck}>
                  <IconWrapper>
                    {!isUserSelected ? (
                      <CheckCircleIcon checked={false} />
                    ) : users.length === selectedUserIds.length ? (
                      <CheckCircleIcon checked={true} />
                    ) : (
                      <PartialCheckCircleIcon checked={true} />
                    )}
                  </IconWrapper>
                </Th>
                <Th></Th>
                <UserPropSortTh
                  colLabel='名前'
                  colName={SORT_FAMILY_NAME}
                  sortKey={sortKey}
                  sort={sort}
                  handleClick={handleClickSortTh}
                />
                <UserPropSortTh
                  colLabel='電話番号'
                  colName={SORT_PHONE}
                  sortKey={sortKey}
                  sort={sort}
                  handleClick={handleClickSortTh}
                />
                <UserPropSortTh
                  colLabel='メールアドレス'
                  colName={SORT_EMAIL}
                  sortKey={sortKey}
                  sort={sort}
                  handleClick={handleClickSortTh}
                />
                <UserPropSortTh
                  colLabel='権限'
                  colName={SORT_AUTHORITY}
                  sortKey={sortKey}
                  sort={sort}
                  handleClick={handleClickSortTh}
                />
              </Tr>
            </Thead>
            {users.length > 0 && (
              <Tbody>
                {users.map((row, index) => (
                  <Tr key={`tr${index}`} onClick={() => handleClickRow(index)}>
                    <TdCheck>
                      <IconWrapper>
                        <CheckCircleIcon checked={selectedUserIds.includes(row.userId)} />
                      </IconWrapper>
                    </TdCheck>
                    <TdPhoto>
                      <Skeleton style={styleForPhoto} src={!!row.photoURL ? row.photoURL : mojaco} />
                    </TdPhoto>
                    <Td>{`${row.familyName} ${row.firstName}`}</Td>
                    <Td>{row.phone}</Td>
                    <Td>{row.email}</Td>
                    <Td>
                      {row.personInCharge ? row.personInCharge[ZERO].authority.authorityDef.authorityDefName : '未設定'}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>

          <Bottom ref={bottomRef} />
        </Content>

        <UserAuthorityModal
          authority={authorities}
          isOpen={isModalOpen}
          onSave={savePersonInCharge}
          onClose={() => setIsModalOpen(false)}
          target={users.filter(user => selectedUserIds.includes(user.userId))}
        />
      </Container>
    </Component>
  );
});

export default UserSearchScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 5}px ${theme.mixins.spacing * 6}px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1280px;
  height: auto;
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 56px;
`;

const FunctionWrapper = styled.div`
  width: 100%;
  height: 30px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: ${theme.mixins.spacing * 2}px;
`;

const UserAuthChangeLabel = styled.div<{ isUserSelected: boolean }>`
  height: 30px;
  display: flex;
  align-items: center;
  gap: ${theme.mixins.spacing / 2}px;

  color: ${props =>
    props.isUserSelected ? theme.mixins.typography.fontColor.gray : theme.mixins.typography.fontColor.lightGray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  cursor: pointer;
`;

const AddIcon = styled(Add)`
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;

const Table = styled.table`
  width: 100%;
  height: auto;
  margin-top: ${theme.mixins.spacing}px;
  border-spacing: 0 ${theme.mixins.spacing / 4}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.sixteen + 2}px;
`;
const Thead = styled.thead`
  width: 100%;
  background: ${theme.mixins.background.white};
`;
const Th = styled.th`
  text-align: left;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
`;
const Tr = styled.tr`
  background: ${theme.mixins.background.white};
`;
const Td = styled.td`
  height: 64px;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
`;
const TdCheck = styled(Td)`
  width: 48px;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;
const TdPhoto = styled(Td)`
  width: 56px;
  padding: 0px;
`;

const Tbody = styled.tbody``;

const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
`;

const CheckCircleIcon = styled(CheckCircle)`
  color: ${({ checked }) => (checked ? theme.mixins.background.pink : theme.mixins.background.gray)};
  font-size: ${theme.mixins.typography.fontSize.twentyEight}px;
`;
const PartialCheckCircleIcon = styled(RemoveCircle)`
  color: ${({ checked }) => (checked ? theme.mixins.background.pink : theme.mixins.background.gray)};
  font-size: ${theme.mixins.typography.fontSize.twentyEight}px;
`;

const styleForPhoto: CSSProperties = {
  width: 48,
  height: 48,
  background: theme.mixins.background.lightGray,
  border: `2px solid ${theme.mixins.border.lightGray}`,
  borderRadius: '50%',
  objectFit: 'contain',
  transform: 'none'
};

const Bottom = styled.div``;
