import { Component, theme, useSafeCallback } from '@atomica.co/components';
import { Space, SpaceId, SpaceReservation, User } from '@atomica.co/irori';
import { hasLength, Minute } from '@atomica.co/utils';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar, { DateSelectArg, EventClickArg } from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { LICENSE_KEY } from '../../../constants/calendar-consts';
import {
  convertToTimelineEvents,
  convertToTimelineResources,
  SpaceResourceRow,
  TimelineEventRow
} from '../../../converters/space-converter';
import '../../../styles/bootstrap-journal.min.css';

const DEFAULT_START_UNIT = 30;

const findStartUnit = (spaces: Space[]): Minute => {
  if (!hasLength(spaces)) return DEFAULT_START_UNIT;
  const space = spaces.sort((a, b) => a.reservationStartUnit - b.reservationStartUnit)[0];
  return !!space.reservationStartUnit ? space.reservationStartUnit : DEFAULT_START_UNIT;
};

interface P {
  selectedDate: Date;
  reservations: SpaceReservation[];
  setSelectedDate: (selectedDate: Date) => void;
  spaces: Space[];
  selectedSpaceIds: SpaceId[];
  user: User;
  handleClickEvent: (arg: EventClickArg) => void;
  handleSelect: (arg: DateSelectArg) => void;
}

const ReserveTime: React.FC<P> = React.memo(props => {
  const {
    selectedDate,
    reservations,
    selectedSpaceIds,
    setSelectedDate,
    spaces,
    user,
    handleClickEvent,
    handleSelect
  } = props;

  const timelineRef = useRef<FullCalendar>(null);
  const calendarApi = timelineRef.current && timelineRef.current.getApi();

  const resources = useMemo<SpaceResourceRow[]>(
    () => convertToTimelineResources(spaces, selectedSpaceIds, selectedDate),
    [selectedSpaceIds, spaces, selectedDate]
  );
  const events = useMemo<TimelineEventRow[]>(
    () => convertToTimelineEvents(reservations, selectedSpaceIds, user),
    [reservations, selectedSpaceIds, user]
  );

  const setCalendarDate = useSafeCallback(
    (num: number) => {
      setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + num)));
      num > 0 ? calendarApi?.next() : calendarApi?.prev();
    },
    [calendarApi, selectedDate, setSelectedDate]
  );

  useEffect(() => {
    if (!selectedDate) return;
    calendarApi?.gotoDate(selectedDate);
  }, [calendarApi, selectedDate]);

  return (
    <Component className='select-date'>
      <Container>
        <Content>
          <ItemNameWrapper>
            <ItemName>3. 時間の選択（3/3）</ItemName>
          </ItemNameWrapper>
          <Description>予約したい時間を選択（長押し）してください。</Description>
          <CalendarWrapper>
            <FullCalendar
              headerToolbar={{
                left: 'customprev customnext',
                center: 'title',
                right: 'customtoday'
              }}
              customButtons={{
                customprev: {
                  text: '<',
                  click: () => {
                    setCalendarDate(-1);
                  }
                },
                customnext: {
                  text: '>',
                  click: () => {
                    setCalendarDate(1);
                  }
                },
                customtoday: {
                  text: '今日',
                  click: () => {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    setSelectedDate(today);
                  }
                }
              }}
              locale='ja'
              timeZone='local'
              initialView='resourceTimeGridDay'
              themeSystem='bootstrap'
              contentHeight='auto'
              resourceOrder='order'
              selectConstraint='businessHours'
              resourceAreaHeaderContent='会議室'
              selectable
              nowIndicator
              resourcesInitiallyExpanded
              allDaySlot={false}
              selectOverlap={false}
              dayMinWidth={120}
              longPressDelay={0}
              eventLongPressDelay={200}
              selectLongPressDelay={200}
              slotMinTime={{ hour: 8, minute: 0 }}
              slotMaxTime={{ hour: 23, minute: 0 }}
              slotDuration={{ minutes: findStartUnit(spaces) }}
              slotLabelFormat={{ hour: 'numeric', minute: '2-digit' }}
              ref={timelineRef}
              events={events}
              plugins={[bootstrapPlugin, interactionPlugin, resourceTimeGridPlugin, scrollGridPlugin]}
              resources={resources}
              schedulerLicenseKey={LICENSE_KEY}
              select={handleSelect}
              eventClick={(arg: EventClickArg) => handleClickEvent(arg)}
            />
          </CalendarWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default ReserveTime;

const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing}px;
  padding: ${theme.mixins.spacing * 3}px;

  ${media.lessThan('small')`
  padding: ${theme.mixins.spacing * 2}px;
  `}
`;

const ItemNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ItemName = styled(Typography)`
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  border-left: 4px solid ${theme.mixins.border.lightGray};
  padding-left: ${theme.mixins.spacing * 2}px;

  ${media.lessThan('small')`
    font-size: 14px;
    padding: ${theme.mixins.spacing}px;
  `}
`;

const Description = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;

  ${media.lessThan('small')`
    font-size: 14px;
  `}
`;

const CalendarWrapper = styled.div`
  padding: ${theme.mixins.spacing * 3}px;
  border: 1px solid ${theme.mixins.border.lightGray};
  border-top: 4px solid ${theme.mixins.border.orange};

  .fc-daygrid-day-events {
    min-height: 5em !important;
  }
  .fc-datagrid-body {
    width: auto !important;
    min-width: 100%;
  }

  ${media.lessThan('small')`
    padding: ${theme.mixins.spacing}px;
    .btn {
      padding: 4px 8px;
      font-size: ${theme.mixins.typography.fontSize.twelve}px;
    }
    .fc-toolbar-title {
      font-size: ${theme.mixins.typography.fontSize.twenty}px;
    }
    .fc-daygrid-body-natural .fc-daygrid-day-events {
      margin-bottom: 0;
    }
    .fc-daygrid-day-events {
      min-height: 1em !important;
    }
  `}
`;
