import { Component, Skeleton, theme } from '@atomica.co/components';
import { UserEntity } from '@atomica.co/irori';
import { UserId } from '@atomica.co/irori';
import { Color, EMPTY, toTimeStr } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { ACCESS_STATUS_COLORS } from '../../constants/shot-const';
import { ACCESS_LABELS } from '../../texts/access-text';
import { toFullName } from '../../utils/user-util';
import mojaco from './../../assets/mojaco/mojaco_normal.png';

type Rotate = 'none' | 'right' | 'left';

interface P extends RouteComponentProps {
  rotate?: Rotate;
  user: UserEntity;
  onClick(userId: UserId): void;
}

const MemberPolaroid: React.FC<P> = React.memo(props => {
  const { rotate = 'none', user, onClick } = props;

  return (
    <Component className='member-polaroid'>
      <Container>
        <Frame rotate={rotate} onClick={() => onClick(user.userId)}>
          <PhotoWrapper>
            <Skeleton style={styleForPhoto} src={!!user.photoURL ? user.photoURL : mojaco} />
          </PhotoWrapper>

          <Name>{toFullName(user)}</Name>

          <Table>
            <Tr>
              <TdLabel>入退館時刻</TdLabel>
              <TdValue>{!!user.createdAt ? toTimeStr(user.createdAt) : EMPTY}</TdValue>
            </Tr>
          </Table>
        </Frame>

        {!!user.status && <Tag background={ACCESS_STATUS_COLORS[user.status]}>{ACCESS_LABELS[user.status]}</Tag>}
      </Container>
    </Component>
  );
});

export default MemberPolaroid;

const Container = styled.div`
  width: 272px;
  height: 352px;
  position: relative;
`;

const Frame = styled.div<{ rotate: Rotate }>`
  width: 240px;
  height: 320px;
  background: ${theme.mixins.background.white};
  border: theme.mixins.border.lightGray;
  box-shadow: theme.mixins.shadow.light;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: unset;
  cursor: pointer;
  margin: ${theme.mixins.spacing * 2}px;
`;

const PhotoWrapper = styled.div`
  width: 208px;
  height: 208px;
`;

const styleForPhoto: CSSProperties = {
  width: 208,
  height: 208,
  background: theme.mixins.background.lightGray,
  // border: theme.mixins.border.lightGray,
  // boxShadow: `inset ${theme.mixins.shadow.light}`,
  objectFit: 'contain'
};

const Name = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding: ${theme.mixins.spacing}px;
`;

const Tag = styled.div<{ background: Color }>`
  position: absolute;
  top: 4px;
  right: 16px;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: 14px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  background: ${props => props.background};
  border-radius: 20px;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 1.5}px;
`;

const Table = styled.table``;

const Tr = styled.tr``;

const TdLabel = styled.td`
  width: 80px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 14px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const TdValue = styled.td`
  width: 120px;
  max-width: 136px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 14px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
