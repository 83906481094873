import { Screen as BaseScreen } from '@atomica.co/components';
import { EMPTY, Message } from '@atomica.co/utils';
import React from 'react';
import ErrorMessage from '../error/ErrorMessage';
import atomica from './../../assets/atomica.gif';

interface P {
  errorMsg?: Message;
  loading?: boolean;
  loadingMsg?: Message;
  className: string;
  children?: React.ReactNode;
}

const Screen: React.FC<P> = React.memo(props => {
  const { errorMsg = EMPTY, loading, loadingMsg, className, children } = props;

  return (
    <>
      {!errorMsg && (
        <BaseScreen loading={loading} src={atomica} message={loadingMsg} className={className}>
          {children}
        </BaseScreen>
      )}

      {!!errorMsg && <ErrorMessage message={errorMsg} />}
    </>
  );
});

export default Screen;
