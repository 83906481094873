import {
  APP_NAME,
  FetchEventV2Request,
  FetchEventV2Response,
  SaveEventV2Request,
  SaveEventV2Response,
  SearchEventsV2Request,
  SearchEventsV2Response,
  FETCH_EVENT_V2,
  SAVE_EVENT_V2,
  SEARCH_EVENTS_V2,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions, storage } from '../firebase';

const COLLECTION_NAME_PHOTOS = 'photos';

export default class EventV2Request {

  public static uploadEventPhoto = async (image: File): Promise<URL> => {
    try {
      //const compressedImage = await compress(image, OPTIONS);
      const ref = storage.ref(`/${COLLECTION_NAME_PHOTOS}/${APP_NAME}/events/`);
      //const snapshot = await ref.put(compressedImage);
      const snapshot = await ref.put(image);
      return await snapshot.ref.getDownloadURL();
    } catch (e) {
      throw Error(JSON.stringify(e));
    }
  };

  public static saveEvent = async (request: SaveEventV2Request): Promise<SaveEventV2Response> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_EVENT_V2));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static searchEvents = async (request: SearchEventsV2Request): Promise<SearchEventsV2Response> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SEARCH_EVENTS_V2));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchEvent = async (request: FetchEventV2Request): Promise<FetchEventV2Response> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_EVENT_V2));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

}
