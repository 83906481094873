import {
  Button,
  Component,
  InputBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Base, EventV2, SearchEventsV2Request } from '@atomica.co/irori';
import { builder, embedIdInPath, EMPTY, Offset, Word } from '@atomica.co/utils';
import { List as MuiList, ListItem, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SearchOption } from '../../enums/event-v2-enum';
import EventV2Request from '../../requests/event-v2-request';
import { Path, PATH_IDS } from '../../router/Routes';

interface P {
  base: Base;
  onChange(path: Path): void;
}

const EventV2ListScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const { onChange } = props;
  const unmountRef = useUnmountRef();
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [events, setEvents] = useSafeState<EventV2[]>(unmountRef, []);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, SearchOption.OFFSET);

  const [totalCount, setTotalCount] = useSafeState<number>(unmountRef, 0);
  const [start, setStart] = useSafeState<number>(unmountRef, 0);
  const [end, setEnd] = useSafeState<number>(unmountRef, 0);
  const [prevPage, setPrevPage] = useSafeState<boolean>(unmountRef, false);
  const [nextPage, setNextPage] = useSafeState<boolean>(unmountRef, false);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<SearchEventsV2Request>()
      .base(base)
      .limit(SearchOption.LIMIT)
      .offset(offset)
      .word(searchingWord)
      .build();
    const response = await EventV2Request.searchEvents(request);

    setEvents(response.eventsV2);
    setTotalCount(response.totalCount);
    setStart(response.start);
    setEnd(response.end);
    setPrevPage(response.prevPage);
    setNextPage(response.nextPage);
  }, [base, searchingWord, offset, setEvents, setEnd, setNextPage, setPrevPage, setStart, setTotalCount]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setSearchingWord(word);
      setOffset(0);
    },
    [setSearchingWord, setOffset]
  );

  const openEventV2DetailsScreen = useSafeCallback(
    (eventV2: EventV2): void => {
      onChange(embedIdInPath(Path.EVENT_V2_DETAILS, PATH_IDS, [base, eventV2.eventId]));
    },
    [onChange, base]
  );

  const openRegisterEventV2Screen = useSafeCallback((): void => {
    onChange(embedIdInPath(Path.REGISTER_EVENT_V2, PATH_IDS, [base]));
  }, [onChange, base]);

  return (
    <Component className='event-list-screen'>
      <Container>
        <Content>
          <SubTitle>イベント</SubTitle>

          <Title>Event</Title>

          <ButtonWrapper>
            <Button onClick={openRegisterEventV2Screen}>
              <AddIcon />
            </Button>
          </ButtonWrapper>

          <InputWrapper>
            <InputBox type='text' label='検索' text={searchingWord} onChange={handleSearchingWordChanged} />
          </InputWrapper>

          <ListWrapper>
            <List>
              {events.map((event, index) => (
                <ListItem key={index} onClick={() => openEventV2DetailsScreen(event)}>
                  {event.name},{event.description},{event.photoURL}
                </ListItem>
              ))}
            </List>
          </ListWrapper>

          <ButtonWrapper>
            <Button onClick={() => setOffset(offset - 1)}>{prevPage ? <AddIcon /> : null}</Button>
            {start} - {end}/{totalCount}
            <Button onClick={() => setOffset(offset + 1)}>{nextPage ? <AddIcon /> : null}</Button>
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default EventV2ListScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  margin: ${theme.mixins.spacing * 4}px 0px 0px ${theme.mixins.spacing * 3}px;
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 56px;
  margin: 0px 0px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
`;

const List = styled(MuiList)`
  background: ${theme.mixins.background.white};
  border-radius: ${theme.mixins.spacing / 2}px;
  padding: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;
