import {
  Screen,
  SingleLineText,
  Skeleton,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Base, BaseDto, BASE_CODE, FetchBaseRequest } from '@atomica.co/irori';
import { builder, Index, Label } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useTheme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CreateIcon from '@material-ui/icons/Create';
import DeckIcon from '@material-ui/icons/Deck';
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/Event';
import FaceIcon from '@material-ui/icons/Face';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import MenuIcon from '@material-ui/icons/Menu';
import TimelineIcon from '@material-ui/icons/Timeline';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { BaseInfo } from '../../constants/base-config';
import usePath from '../../redux/hooks/usePath';
import BaseRequest from '../../requests/base-request';
import { Path } from '../../router/Routes';
import logo from './../../assets/logo_atomica.png';
import ConsoleSwitcher from './ConsoleSwitcher';

export const DRAWER_WIDTH = 280;

interface ConsoleMenuItem {
  showInMenu: boolean;
  path: Path;
  icon: React.ReactNode;
  label: Label;
}

const CONSOLE_MENU_ITEMS: ConsoleMenuItem[] = [
  {
    showInMenu: true,
    path: Path.TIMELINE,
    icon: <TimelineIcon />,
    label: 'タイムライン'
  },
  {
    showInMenu: true,
    path: Path.VISITORS,
    icon: <DeckIcon />,
    label: '本日の利用者'
  },
  {
    showInMenu: true,
    path: Path.USER_SEARCH,
    icon: <FaceIcon />,
    label: 'ユーザー検索'
  },
  {
    showInMenu: false,
    path: Path.USER_DETAILS,
    icon: <FaceIcon />,
    label: 'ユーザー詳細'
  },
  {
    showInMenu: true,
    path: Path.CONTRACT_LIST,
    icon: <DescriptionIcon />,
    label: '契約一覧'
  },
  {
    showInMenu: false,
    path: Path.CONTRACT_DETAILS,
    icon: <DescriptionIcon />,
    label: '契約詳細'
  },
  {
    showInMenu: false,
    path: Path.REGISTER_CONTRACT,
    icon: <DescriptionIcon />,
    label: '契約登録'
  },
  {
    showInMenu: true,
    path: Path.EVENT_LIST,
    icon: <EventIcon />,
    label: 'イベント一覧'
  },
  {
    showInMenu: true,
    path: Path.EVENT_V2_LIST,
    icon: <EventIcon />,
    label: '新イベント一覧'
  },
  {
    showInMenu: false,
    path: Path.REGISTER_EVENT_V2,
    icon: <EventIcon />,
    label: '新イベント登録'
  },
  {
    showInMenu: false,
    path: Path.EVENT_V2_DETAILS,
    icon: <EventIcon />,
    label: '新イベント詳細'
  },
  {
    showInMenu: true,
    path: Path.DIARY,
    icon: <CreateIcon />,
    label: '日報'
  },
  {
    showInMenu: true,
    path: Path.EXPORT,
    icon: <ImportExportIcon />,
    label: 'エクスポート'
  }
];

interface P extends RouteComponentProps {}

const ConsoleScreen: React.FC<P> = React.memo(() => {
  const theme = useTheme();
  const unmountRef = useUnmountRef();
  const { path, params, openBasePath } = usePath();
  const [isDrawerOpen, setIsDrawerOpen] = useSafeState<boolean>(unmountRef, true);
  const [base, setBase] = useSafeState<BaseDto>(unmountRef);

  const baseCode = useMemo<Base>(() => {
    return params[BASE_CODE];
  }, [params]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchBaseRequest>().baseCode(baseCode).build();
    const response = await BaseRequest.fetchBase(request);
    setBase(response.base);
  }, [baseCode, setBase]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleMenuClicked = useSafeCallback(
    (path: Path): void => {
      switch (path) {
        case Path.TIMELINE:
        case Path.VISITORS:
        case Path.USER_SEARCH:
        case Path.CONTRACT_LIST:
        case Path.EVENT_LIST:
        case Path.EVENT_V2_LIST:
        case Path.REGISTER_EVENT_V2:
        case Path.DIARY:
        case Path.EXPORT:
          openBasePath(path);
          break;

        default:
          throw new Error(`${path} is out of target.`);
      }
    },
    [openBasePath]
  );

  return (
    <Screen className='console-screen'>
      <Container>
        <CssBaseline />

        <StyledDrawer
          anchor='left'
          variant='persistent'
          open={isDrawerOpen}
          PaperProps={{ style: { width: DRAWER_WIDTH } }}
        >
          <DrawerHeader>
            <IconButton onClick={() => setIsDrawerOpen(false)}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>

          <StyledBaseInfo>
            <ThumbnailWrapper>
              <Skeleton style={styleForThumbnail} src={BaseInfo[baseCode].photoURL} />
            </ThumbnailWrapper>

            <NameWrapper>
              <SingleLineText style={styleForName} text={BaseInfo[baseCode].name} />
            </NameWrapper>
          </StyledBaseInfo>

          <Divider />

          <List>
            {CONSOLE_MENU_ITEMS.filter((item: ConsoleMenuItem) => item.showInMenu).map(
              (item: ConsoleMenuItem, index: Index) => (
                <ListItem button key={index} onClick={() => handleMenuClicked(item.path)}>
                  <StyledListItemIcon>{item.icon}</StyledListItemIcon>

                  <ListItemText>{item.label}</ListItemText>
                </ListItem>
              )
            )}
          </List>
        </StyledDrawer>

        <Header position='fixed' open={isDrawerOpen}>
          <Toolbar>
            <StyledIconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              open={isDrawerOpen}
              onClick={() => setIsDrawerOpen(true)}
            >
              <MenuIcon />
            </StyledIconButton>

            <LogoWrapper>
              <Logo src={logo} />
            </LogoWrapper>
          </Toolbar>
        </Header>

        {!!base && <ConsoleSwitcher isDrawerOpen={isDrawerOpen} base={base} path={path} onChange={openBasePath} />}
      </Container>
    </Screen>
  );
});

export default ConsoleScreen;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

const StyledDrawer = styled(Drawer)`
  width: ${DRAWER_WIDTH}px;
  flex-shrink: 0;
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(0, 1)};
  ${theme.mixins.toolbar};
  justify-content: flex-end;
`;

const StyledBaseInfo = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const ThumbnailWrapper = styled.div`
  width: 120px;
  height: 120px;
`;

const styleForThumbnail: CSSProperties = {
  width: 120,
  height: 120,
  minHeight: 120,
  borderRadius: '50%'
};

const NameWrapper = styled.div`
  width: 204px;
  height: 48px;
  margin: 8px 0px 24px;
`;

const styleForName: CSSProperties = {
  color: theme.mixins.typography.fontColor.gray,
  fontSize: theme.mixins.typography.fontSize.twenty,
  fontWeight: theme.mixins.typography.fontWeight.sevenHundreds as number,
  fontFamily: theme.mixins.typography.fontFamily,
  textAlign: 'center',
  whiteSpace: 'pre-wrap'
};

const StyledListItemIcon = styled(ListItemIcon)`
  padding-left: ${theme.mixins.spacing * 2}px;
`;

const ListItemText = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily} !important;
  padding-right: ${theme.mixins.spacing * 3.75}px;
`;

const Header = styled(AppBar)<{ open: boolean }>`
  background: ${theme.mixins.background.white};
  box-shadow: none;

  ${props =>
    props.open
      ? `
    width: calc(100% - ${DRAWER_WIDTH}px);
    margin-left: ${DRAWER_WIDTH}px;
    transition: ${theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })};
  `
      : `
    transition: ${theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })};
  `}
`;

const StyledIconButton = styled(IconButton)<{ open: boolean }>`
  margin-right: ${theme.spacing(2)};
  ${props => props.open && 'display: none;'}
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  margin-left: ${theme.mixins.spacing * 2}px;
`;

const Logo = styled.img`
  width: 180px;
  height: auto;
`;
