import { storage } from '../firebase';
import { STORAGE_FACE_RECOGNITION_PHOTOS_PATH } from '../constants/url-const';
import crypto from 'crypto';

export class FaceRecognitionService {
  public static async putImageFileToStorage(file: File): Promise<string> {
    const today = new Date();
    const folder = `${STORAGE_FACE_RECOGNITION_PHOTOS_PATH}/${today.getFullYear()}_${
      today.getMonth() + 1
    }_${today.getDate()}`;
    const fileName =
      crypto.createHash('md5').update(`${file.name}_${today.getTime()}`).digest('hex') +
      '.' +
      file.name.split('.').pop();
    const storageRef = storage.ref();
    const faceRecognitionPhotosRef = storageRef.child(`${folder}/${fileName}`);
    await faceRecognitionPhotosRef.put(file);
    return `${folder}/${fileName}`;
  }
}
