import {
  DeleteSpaceReservationRequest,
  DeleteSpaceReservationResponse,
  DELETE_SPACE_RESERVAITION,
  FetchSpaceReservationsOfDayRequest,
  FetchSpaceReservationsOfDayResponse,
  FetchSpaceReservationsByUserRequest,
  FetchSpaceReservationsByUserResponse,
  FETCH_SPACE_RESERVATIONS_OF_DAY,
  FETCH_SPACE_RESERVATIONS_BY_USER,
  SaveSpaceReservationRequest,
  SaveSpaceReservationResponse,
  SAVE_SPACE_RESERVATION,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class SpaceReservationRequest {
  public static saveSpaceReservation = async (
    request: SaveSpaceReservationRequest
  ): Promise<SaveSpaceReservationResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_SPACE_RESERVATION));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static deleteSpaceReservation = async (
    request: DeleteSpaceReservationRequest
  ): Promise<DeleteSpaceReservationResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(DELETE_SPACE_RESERVAITION));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchSpaceReservationsOfDay = async (
    request: FetchSpaceReservationsOfDayRequest
  ): Promise<FetchSpaceReservationsOfDayResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_SPACE_RESERVATIONS_OF_DAY));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchSpaceReservationsByUser = async (
    request: FetchSpaceReservationsByUserRequest
  ): Promise<FetchSpaceReservationsByUserResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_SPACE_RESERVATIONS_BY_USER));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
