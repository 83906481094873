import { BASE_CODE, CONTRACT_ID, EVENT_ID, USER_ID } from '@atomica.co/irori';
import { ID } from '@atomica.co/utils';

export const PATH_IDS: ID[] = [BASE_CODE, CONTRACT_ID, EVENT_ID, USER_ID];

export enum Path {
  /** sign-up */
  SIGN_UP = '/base/:baseCode/signup',

  /** sign-in */
  SIGN_IN = '/base/:baseCode/signin',

  /** sign-out */
  SIGN_OUT = '/base/:baseCode/signout',

  /** redirect */
  REDIRECT = '/redirect',

  /** liff */
  LIFF = '/liff',

  /** account */
  REGISTER_ACCOUNT = '/base/:baseCode/account/new',
  // ACCOUNT = '/account',

  /** my-account */
  MY_ACCOUNT = '/base/:baseCode/myaccount',

  /** contract */
  REGISTER_CONTRACT_MEMBER = '/base/:baseCode/contract/:contractId/new',

  /** shot */
  // SELECT_BASE = '/base',
  REGISTER_SHOT = '/base/:baseCode/shot/new',
  SHOT_RECEPTION = '/base/:baseCode/shot/reception',

  /** access */
  RECORD_ACCESS = '/base/:baseCode/access/new',

  /** event */
  JOIN_EVENT = '/base/:baseCode/event/:eventId/new',

  /** space */
  RESERVE_SPACE = '/base/:baseCode/space/reserve',

  /** console */
  TIMELINE = '/base/:baseCode',
  VISITORS = '/base/:baseCode/current',
  USER_SEARCH = '/base/:baseCode/users/search',
  USER_DETAILS = '/base/:baseCode/user/:userId',
  REGISTER_CONTRACT = '/base/:baseCode/contract/new',
  CONTRACT_LIST = '/base/:baseCode/contracts',
  CONTRACT_DETAILS = '/base/:baseCode/contract/:contractId',
  EVENT_LIST = '/base/:baseCode/events',
  EVENT_V2_LIST = '/base/:baseCode/events-v2',
  REGISTER_EVENT_V2 = '/base/:baseCode/events-v2/new',
  EVENT_V2_DETAILS = '/base/:baseCode/events-v2/:eventId',
  DIARY = '/base/:baseCode/diary',
  EXPORT = '/base/:baseCode/export',

  /** registered */
  REGISTERED = '/base/:baseCode/registered'

  /** @deprecated */
  // SETTING = '/setting'
}
