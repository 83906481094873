import { Component, Scrollable, theme } from '@atomica.co/components';
import { BaseDto, User } from '@atomica.co/irori';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Path } from '../../router/Routes';
import MyAccountScreen from '../my-account/MyAccountScreen';
import SpaceReservationScreen from '../space/SpaceReservationScreen';

export const HEADER_HEIGHT = 48;
export const MOBILE_HEADER_HEIGHT = 48;

interface P extends RouteComponentProps {
  base: BaseDto;
  path: Path;
  user: User;
  setUser: (user: User) => void;
  onChange(path: Path): void;
}

const ConsumerSwitcher: React.FC<P> = React.memo(props => {
  const { base, path, user, setUser } = props;

  return (
    <Component style={{ width: '100%', height: '100%' }} className='consumer-switcher'>
      <Container>
        <DrawerHeader />

        <Screens>
          <Scrollable>
            {path === Path.MY_ACCOUNT && <MyAccountScreen base={base} user={user} setUser={setUser} />}
            {path === Path.RESERVE_SPACE && <SpaceReservationScreen base={base} user={user} />}
          </Scrollable>
        </Screens>
      </Container>
    </Component>
  );
});

export default ConsumerSwitcher;

const Container = styled.main<{ open: boolean }>`
  width: 100%;
  min-height: 100vh;
  transition: ${theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen
  })};
  margin-left: 0px;
`;

const DrawerHeader = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  min-height: ${HEADER_HEIGHT}px !important;
  display: flex;
  align-items: center;
  padding: ${theme.spacing(0, 1)};
  ${theme.mixins.toolbar};
  justify-content: flex-end;

  ${media.lessThan('small')`
    height: ${MOBILE_HEADER_HEIGHT}px;
  `}
`;

const Screens = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);

  ${media.lessThan('small')`
    height: calc(100vh - ${MOBILE_HEADER_HEIGHT}px);
  `}
`;
