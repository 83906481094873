import { BaseAuthority, BaseDto, Category, PersonInCharge, Prefecture, User, UserId } from '@atomica.co/irori';
import {
  Address,
  builder,
  Code,
  Email,
  EMPTY,
  InflowSource,
  Name,
  Phone,
  toBeginningOfDay,
  toEndOfDay,
  toJST
} from '@atomica.co/utils';
import { getBasePIC } from '../utils/user-util';

export const toUserToSave = (
  prevUser: User | undefined,
  startDate: Date | undefined,
  endDate: Date | undefined,
  familyName: Name,
  firstName: Name,
  companyName: Name,
  dateOfBirth: Date,
  phone: Phone,
  email: Email | undefined,
  postalCode: Code,
  prefecture: Prefecture,
  address: Address,
  category: Category,
  inflowSource: InflowSource,
  facePhotoURL: string,
  faceFeatureStr: string
): User => {
  const building = builder<User>()
    .startDate(toJST(toBeginningOfDay(startDate))!)
    .endDate(toJST(toEndOfDay(endDate))!)
    .familyName(familyName)
    .firstName(firstName)
    .companyName(companyName)
    .dateOfBirth(toJST(toBeginningOfDay(dateOfBirth)!)!)
    .phone(phone)
    .email(email || (!!prevUser ? prevUser.email : EMPTY))
    .photoURL((!!prevUser && prevUser.photoURL) || EMPTY)
    .faceRecognitionPhotoURL(facePhotoURL)
    .faceFeatureStr(faceFeatureStr)
    .postalCode(postalCode)
    .prefecture(prefecture)
    .address(address)
    .category(category)
    .inflowSource(inflowSource)
    .dateOfAgreement(toJST(new Date())!);

  if (!!prevUser) {
    building.userId(prevUser.userId);
  }

  return building.build();
};

export const toPersonInChargeToSave = (base: BaseDto, users: User[], authority: BaseAuthority): PersonInCharge[] => {
  return users.reduce((prev: PersonInCharge[], current: User): PersonInCharge[] => {
    const personInCharge = getBasePIC(current, base);
    if (!!personInCharge) {
      prev.push(
        builder<PersonInCharge>()
          .personInChargeId(personInCharge.personInChargeId)
          .base(personInCharge.base)
          .baseDto(personInCharge.baseDto)
          .user(current)
          .authority(authority)
          .authorityDef(authority.authorityDef)
          .build()
      );
    }
    return prev;
  }, []);
};

export const setUserId = (user: User, userId: UserId): User => {
  user.userId = userId;
  return user;
};
