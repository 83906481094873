import {
  EventPotentialParticipantId,
  ExistPotentialParticipantRequest,
  ExistPotentialParticipantResponse,
  EXIST_EVENT_POTENTIAL_PARTICIPANT,
  SaveEventPotentialParticipantRequest,
  SaveEventPotentialParticipantResponse,
  SAVE_EVENT_POTENTIAL_PARTICIPANT,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType, uuid } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class EventPotentialParticipantRequest {
  public static getEventPotentialParticipantId = (): EventPotentialParticipantId => uuid();

  public static saveEventPotentialParticipant = async (
    request: SaveEventPotentialParticipantRequest
  ): Promise<SaveEventPotentialParticipantResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_EVENT_POTENTIAL_PARTICIPANT));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static existEventPotentialParticipant = async (
    request: ExistPotentialParticipantRequest
  ): Promise<ExistPotentialParticipantResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(EXIST_EVENT_POTENTIAL_PARTICIPANT));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
