import { Base, Shot, USER_ID } from '@atomica.co/irori';
import { embedIdInPath, EMPTY, URL } from '@atomica.co/utils';
import querystring from 'querystring';
import { Path, PATH_IDS } from '../router/Routes';

export const getReceptionURL = (shotUsage: Shot | undefined, base: Base): URL => {
  const userId = !!shotUsage && !!shotUsage.user ? shotUsage.user.userId : EMPTY;
  const queryParams = querystring.stringify(shotUsage as any) + `&${USER_ID}=${userId}`;

  return (
    window.location.origin +
    embedIdInPath(Path.SHOT_RECEPTION, PATH_IDS, [base]) +
    `?${encodeURIComponent(queryParams)}`
  );
};
