import {
  ButtonOption,
  CommentBox,
  MultiButtons,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  Base,
  Event,
  EventPotentialParticipant,
  ExistPotentialParticipantRequest,
  SaveEventPotentialParticipantRequest,
  User
} from '@atomica.co/irori';
import { builder, EMPTY, noop } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Screen from '../../../components/screen/Screen';
import { REGISTERED_AS_EVENT_WAITING, SUCCESS } from '../../../constants/snackbar-const';
import EventPotentialParticipantRequest from '../../../requests/event-potential-participant-request';
import mojaco_bow from './../../../assets/mojaco/mojaco_bow.png';
import mojaco_greeting from './../../../assets/mojaco/mojaco_greeting.png';

enum ButtonType {
  TURN_NOTIFICATION_ON = 'turn_notification_on',
  IS_NOTIFICATION_ON = 'is_notification_on'
}

const NOTIFICATION_OFF_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.TURN_NOTIFICATION_ON,
    isPrimary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '開催通知を受け取る'
  }
];

const NOTIFICATION_ON_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.IS_NOTIFICATION_ON,
    isPrimary: false,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.gray,
    label: '開催通知を受け取る'
  }
];

interface P {
  base: Base;
  user: User;
  event: Event;
}

const RegisterAsEventPotentialParticipant: React.FC<P> = React.memo(props => {
  const { base, user, event } = props;
  const unmountRef = useUnmountRef();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [isNotificationOn, setIsNotificationOn] = useSafeState<boolean>(unmountRef, false);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<ExistPotentialParticipantRequest>().base(base).userId(user.userId).build();

    const response = await EventPotentialParticipantRequest.existEventPotentialParticipant(request);
    const isNotificationOn = response.isExisted;

    setIsNotificationOn(isNotificationOn);
    setLoaded(true);
  }, [base, user, setIsNotificationOn, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveAsEventPotentialParticipant = useSafeCallback(async (): Promise<void> => {
    setIsNotificationOn(true);

    const eventPotentialParticipantToSave = builder<EventPotentialParticipant>()
      .eventPotentialParticipantId(EventPotentialParticipantRequest.getEventPotentialParticipantId())
      .base(base)
      .user(user)
      .build();

    const request = builder<SaveEventPotentialParticipantRequest>()
      .participant(eventPotentialParticipantToSave)
      .build();

    await EventPotentialParticipantRequest.saveEventPotentialParticipant(request);
    enqueueSnackbar(REGISTERED_AS_EVENT_WAITING, { variant: SUCCESS });
  }, [setIsNotificationOn, base, user, enqueueSnackbar]);

  const handleNotifyButtonClicked = useSafeCallback(
    (option: ButtonOption): void => {
      switch (option.id) {
        case ButtonType.TURN_NOTIFICATION_ON:
          saveAsEventPotentialParticipant();
          break;

        default:
          throw new Error(`${option.id} is out of target.`);
      }
    },
    [saveAsEventPotentialParticipant]
  );

  return (
    <Screen loading={!loaded} className='register-as-event-waiting'>
      <MojacoWrapper>
        <CommentBox animation photoURL={mojaco_greeting}>
          <Greeting>
            {!!event
              ? `こんにちは！\n次回の${event.name}の開催日が決まったら連絡するね！\nよければ、「開催通知を受け取る」をクリックして待っててね✨`
              : EMPTY}
          </Greeting>
        </CommentBox>
      </MojacoWrapper>

      <MojacoWrapper>
        <Mojaco src={mojaco_bow} />
      </MojacoWrapper>

      <FooterArea>
        {isNotificationOn && (
          <ButtonWrapper>
            <MultiButtons disabled options={NOTIFICATION_ON_BUTTONS} onClick={noop} />
          </ButtonWrapper>
        )}

        {!isNotificationOn && (
          <ButtonWrapper>
            <MultiButtons options={NOTIFICATION_OFF_BUTTONS} onClick={handleNotifyButtonClicked} />
          </ButtonWrapper>
        )}
      </FooterArea>
    </Screen>
  );
});

export default RegisterAsEventPotentialParticipant;

const MojacoWrapper = styled.div`
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const Greeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  margin-top: ${theme.mixins.spacing * 2}px;
`;

const FooterArea = styled.div`
  width: 100%;
  height: 80px;
  position: fixed;
  left: 0px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ButtonWrapper = styled.div`
  width: 280px;
  height: 48px;
`;
