import { Component, Scrollable, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Base, BaseDto, User } from '@atomica.co/irori';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import NoPrivilege from '../../components/error/NoPrivilege';
import useUser from '../../redux/hooks/useUser';
import { Path } from '../../router/Routes';
import { isPIC } from '../../utils/user-util';
import ContractDetailsScreen from '../contract/ContractDetailsScreen';
import ContractListScreen from '../contract/ContractListScreen';
import RegisterContractScreen from '../contract/RegisterContractScreen';
import DiaryScreen from '../diary/DiaryScreen';
import EventListScreen from '../event/EventListScreen';
import EventV2ListScreen from '../event-v2/EventV2ListScreen';
import RegisterEventV2Screen from '../event-v2/RegisterEventV2Screen';
import EventV2DetailsScreen from '../event-v2/EventV2DetailsScreen';
import ExportScreen from '../export/ExportScreen';
import TimelineScreen from '../timeline/TimelineScreen';
import UserDetailsScreen from '../users/UserDetailsScreen';
import UserSearchScreen from '../users/UserSearchScreen';
import VisitorsScreen from '../users/VisitorsScreen';
import { DRAWER_WIDTH } from './ConsoleScreen';

export const HEADER_HEIGHT = 64;

interface P extends RouteComponentProps {
  isDrawerOpen: boolean;
  base: BaseDto;
  path: Path;
  onChange(path: Path): void;
}

const ConsoleSwitcher: React.FC<P> = React.memo(props => {
  const { isDrawerOpen, base, path, onChange } = props;
  const { getUser } = useUser();
  const unmountRef = useUnmountRef();
  const [isPicUser, setIsPicUser] = useSafeState<boolean | undefined>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = (await getUser()) as User;
    // toFix
    setIsPicUser(!!user && isPIC(user, base.baseCode as Base));
  }, [base, getUser, setIsPicUser]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component style={{ width: '100%', height: '100%' }} className='console-switcher'>
      <Container open={isDrawerOpen}>
        <DrawerHeader />

        <Screens>
          <Scrollable>
            {isPicUser === false && <NoPrivilege />}

            {isPicUser === true && path === Path.TIMELINE && <TimelineScreen />}

            {isPicUser === true && path === Path.VISITORS && <VisitorsScreen onChange={onChange} />}

            {isPicUser === true && path === Path.USER_SEARCH && <UserSearchScreen base={base} />}

            {isPicUser === true && path === Path.USER_DETAILS && <UserDetailsScreen />}

            {isPicUser === true && path === Path.CONTRACT_LIST && (
              // toFix
              <ContractListScreen base={base.baseCode as Base} onChange={onChange} />
            )}

            {isPicUser === true && path === Path.CONTRACT_DETAILS && (
              <ContractDetailsScreen base={base} onChange={onChange} />
            )}

            {isPicUser === true && path === Path.REGISTER_CONTRACT && (
              <RegisterContractScreen base={base} onChange={onChange} />
            )}

            {isPicUser === true && path === Path.EVENT_LIST && <EventListScreen />}

            {isPicUser === true && path === Path.EVENT_V2_LIST && (
              <EventV2ListScreen base={base.baseCode as Base} onChange={onChange} />
            )}

            {isPicUser === true && path === Path.REGISTER_EVENT_V2 && (
              <RegisterEventV2Screen base={base} onChange={onChange} />
            )}

            {isPicUser === true && path === Path.EVENT_V2_DETAILS && (
              <EventV2DetailsScreen base={base.baseCode as Base} onChange={onChange} />
            )}

            {isPicUser === true && path === Path.DIARY && <DiaryScreen />}

            {isPicUser === true && path === Path.EXPORT && <ExportScreen />}
          </Scrollable>
        </Screens>
      </Container>
    </Component>
  );
});

export default ConsoleSwitcher;

const Container = styled.main<{ open: boolean }>`
  ${props =>
    props.open
      ? `
    width: 100%;
    transition: ${theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })};
    margin-left: 0px;
  `
      : `
    flex-grow: 1;
    padding: ${theme.spacing(3)}px;
    transition: ${theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })};
    margin-left: -${DRAWER_WIDTH}px;
  `}
`;

const DrawerHeader = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  padding: ${theme.spacing(0, 1)};
  ${theme.mixins.toolbar};
  justify-content: flex-end;
`;

const Screens = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;
