import { Button, Component, Modal, theme } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface P {
  isOpen: boolean;
  onClick(): void;
  onClose(): void;
}

const ConfirmationModal: React.FC<P> = React.memo(props => {
  const { isOpen, onClick, onClose } = props;

  return (
    <Component className='confirmation-modal'>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Container onClick={onClose}>
          <Frame onClick={e => e.stopPropagation()}>
            <Content>
              <TextWrapper>
                <Message>予約を取り消してもよろしいですか？</Message>
              </TextWrapper>
              <ButtonWrapper>
                <LeftButtonWrapper>
                  <Button type='primary' shape='rect' onClick={onClick}>
                    <Label>取り消す</Label>
                  </Button>
                </LeftButtonWrapper>
                <Button type='secondary' shape='rect' onClick={onClose}>
                  <Label>キャンセル</Label>
                </Button>
              </ButtonWrapper>
            </Content>
          </Frame>
        </Container>
      </Modal>
    </Component>
  );
});

export default ConfirmationModal;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Frame = styled.div`
  width: 480px;
  height: auto;
  background: ${theme.mixins.background.lightGray};
  border-radius: 12px;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing * 4}px;
  display: flex;
  flex-direction: column;

  ${media.lessThan('small')`
    width: 95vw;
    max-height: 80vh;
    overflow-y: auto;
  `}
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing * 2}px;
`;
const Message = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  text-align: center;
`;

const TextWrapper = styled.div``;
const Label = styled(Typography)`
  width: 120px;
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LeftButtonWrapper = styled.div`
  margin-right: ${theme.mixins.spacing}px;
`;
