import {
  Button,
  Component,
  InputBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  Base,
  EventScheduleV2,
  EventV2,
  EVENT_ID_V2,
  FetchEventV2Request,
  SearchEventSchedulesV2Request
} from '@atomica.co/irori';
import { builder, EMPTY, Offset, Word } from '@atomica.co/utils';
import { List as MuiList, ListItem, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SearchOption } from '../../enums/event-v2-enum';
import usePath from '../../redux/hooks/usePath';
import EventScheduleV2Request from '../../requests/event-schedule-v2-request';
import EventV2Request from '../../requests/event-v2-request';
import { Path } from '../../router/Routes';

interface P {
  base: Base;
  onChange(path: Path): void;
}

const EventV2DetailsScreen: React.FC<P> = React.memo(props => {
  const { params } = usePath();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [searchingWord, setSearchingWord] = useSafeState<Word>(unmountRef, EMPTY);
  const [initialEvent, setInitialEvent] = useSafeState<EventV2>(unmountRef);
  const [eventSchedules, setEventSchedules] = useSafeState<EventScheduleV2[]>(unmountRef, []);
  const [offset, setOffset] = useSafeState<Offset>(unmountRef, SearchOption.OFFSET);

  const [totalCount, setTotalCount] = useSafeState<number>(unmountRef, 0);
  const [start, setStart] = useSafeState<number>(unmountRef, 0);
  const [end, setEnd] = useSafeState<number>(unmountRef, 0);
  const [prevPage, setPrevPage] = useSafeState<boolean>(unmountRef, false);
  const [nextPage, setNextPage] = useSafeState<boolean>(unmountRef, false);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const eventId = params[EVENT_ID_V2];

    const request = builder<FetchEventV2Request>().eventIdV2(eventId).build();
    const response = await EventV2Request.fetchEvent(request);
    const eventV2 = response.eventV2;
    if (!eventV2) {
      setLoaded(true);
      return;
    }

    setInitialEvent(eventV2);
    setLoaded(true);
  }, [setInitialEvent, params, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const searchEventSchedules = useSafeCallback(async (): Promise<void> => {
    const eventId = params[EVENT_ID_V2];

    const request = builder<SearchEventSchedulesV2Request>()
      .eventIdV2(eventId)
      .limit(SearchOption.LIMIT)
      .offset(offset)
      .word(searchingWord)
      .build();
    const response = await EventScheduleV2Request.searchEventSchedules(request);

    setEventSchedules(response.eventScheduleV2);
    setTotalCount(response.totalCount);
    setStart(response.start);
    setEnd(response.end);
    setPrevPage(response.prevPage);
    setNextPage(response.nextPage);
  }, [params, searchingWord, offset, setEventSchedules, setEnd, setNextPage, setPrevPage, setStart, setTotalCount]);

  useEffect(() => {
    searchEventSchedules();
  }, [searchEventSchedules, searchingWord]);

  const handleSearchingWordChanged = useSafeCallback(
    (word: Word): void => {
      setSearchingWord(word);
      setOffset(0);
    },
    [setSearchingWord, setOffset]
  );

  return (
    <Component
      loading={!loaded}
      style={{ width: '100%', height: `calc(100vh - 64px)` }}
      className='contract-details-screen'
    >
      <Container>
        <Content>
          <SubTitle>イベント詳細</SubTitle>
          <Title>Contract Details</Title>

          <Table>
            {initialEvent && (
              <Tbody>
                <Tr>
                  <Td>{initialEvent.name}</Td>
                  <Td>{initialEvent.description}</Td>
                  <Td>{initialEvent.photoURL}</Td>
                </Tr>
              </Tbody>
            )}
          </Table>

          <InputWrapper>
            <InputBox type='text' label='検索' text={searchingWord} onChange={handleSearchingWordChanged} />
          </InputWrapper>

          <ListWrapper>
            <List>
              {eventSchedules.map((eventSchedule, index) => (
                <ListItem key={index}>{eventSchedule.name}</ListItem>
              ))}
            </List>
          </ListWrapper>

          <ButtonWrapper>
            <Button onClick={() => setOffset(offset - 1)}>{prevPage ? <AddIcon /> : null}</Button>
            {start} - {end}/{totalCount}
            <Button onClick={() => setOffset(offset + 1)}>{nextPage ? <AddIcon /> : null}</Button>
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default EventV2DetailsScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  margin: ${theme.mixins.spacing * 4}px 0px 0px ${theme.mixins.spacing * 3}px;
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 56px;
  margin: 0px 0px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
`;

const Table = styled.table`
  width: 100%;
  height: auto;
  margin-top: ${theme.mixins.spacing}px;
  border-spacing: 0 ${theme.mixins.spacing / 4}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.sixteen + 2}px;
`;
const Tr = styled.tr`
  background: ${theme.mixins.background.white};
`;
const Td = styled.td`
  height: 64px;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
`;
const Tbody = styled.tbody``;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
`;

const List = styled(MuiList)`
  background: ${theme.mixins.background.white};
  border-radius: ${theme.mixins.spacing / 2}px;
  padding: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;
