import { BoxFrame, Component, Scrollable, theme } from '@atomica.co/components';
import { Email } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import styled from 'styled-components';
import puzzles from './../../../../../assets/puzzles.png';

interface P {
  email: Email;
}

const EmailSent: React.FC<P> = React.memo(props => {
  const { email } = props;

  return (
    <Component className='email-sent'>
      <Scrollable>
        <Content>
          <LogoWrapper>
            <Logo src={puzzles} />
          </LogoWrapper>

          <BoxWrapper>
            <BoxFrame
              content={
                <BoxContent>
                  <Icon>
                    <CheckIcon />
                  </Icon>
                  <Message>メールを送信しました</Message>
                </BoxContent>
              }
            />
          </BoxWrapper>

          <Remarks>
            <Row>{email}</Row>
            <Row>宛にメールを送信しました。</Row>
          </Remarks>

          <Remarks>
            <Row>本文内のURLにアクセスし、</Row>
            <Row>パスワードを再設定してください。</Row>
          </Remarks>
        </Content>
      </Scrollable>
    </Component>
  );
});

export default EmailSent;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  min-height: 160px;
  object-fit: cover;
`;

const BoxWrapper = styled.div`
  margin: ${theme.mixins.spacing}px;
`;

const BoxContent = styled.div`
  width: 280px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(Typography)`
  width: 40px;
  height: 40px;
  color: ${theme.mixins.typography.fontColor.pink};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled(Typography)`
  width: calc(100% - 40px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Remarks = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px 0px;
`;

const Row = styled(Typography)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;
