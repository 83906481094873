import {
  Button,
  Component,
  InputBox,
  Scrollable,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { DOMAINS, ErrorCodeEnum, ERROR_MESSAGES } from '@atomica.co/irori';
import { BCC_EMAIL, Email, EMPTY, Message, MINUS_ONE } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import env from '../../../../../env/env';
import firebase, { auth } from '../../../../../firebase';
import atomica from './../../../../../assets/atomica.png';

interface P {
  onSendEmail(email: Email): void;
  goBack(): void;
}

const InputEmail: React.FC<P> = React.memo(props => {
  const { onSendEmail, goBack } = props;
  const unmountRef = useUnmountRef();
  const [disabledSendButton, setDisabledSendButton] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  useEffect(() => {
    setDisabledSendButton(!email);
  }, [setDisabledSendButton, email]);

  const constructErrorMessage = useSafeCallback(
    (code: ErrorCodeEnum): void => {
      if (!code) return;

      Object.entries(ERROR_MESSAGES)
        .filter(([key, value]) => code.toString().indexOf(key) !== MINUS_ONE)
        .forEach(([key, value]) => setErrorMessage(value));
    },
    [setErrorMessage]
  );

  const signInWithEmailAndPassword = useSafeCallback((): void => {
    if (!email) {
      constructErrorMessage(ErrorCodeEnum.INVALID_EMAIL);
      return;
    }

    setDisabledSendButton(true);
    const settings: firebase.auth.ActionCodeSettings = {
      handleCodeInApp: true,
      url: `https://${DOMAINS[env]}`
    };

    auth
      .sendPasswordResetEmail(email, settings)
      .then(() => {
        auth.sendPasswordResetEmail(BCC_EMAIL, settings);
        onSendEmail(email);
      })
      .catch(err => {
        setDisabledSendButton(false);
        constructErrorMessage(err.code);
      });
  }, [setDisabledSendButton, email, onSendEmail, constructErrorMessage]);

  return (
    <Component className='input-email'>
      <Scrollable>
        <Content>
          <LogoWrapper>
            <Logo src={atomica} />
          </LogoWrapper>

          <InputWrapper>
            <InputBox type='email' label='Email' text={email} onChange={setEmail} />

            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </InputWrapper>

          <BackButtonWrapper>
            <Button onClick={goBack}>
              <BackLabel>← 戻る</BackLabel>
            </Button>
          </BackButtonWrapper>

          <SendButtonWrapper>
            <Button type='primary' disabled={disabledSendButton} onClick={signInWithEmailAndPassword}>
              <MailIcon fontSize='small' />
              <SendLabel>再設定のURLを送る</SendLabel>
            </Button>
          </SendButtonWrapper>
        </Content>
      </Scrollable>
    </Component>
  );
});

export default InputEmail;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: ${theme.mixins.spacing * 6}px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const InputWrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px 0px;
`;

const ErrorMessage = styled(Typography)`
  width: 100%;
  height: auto;
  min-height: 18px;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding: ${theme.mixins.spacing / 2}px 0px 0px ${theme.mixins.spacing * 2}px;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${theme.mixins.spacing * 2}px;
`;

const BackLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const SendButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 4}px;
`;

const SendLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding: 0px ${theme.mixins.spacing}px;
`;
