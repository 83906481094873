import {
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, SHARED_SPACE_RESERVATION_ID, User } from '@atomica.co/irori';
import { Code } from '@atomica.co/utils';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import media from 'styled-media-query';
import Screen from '../../components/screen/Screen';
import { BOTTOM_NAGIGATOR_HEIGHT } from '../../constants/my-account-consts';
import { ScreenNameEnum } from '../../enums/my-account-enum';
import usePath from '../../redux/hooks/usePath';
import { getBasePIC } from '../../utils/user-util';
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../consumer/ConsumerSwitcher';
import MyAccountHome from './my-account-home/MyAccountHome';
import MyAccountProfile from './my-account-profile/MyAccountProfile';
import MyAccountReservations from './my-account-reservations/MyAccountReservations';
import MyAccountShare from './my-account-share/MyAccountShare';
import MyAccountShot from './my-account-shot/MyAccountShot';

interface P extends RouteComponentProps {
  base: BaseDto;
  user: User;
  setUser: (user: User) => void;
}

interface MyAccountScreenItem {
  name: ScreenNameEnum;
  menu: ReactNode;
  icon?: ReactNode;
  isOnMenu: boolean;
}

const MyAccountScreen: React.FC<P> = React.memo(props => {
  const { base, user, setUser } = props;
  const unmountRef = useUnmountRef();
  const { queryParams } = usePath();
  const [selectedScreenName, setSelectedScreenName] = useSafeState<ScreenNameEnum>(unmountRef, ScreenNameEnum.HOME);

  const authorityDefCode = useMemo<Code | null>(() => {
    const pic = getBasePIC(user, base);
    return !!pic ? pic.authority.authorityDef.authorityDefCode : null;
  }, [base, user]);

  const isShareMode = useMemo<boolean>(() => {
    const sharedSpaceReservationId = queryParams[SHARED_SPACE_RESERVATION_ID];
    return !!sharedSpaceReservationId;
  }, [queryParams]);

  const initialize = useSafeCallback((): void => {
    setSelectedScreenName(isShareMode ? ScreenNameEnum.SHARE : ScreenNameEnum.HOME);
  }, [isShareMode, setSelectedScreenName]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleNavigatorClicked = useSafeCallback(
    (event: any, newValue: ScreenNameEnum): void => {
      setSelectedScreenName(newValue);
    },
    [setSelectedScreenName]
  );

  const handleShareSaved = useSafeCallback((): void => {
    setSelectedScreenName(ScreenNameEnum.HOME);
  }, [setSelectedScreenName]);

  const accountScreenItems = useMemo<MyAccountScreenItem[]>(
    () => [
      {
        name: ScreenNameEnum.HOME,
        menu: <MyAccountHome key={ScreenNameEnum.HOME} authorityDefCode={authorityDefCode} base={base} user={user} />,
        icon: <HomeOutlinedIcon fontSize='large' />,
        isOnMenu: true
      },
      {
        name: ScreenNameEnum.SHOT,
        menu: <MyAccountShot />,
        icon: <QuestionAnswerOutlinedIcon fontSize='large' />,
        isOnMenu: true
      },
      {
        name: ScreenNameEnum.RESERVATION,
        menu: <MyAccountReservations key={ScreenNameEnum.RESERVATION} base={base} user={user} />,
        icon: <EventOutlinedIcon fontSize='large' />,
        isOnMenu: true
      },
      {
        name: ScreenNameEnum.PROFILE,
        menu: (
          <MyAccountProfile
            key={ScreenNameEnum.PROFILE}
            authorityDefCode={authorityDefCode}
            user={user}
            setUser={setUser}
          />
        ),
        icon: <PermIdentityOutlinedIcon fontSize='large' />,
        isOnMenu: true
      },
      {
        name: ScreenNameEnum.SHARE,
        menu: <MyAccountShare key={ScreenNameEnum.SHARE} base={base} user={user} onSave={handleShareSaved} />,
        isOnMenu: false
      }
    ],
    [authorityDefCode, base, user, setUser, handleShareSaved]
  );

  return (
    <CustomScreen style={{ height: 'auto' }} className='my-account-screen'>
      <Container headerHeight={HEADER_HEIGHT} mobileHeaderHeight={MOBILE_HEADER_HEIGHT}>
        <Content>
          <WrapperMenu>
            {accountScreenItems.map(
              accounScreenItem => accounScreenItem.name === selectedScreenName && accounScreenItem.menu
            )}
          </WrapperMenu>
          <WrapperBottomNavigation>
            <CustomBottomNavigation value={selectedScreenName} onChange={handleNavigatorClicked}>
              {accountScreenItems.map(
                (accounScreenItem, idx) =>
                  accounScreenItem.isOnMenu && (
                    <CustomBottomNavigationAction
                      key={idx}
                      value={accounScreenItem.name}
                      showLabel={false}
                      icon={accounScreenItem.icon}
                    />
                  )
              )}
            </CustomBottomNavigation>
          </WrapperBottomNavigation>
        </Content>
      </Container>
    </CustomScreen>
  );
});

export default MyAccountScreen;
const CustomScreen = styled(Screen)`
  min-height: calc(100vh - ${({ headerHeight }) => (headerHeight || 0) + BOTTOM_NAGIGATOR_HEIGHT}px);

  ${media.lessThan('small')`
    min-height: calc(100vh - ${({ mobileHeaderHeight }) => (mobileHeaderHeight || 0) + BOTTOM_NAGIGATOR_HEIGHT}px);
  `}
`;
const Container = styled.div`
  width: 100vw;
  height: auto;
  ${media.lessThan('small')``}
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  margin: 0 auto;
`;

const WrapperBottomNavigation = styled.div`
  width: 352px;
  border-radius: 8px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${theme.mixins.spacing * 3}px;
  margin: auto;
  z-index: 100;
`;

const CustomBottomNavigation = styled(BottomNavigation)`
  height: 48px;
  border-radius: 50vh;
  box-shadow: ${theme.mixins.shadow.dark};
`;
const CustomBottomNavigationAction = styled(BottomNavigationAction)`
  border-radius: 50vh;
  padding-top: 6px !important;
`;
const WrapperMenu = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100vh - 120px);
`;
