import { Component, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  DeleteSpaceReservationRequest,
  FetchSpaceReservationsOfDayRequest,
  SaveSpaceReservationRequest,
  Space,
  SpaceId,
  SpaceParticipant,
  SpaceParticipantDiv,
  SpaceReservation,
  SpaceReservationId,
  User
} from '@atomica.co/irori';
import { builder, EMPTY, hasLength, toJST, toUTC, uuid, ZERO } from '@atomica.co/utils';
import { DateClickArg } from '@fullcalendar/interaction';
import { DateSelectArg, EventClickArg } from '@fullcalendar/react';
import '@fullcalendar/react/dist/vdom';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import {
  ERROR,
  SPACE_RESERVE_DELETED,
  SPACE_RESERVE_ERROR,
  SPACE_RESERVE_SAVED,
  SPACE_RESERVE_UPDATED,
  SUCCESS
} from '../../constants/snackbar-const';
import { ReservationModalMode } from '../../enums/space-enum';
import useCashedSpaceReservation from '../../redux/hooks/useCashedSpaceReservation';
import useCashedURL from '../../redux/hooks/useCashedURL';
import usePath from '../../redux/hooks/usePath';
import SpaceReservationRequest from '../../requests/space-reservation-request';
import { Path } from '../../router/Routes';
import ReservationModal from './modal/ReservationModal';
import Notion from './notion/Notion';
import SelectDate from './select-date/SelectDate';
import SelectTime from './select-time/SelectTime';
import SpaceList from './space-list/SpaceList';

interface P {
  base: BaseDto;
  user: User;
}

const SpaceReservationScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const unmountRef = useUnmountRef();
  const { enqueueSnackbar } = useSnackbar();
  const { saveCurrentURL } = useCashedURL();
  const { openBasePath } = usePath();
  const { cashedSpaceReservation, saveCashedSpaceReservation, clearCashedSpaceReservation } =
    useCashedSpaceReservation();
  const reserveTimeRef = useRef<HTMLDivElement>(null);
  const [reservations, setReservations] = useSafeState<SpaceReservation[]>(unmountRef, []);
  const [reservationName, setReservationName] = useSafeState<string>(
    unmountRef,
    !!cashedSpaceReservation ? cashedSpaceReservation.reservationName : EMPTY
  );
  const [remarks, setRemarks] = useSafeState<string>(
    unmountRef,
    !!cashedSpaceReservation ? cashedSpaceReservation.remarks : EMPTY
  );
  const [spaceIdToReserve, setSpaceIdToReserve] = useSafeState<SpaceId | undefined>(
    unmountRef,
    !!cashedSpaceReservation ? cashedSpaceReservation.spaceIdToReserve : undefined
  );
  const [selectedSpaceIds, setSelectedSpaceIds] = useSafeState<SpaceId[]>(
    unmountRef,
    !!cashedSpaceReservation ? [cashedSpaceReservation.spaceIdToReserve!] : []
  );
  const [selectedDate, setSelectedDate] = useSafeState<Date>(
    unmountRef,
    !!cashedSpaceReservation.selectedDate ? new Date(cashedSpaceReservation.selectedDate) : new Date()
  );
  const [selectedStartAt, setSelectedStartAt] = useSafeState<Date | undefined>(
    unmountRef,
    !!cashedSpaceReservation.selectedStartAt ? new Date(cashedSpaceReservation.selectedStartAt) : undefined
  );
  const [selectedEndAt, setSelectedEndAt] = useSafeState<Date | undefined>(
    unmountRef,
    !!cashedSpaceReservation.selectedEndAt ? new Date(cashedSpaceReservation.selectedEndAt) : undefined
  );
  const [selectedReserve, setSelectedReserve] = useSafeState<SpaceReservation | undefined>(unmountRef);
  const [spaces, setSpaces] = useSafeState<Space[]>(unmountRef);
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(
    unmountRef,
    !!cashedSpaceReservation && !!cashedSpaceReservation.isModalOpen
  );
  const [modalMode, setModalMode] = useSafeState<ReservationModalMode>(
    unmountRef,
    !!cashedSpaceReservation && !!cashedSpaceReservation.isModalOpen
      ? ReservationModalMode.NEW
      : ReservationModalMode.READONLY
  );
  // const [isReadonlyEvent, setIsReadonlyEvent] = useSafeState<boolean>(unmountRef, false);
  // const [isEditableEvent, setIsEditableEvent] = useSafeState<boolean>(unmountRef, false);

  const onClickSpace = useSafeCallback(
    (spaceId: SpaceId): void => {
      setSelectedSpaceIds(selectedSpaceIds => {
        const newSelectedSpaceIds: SpaceId[] = selectedSpaceIds.concat();
        newSelectedSpaceIds.includes(spaceId)
          ? newSelectedSpaceIds.splice(newSelectedSpaceIds.indexOf(spaceId), 1)
          : newSelectedSpaceIds.push(spaceId);
        return newSelectedSpaceIds;
      });
    },
    [setSelectedSpaceIds]
  );

  const onClickDate = useSafeCallback(
    (arg: DateClickArg): void => {
      setSelectedDate(arg.date);
      reserveTimeRef.current && reserveTimeRef.current.scrollIntoView();
    },
    [reserveTimeRef, setSelectedDate]
  );

  const onSelectTime = useSafeCallback(
    (arg: DateSelectArg): void => {
      setSelectedStartAt(arg.start);
      setSelectedEndAt(arg.end);
      setSpaceIdToReserve(arg.resource?.id);
      setModalMode(ReservationModalMode.NEW);
      setIsModalOpen(true);
    },
    [setModalMode, setSelectedStartAt, setSelectedEndAt, setSpaceIdToReserve, setIsModalOpen]
  );

  const onClickEvent = useSafeCallback(
    (arg: EventClickArg): void => {
      const clickedReservationId = arg.event.id;
      const clickedReservation = reservations.find(
        reservation => reservation.spaceReservationId === clickedReservationId
      );
      if (!clickedReservation) return;

      const participant = clickedReservation.participants.find(participant => participant.user.userId === user.userId);
      if (!participant) return;

      const now = new Date();
      const editableDueTime = toUTC(new Date(clickedReservation.startAt));

      if (!!base.spaceDueDays) {
        editableDueTime!.setDate(editableDueTime!.getDate() - (base.spaceDueDays - 1));
        editableDueTime!.setHours(ZERO);
        editableDueTime!.setMinutes(ZERO);
        editableDueTime!.setSeconds(ZERO);
      }

      const isOwner = participant.participantDiv === SpaceParticipantDiv.OWNER;
      const isEditable = now < editableDueTime!;
      if (!isOwner) return;

      setRemarks(clickedReservation.remarks);
      setReservationName(clickedReservation.reservationName);
      setSelectedStartAt(toUTC(new Date(clickedReservation.startAt)));
      setSelectedEndAt(toUTC(new Date(clickedReservation.endAt)));
      setSelectedReserve(clickedReservation);
      setSpaceIdToReserve(clickedReservation.space.spaceId);
      setModalMode(isEditable ? ReservationModalMode.EDIT : ReservationModalMode.READONLY);
      setIsModalOpen(true);
    },
    [
      base,
      reservations,
      user,
      setIsModalOpen,
      setModalMode,
      setRemarks,
      setReservationName,
      setSelectedStartAt,
      setSelectedEndAt,
      setSelectedReserve,
      setSpaceIdToReserve
    ]
  );

  const fetchSpaceReservations = useSafeCallback(async (): Promise<void> => {
    const dateToDB = toJST(new Date(selectedDate));
    if (!selectedDate || !dateToDB || !hasLength(spaces)) return;
    const request = builder<FetchSpaceReservationsOfDayRequest>()
      .date(dateToDB)
      .spaceIds(spaces.map(space => space.spaceId))
      .build();
    const response = await SpaceReservationRequest.fetchSpaceReservationsOfDay(request);
    setReservations(response.spaceReservations);
  }, [selectedDate, spaces, setReservations]);

  const onCloseModal = useSafeCallback((): void => {
    clearCashedSpaceReservation();
    fetchSpaceReservations();
    setRemarks(EMPTY);
    setReservationName(EMPTY);
    setSelectedReserve(undefined);
    setSelectedStartAt(undefined);
    setSelectedEndAt(undefined);
    setModalMode(ReservationModalMode.READONLY);
    setIsModalOpen(false);
  }, [
    clearCashedSpaceReservation,
    fetchSpaceReservations,
    setIsModalOpen,
    setModalMode,
    setRemarks,
    setReservationName,
    setSelectedReserve,
    setSelectedStartAt,
    setSelectedEndAt
  ]);

  const saveSpaceReservation = useSafeCallback(async (): Promise<SpaceReservationId | undefined> => {
    const space = spaces.find(space => space.spaceId === spaceIdToReserve);
    const startAtToDB = selectedStartAt && toJST(new Date(selectedStartAt));
    const endAtToDB = selectedEndAt && toJST(new Date(selectedEndAt));
    if (!space || !startAtToDB || !endAtToDB) return;

    if (!user) {
      saveCashedSpaceReservation({
        remarks,
        reservationName,
        selectedDate,
        selectedStartAt,
        selectedEndAt,
        spaceIdToReserve,
        isModalOpen: true
      });
      saveCurrentURL();
      setIsModalOpen(false);
      openBasePath(Path.SIGN_IN);
      return;
    }

    const spaceParticipant = builder<SpaceParticipant>()
      .spaceParticipantId(uuid())
      .participantDiv(SpaceParticipantDiv.OWNER)
      .user(user)
      .build();
    const spaceReservation = builder<SpaceReservation>()
      .spaceReservationId(selectedReserve ? selectedReserve.spaceReservationId : uuid())
      .reservationName(reservationName)
      .remarks(remarks)
      .startAt(startAtToDB)
      .endAt(endAtToDB)
      .space(space)
      .build();

    const saveSpaceReservationRequest = builder<SaveSpaceReservationRequest>()
      .base(base)
      .user(user)
      .spaceReservation(spaceReservation)
      .spaceParticipants(selectedReserve ? [] : [spaceParticipant])
      .build();

    const saveSpaceReservationResponse = await SpaceReservationRequest.saveSpaceReservation(
      saveSpaceReservationRequest
    );

    const isSavedSuccessfully = !!saveSpaceReservationResponse.spaceReservationId;
    onCloseModal();
    enqueueSnackbar(
      isSavedSuccessfully ? (!!selectedReserve ? SPACE_RESERVE_UPDATED : SPACE_RESERVE_SAVED) : SPACE_RESERVE_ERROR,
      {
        variant: isSavedSuccessfully ? SUCCESS : ERROR
      }
    );
    return saveSpaceReservationResponse.spaceReservationId;
  }, [
    base,
    enqueueSnackbar,
    onCloseModal,
    openBasePath,
    remarks,
    reservationName,
    saveCashedSpaceReservation,
    saveCurrentURL,
    setIsModalOpen,
    spaces,
    selectedDate,
    selectedReserve,
    selectedStartAt,
    selectedEndAt,
    spaceIdToReserve,
    user
  ]);

  const deleteSpaceReservation = useSafeCallback(async (): Promise<SpaceReservationId | undefined> => {
    if (!selectedReserve) return;
    const deleteSpaceReservationRequest = builder<DeleteSpaceReservationRequest>()
      .base(base)
      .spaceReservationId(selectedReserve.spaceReservationId)
      .spacePaticipantIds(selectedReserve.participants.map(participant => participant.spaceParticipantId))
      .user(user)
      .build();

    const deleteSpaceReservationResponse = await SpaceReservationRequest.deleteSpaceReservation(
      deleteSpaceReservationRequest
    );
    const isDeletedSuccessfully = !!deleteSpaceReservationResponse.spaceReservationId;
    onCloseModal();
    enqueueSnackbar(isDeletedSuccessfully ? SPACE_RESERVE_DELETED : SPACE_RESERVE_ERROR, {
      variant: isDeletedSuccessfully ? SUCCESS : ERROR
    });
  }, [base, enqueueSnackbar, onCloseModal, selectedReserve, user]);

  const initialize = useSafeCallback((): void => {
    if (!!user && !user.isActivated) {
      saveCurrentURL();
      openBasePath(Path.REGISTER_ACCOUNT);
      return;
    }
    clearCashedSpaceReservation();
    fetchSpaceReservations();
  }, [clearCashedSpaceReservation, fetchSpaceReservations, openBasePath, saveCurrentURL, user]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component className='space-reservation-screen'>
      <Container>
        <Content>
          <TitleWrapper>
            <SubTitle>{`${!!base ? `${base.baseName} ` : EMPTY} 会議室予約`}</SubTitle>
            <Title>Conference Room Reservation</Title>
          </TitleWrapper>

          <SpaceList
            base={base}
            user={user}
            handleClick={onClickSpace}
            selectedSpaceIds={selectedSpaceIds}
            spaces={spaces}
            setSpaces={setSpaces}
          />

          <SelectDate handleClick={onClickDate} />

          <SelectTimeWrapper ref={reserveTimeRef}>
            <SelectTime
              reservations={reservations}
              selectedDate={selectedDate}
              selectedSpaceIds={selectedSpaceIds}
              setSelectedDate={setSelectedDate}
              spaces={spaces}
              user={user}
              handleClickEvent={onClickEvent}
              handleSelect={onSelectTime}
            />
          </SelectTimeWrapper>

          {base.isSpacePaymentsRequired && <Notion title='お支払方法' text={base.spacePayments} />}
          {!!base.spaceNotes && <Notion title='禁止事項・注意事項' text={base.spaceNotes} />}
          {base.isSpacePaymentsRequired && <Notion title='キャンセルポリシー' text={base.spaceCancelPolicy} />}
        </Content>

        <ReservationModal
          isOpen={isModalOpen}
          mode={modalMode}
          remarks={remarks}
          reservationName={reservationName}
          selectedDate={selectedDate}
          startAt={selectedStartAt}
          endAt={selectedEndAt}
          reservations={reservations}
          spaces={!!spaces ? spaces.filter(s => selectedSpaceIds.includes(s.spaceId)) : []}
          spaceReservationIdToUpdate={selectedReserve?.spaceReservationId}
          spaceIdToReserve={spaceIdToReserve}
          user={user}
          deleteSpaceReservation={deleteSpaceReservation}
          saveSpaceReservation={saveSpaceReservation}
          setReservationName={setReservationName}
          setRemarks={setRemarks}
          setSpaceIdToReserve={setSpaceIdToReserve}
          onClose={onCloseModal}
        />
      </Container>
    </Component>
  );
});

export default SpaceReservationScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1124px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing * 2}px;
  padding: ${theme.mixins.spacing * 2}px;

  ${media.lessThan('small')`
    padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
  `}
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const SubTitle = styled(Typography)`
  text-align: left;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 24px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};

  ${media.lessThan('small')`
    margin-top: ${theme.mixins.spacing}px;
    margin-left: ${theme.mixins.spacing}px;
    font-size: 14px;
  `}
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 48px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 48px;

  ${media.lessThan('small')`
    margin-left: ${theme.mixins.spacing}px;
    font-size: ${theme.mixins.typography.fontSize.twenty}px;
    line-height: ${theme.mixins.typography.fontSize.twenty}px;
  `}

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    &&i-block-chrome {
      background: #cccccc;
    }
  }
`;

const SelectTimeWrapper = styled.div`
  width: 100%;
  height: auto;
`;
