import {
  SearchEventSchedulesV2Request,
  SearchEventSchedulesV2Response,
  SEARCH_EVENT_SCHEDULES_V2,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class EventScheduleV2Request {

  public static searchEventSchedules = async (request: SearchEventSchedulesV2Request): Promise<SearchEventSchedulesV2Response> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SEARCH_EVENT_SCHEDULES_V2));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

}
