export enum Hour {
  ONE = '1',
  ONE_AND_A_HALF = '1.5',
  TWO = '2',
  TWO_AND_A_HALF = '2.5',
  THREE = '3',
  THREE_AND_A_HALF = '3.5',
  FOUR = '4',
  FOUR_AND_A_HALF = '4.5',
  FIVE = '5',
  FIVE_AND_A_HALF = '5.5',
  SIX = '6',
  SIX_AND_A_HALF = '6.5',
  SEVEN = '7',
  SEVEN_AND_A_HALF = '7.5',
  EIGHT = '8',
  EIGHT_AND_A_HALF = '8.5',
  NINE = '9',
  NINE_AND_A_HALF = '9.5',
  TEN = '10',
  TEN_AND_A_HALF = '10.5',
  ELEVEN = '11',
  ELEVEN_AND_A_HALF = '11.5',
  TWELVE = '12'
}

export enum NumberOfPeople {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
  TEN = '10',
  ELEVEN = '11',
  TWELVE = '12',
  THIRTEEN = '13',
  FOURTEEN = '14',
  FIFTEEN = '15'
}
