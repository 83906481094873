import {
  Button,
  Component,
  InputBox,
  ImageInputBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  SaveEventV2Request,
} from '@atomica.co/irori';
import {
  builder,
  embedIdInPath,
  EMPTY,
  Name,
  Text,
  uuid,
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { Path, PATH_IDS } from '../../router/Routes';
import styled from 'styled-components';
import { toEventV2 } from '../../converters/event-v2-converter';
import EventV2Request from '../../requests/event-v2-request';

interface P {
  base: BaseDto;
  onChange(path: Path): void;
}

const RegisterEventV2Screen: React.FC<P> = React.memo(props => {
  const { base, onChange } = props;
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [nameToSave, setNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [descriptionToSave, setDescriptionToSave] = useSafeState<Text>(unmountRef, EMPTY);
  const [photoURLToSave, setPhotoURLToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [photo, setPhoto] = useSafeState<File>(unmountRef, undefined);

  const disabledSaveButton = useMemo<boolean>(() => {
    return (
      !nameToSave ||
      !descriptionToSave
    );
  }, [
    nameToSave,
    descriptionToSave
  ]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!base) return;

    setLoaded(true);
  }, [base, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openEventV2ListScreen = useSafeCallback((): void => {
    onChange(embedIdInPath(Path.EVENT_V2_LIST, PATH_IDS, [base.baseCode]));
  }, [onChange, base]);

  const saveNewEvent = useSafeCallback(async (): Promise<void> => {
    if (!base) return;
    setSaving(true);

    // const uploadedPhotoURL = await EventV2Request.uploadEventPhoto(photo);
    // console.log(uploadedPhotoURL);
    const eventToSave = toEventV2(
      uuid(),
      nameToSave,
      descriptionToSave,
      photoURLToSave,
      base as BaseDto
    );

    const eventRequest = builder<SaveEventV2Request>().eventV2(eventToSave).build();

    await EventV2Request.saveEvent(eventRequest);

    openEventV2ListScreen();

  }, [
    setSaving,
    nameToSave,
    descriptionToSave,
    photoURLToSave,
    base,
    openEventV2ListScreen
  ]);

  const facePhotoToSaveOnChange = useSafeCallback(
    async (file: File): Promise<boolean> => {
      setPhoto(file);
      return true;
    },
    [setPhoto]
  );

  return (
    <Component
      loading={!loaded || saving}
      style={{ width: '100%', height: `calc(100vh - 64px)` }}
      className='register-event-v2-screen'
    >
      <Container>
        <Content>
          <SubTitle>イベントの登録</SubTitle>
          <Title>Contract Registration</Title>

          <FullInputWrapper>
            <InputBox
              required
              autoFocus
              type='text'
              label='イベント名'
              text={nameToSave}
              onChange={setNameToSave}
            />
          </FullInputWrapper>

          <FullInputWrapper>
            <InputBox
              required
              autoFocus
              type='text'
              label='イベント説明'
              text={descriptionToSave}
              onChange={setDescriptionToSave}
            />
          </FullInputWrapper>

          <FullInputWrapper>
            <InputBox
              required
              autoFocus
              type='text'
              label='写真URL'
              text={photoURLToSave}
              onChange={setPhotoURLToSave}
            />
          </FullInputWrapper>

          <FullInputWrapper>
            <ImageInputBox required title='顔認証用の顔写真を登録する' onChange={facePhotoToSaveOnChange} />
          </FullInputWrapper>

          <ButtonWrapper>
            <Button type='primary' disabled={disabledSaveButton} onClick={saveNewEvent}>
              <Label>イベント登録</Label>
            </Button>
          </ButtonWrapper>
        </Content>
      </Container>

    </Component>
  );
});

export default RegisterEventV2Screen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  margin: ${theme.mixins.spacing * 4}px 0px 0px ${theme.mixins.spacing * 3}px;
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 56px;
  margin: 0px 0px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
`;

const FullInputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  padding: 0px ${theme.mixins.spacing / 2}px;
`;

const Label = styled(Typography)`
  width: 160px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;
