import { MOBILE_MAX_WIDTH, MOBILE_MIN_WIDTH, theme } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import mojaco from './../../assets/mojaco/mojaco_thanks.png';

interface P {}

const RegisteredScreen: React.FC<P> = React.memo(() => {
  return (
    <Screen className='registered-screen'>
      <Container>
        <Content>
          <MessageWrapper>
            <StyledMessage>登録が完了しました！</StyledMessage>
          </MessageWrapper>

          <Mojaco src={mojaco} />
        </Content>
      </Container>
    </Screen>
  );
});

export default RegisteredScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px;
`;

const StyledMessage = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  user-select: none;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  user-select: none;
  padding: ${theme.mixins.spacing}px;
`;
