import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { EMPTY } from '@atomica.co/utils';
import { SpaceId } from '@atomica.co/irori';
import { toSpaceReservationAction } from '../actions/space-reserve-action';
import store from '../store';

export interface CashedSpaceReservation {
  reservationName?: string;
  remarks?: string;
  spaceIdToReserve?: SpaceId;
  selectedDate?: Date;
  selectedStartAt?: Date;
  selectedEndAt?: Date;
  isModalOpen?: boolean;
}

interface P {
  cashedSpaceReservation: CashedSpaceReservation;
  saveCashedSpaceReservation: (spaceReservation: CashedSpaceReservation) => void;
  clearCashedSpaceReservation: () => void;
}

export const initialCashedSpaceReservation: CashedSpaceReservation = {
  reservationName: EMPTY,
  remarks: EMPTY,
  spaceIdToReserve: undefined,
  selectedDate: undefined,
  selectedStartAt: undefined,
  selectedEndAt: undefined,
  isModalOpen: false
};

function useCashedSpaceReservation() {
  const unmountRef = useUnmountRef();
  const [cashedSpaceReservation, setCashedSpaceReservation] = useSafeState<CashedSpaceReservation>(
    unmountRef,
    store.getState().cashedSpaceReservation
  );

  const saveCashedSpaceReservation = useSafeCallback(
    (spaceReservation: CashedSpaceReservation): void => {
      setCashedSpaceReservation(spaceReservation);
      const action = toSpaceReservationAction(spaceReservation);
      store.dispatch(action);
    },
    [setCashedSpaceReservation]
  );

  const clearCashedSpaceReservation = useSafeCallback((): void => {
    setCashedSpaceReservation(initialCashedSpaceReservation);
    const action = toSpaceReservationAction(initialCashedSpaceReservation);
    store.dispatch(action);
  }, [setCashedSpaceReservation]);

  return {
    cashedSpaceReservation,
    saveCashedSpaceReservation,
    clearCashedSpaceReservation
  } as P;
}

export default useCashedSpaceReservation;
