import {
  SaveSpaceRequest,
  SaveSpaceResponse,
  SAVE_SPACE,
  SearchSpacesForContractRequest,
  SearchSpacesForContractResponse,
  SearchSpacesRequest,
  SearchSpacesResponse,
  SEARCH_SPACES,
  SEARCH_SPACES_FOR_CONTRACT,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class SpaceRequest {
  public static saveSpace = async (request: SaveSpaceRequest): Promise<SaveSpaceResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_SPACE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchSpaces = async (request: SearchSpacesRequest): Promise<SearchSpacesResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SEARCH_SPACES));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchSpacesForContract = async (
    request: SearchSpacesForContractRequest
  ): Promise<SearchSpacesForContractResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SEARCH_SPACES_FOR_CONTRACT));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
