import {
  CountShotUsageRequest,
  CountShotUsageResponse,
  COUNT_SHOT_USAGE,
  DeleteShotUsageRequest,
  DeleteShotUsageResponse,
  DELETE_SHOT_USAGE,
  FetchShotUsagesByDatesRequest,
  FetchShotUsagesByDatesResponse,
  FETCH_SHOT_USAGES_BY_DATES,
  SaveShotUsageRequest,
  SaveShotUsageResponse,
  SAVE_SHOT_USAGE,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class ShotUsageRequest {
  public static saveShotUsage = async (request: SaveShotUsageRequest): Promise<SaveShotUsageResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_SHOT_USAGE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchShotUsagesByDates = async (
    request: FetchShotUsagesByDatesRequest
  ): Promise<FetchShotUsagesByDatesResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_SHOT_USAGES_BY_DATES));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static countShotUsage = async (request: CountShotUsageRequest): Promise<CountShotUsageResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(COUNT_SHOT_USAGE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static deleteShotUsage = async (request: DeleteShotUsageRequest): Promise<DeleteShotUsageResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(DELETE_SHOT_USAGE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
