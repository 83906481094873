import { EMPTY, REDIRECT_PATH } from '@atomica.co/utils';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Screen from '../../components/screen/Screen';

interface P extends RouteComponentProps {}

const RedirectScreen: React.FC<P> = React.memo(() => {
  useEffect(() => {
    const redirectURL = window.location.search.substring(1).replace(`${REDIRECT_PATH}=`, EMPTY);
    window.location.href = redirectURL;

    setTimeout(() => {
      window.history.back();
      window.close();
    }, 3000);
  }, []);

  return <Screen loading className='redirect-screen' />;
});

export default RedirectScreen;
