import { AccessStatus, UsageType } from '@atomica.co/irori';
import { ColorInfo } from '../models/common-model';

export const ACCESS_STATUS_COLORS: ColorInfo = {
  [AccessStatus.IN]: '#ff7165',
  [AccessStatus.OUT]: '#54d3e3'
};

export const USAGE_TYPE_COLORS: ColorInfo = {
  [UsageType.COWORKING]: '#ff7165',
  [UsageType.ONE_DAY]: '#ff7165',
  [UsageType.ONE_HOUR]: '#ff7165',
  [UsageType.TWO_HOURS]: '#ff7165',
  [UsageType.THREE_HOURS]: '#ff7165',
  [UsageType.CONFERENCE]: '#9dde22',
  [UsageType.EVENT]: '#54d3e3',
  [UsageType.GUEST]: '#34d590',
  [UsageType.PREVIEW]: '#ffa143'
};
