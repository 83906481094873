export enum Process {
  INITIAL = 'initial',
  PROCESSING = 'processing',
  COMPLETED = 'completed'
}

export enum ProcessActionEnum {
  SET = 'set_process',
  PURGE = 'persist/PURGE'
}

export enum PreviousURLActionEnum {
  SET = 'set_previous_url',
  PURGE = 'persist/PURGE'
}

export enum CashedURLActionEnum {
  SET = 'set_cashed_url',
  PURGE = 'persist/PURGE'
}

export enum CashedSpaceReservationActionEnum {
  SET = 'set_cashed_space_reservation',
  PURGE = 'persist/PURGE'
}
