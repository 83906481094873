import { Component } from '@atomica.co/components';
import { EventParticipant } from '@atomica.co/irori';
import { EMPTY } from '@atomica.co/utils';
import React from 'react';
import mojaco_asking from './../../../../assets/mojaco/mojaco_asking.png';
import mojaco_break from './../../../../assets/mojaco/mojaco_break.png';
import ParticipantCard from './ParticipantCard';

const MOJAO = {
  user: {
    familyName: EMPTY,
    firstName: 'モジャ夫',
    photoURL: mojaco_break
  },
  comment:
    '宮崎に来て一年半しか経ってなくて、なかなか知り合いが増えません。\nお酒が好きなので、お勧めの飲み屋があれば教えてほしいです。\nマリンスポーツも始めてみたいので、初心者でも楽しめる方法をご存知の方は教えてください。\n割と人見知りはしない方だと思ってますので、ぜひ友達になってください。'
} as EventParticipant;

const MOJAKO = {
  user: {
    familyName: EMPTY,
    firstName: 'モジャ子',
    photoURL: mojaco_asking
  },
  comment:
    '普段はATOMica東京で働いてます。\n新規事業開発やワクワクする様な企画をすることが好きです♪ お酒も飲みます🍶\n一次産業など宮崎に根付く地場産業をされている方と知り合いになりたいです！どうぞよろしくお願いいたします。'
} as EventParticipant;

interface P {
  participants: EventParticipant[];
}

const ParticipantCards: React.FC<P> = React.memo(props => {
  const { participants } = props;

  return (
    <Component className='participant-cards'>
      <ParticipantCard participant={MOJAO} />

      <ParticipantCard participant={MOJAKO} />

      {participants.map((participant, index) => (
        <ParticipantCard key={index} participant={participant} />
      ))}
    </Component>
  );
});

export default ParticipantCards;
