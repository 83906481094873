import React from 'react';
import AuthGuard from '../../../auth/AuthGuard';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';
import RecordAccessScreen from '../../access/RecordAccessScreen';
// import AccountScreen from '../../account/AccountScreen';
import ConsoleScreen from '../../console/ConsoleScreen';
import ConsumerScreen from '../../consumer/ConsumerScreen';
import RegisterContractMemberScreen from '../../contract/RegisterContractMemberScreen';
import JoinEventScreen from '../../event/JoinEventScreen';
import RegisteredScreen from '../../registered/RegisteredScreen';
// import SettingScreen from '../../setting/SettingScreen';
import InputShotScreen from '../../shot/InputShotScreen';
// import SelectBaseScreen from '../../shot/SelectBaseScreen';
import ShotReceptionScreen from '../../shot/ShotReceptionScreen';

const GuardedScreens: React.FC = React.memo(() => {
  const { path } = usePath();

  return (
    <>
      {/* {path === Path.ACCOUNT && <AccountScreen />} */}

      {path === Path.MY_ACCOUNT && <ConsumerScreen />}

      {path === Path.REGISTER_CONTRACT_MEMBER && <RegisterContractMemberScreen />}

      {/* {path === Path.SELECT_BASE && <SelectBaseScreen />} */}

      {path === Path.REGISTER_SHOT && <InputShotScreen />}

      {path === Path.SHOT_RECEPTION && <ShotReceptionScreen />}

      {path === Path.RECORD_ACCESS && <RecordAccessScreen />}

      {path === Path.JOIN_EVENT && <JoinEventScreen />}

      {(path === Path.TIMELINE ||
        path === Path.VISITORS ||
        path === Path.USER_SEARCH ||
        path === Path.USER_DETAILS ||
        path === Path.CONTRACT_LIST ||
        path === Path.CONTRACT_DETAILS ||
        path === Path.REGISTER_CONTRACT ||
        path === Path.EVENT_LIST ||
        path === Path.EVENT_V2_LIST ||
        path === Path.EVENT_V2_DETAILS ||
        path === Path.REGISTER_EVENT_V2 ||
        path === Path.DIARY ||
        path === Path.EXPORT) && <ConsoleScreen />}

      {path === Path.REGISTERED && <RegisteredScreen />}

      {/* {path === Path.SETTING && <SettingScreen />} */}
    </>
  );
});

export default AuthGuard(GuardedScreens);
