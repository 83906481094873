import { BaseDto, QrCodeSpaceReservationData, SpaceManagement, SpaceReservation, User } from '@atomica.co/irori';
import { EMPTY, hasLength, ID, Name, QrCodeBuffer, Remarks, toUTC, URL } from '@atomica.co/utils';
import { format } from 'date-fns';
import jaLocale from 'date-fns/locale/ja';
import { getBasePIC } from '../utils/user-util';

export interface SpaceReservationCard {
  spaceReservationId: ID;
  spaceName: Name;
  spacePhotoUrl: URL;
  reservationName: Name;
  reservationStartAt?: Date;
  reservationEndAt?: Date;
  reservationRemarks: Remarks;
  blobQrCode: string;
}

const convertBufferToBlobPng = (buffer: QrCodeBuffer | undefined): string => {
  if (!buffer) return EMPTY;
  const arrayBuffer = Object.values(buffer);
  const blob = new Blob([Buffer.from(arrayBuffer)], { type: 'image/png' });
  const urlCreator = window.URL || window.webkitURL;
  return urlCreator.createObjectURL(blob);
};

export const convertToSpaceReservationCards = (
  user: User,
  base: BaseDto,
  qrcodeForAllSpaces: QrCodeBuffer | undefined,
  qrcodeForContractedSpaces: QrCodeBuffer | undefined,
  qrcodesForEachSpaceReservation: QrCodeSpaceReservationData[],
  spaceReservations: SpaceReservation[]
): SpaceReservationCard[] => {
  const cards: SpaceReservationCard[] = [];

  if (!!qrcodeForAllSpaces) {
    const card = convertToCardForAllSpaces(user, base, qrcodeForAllSpaces);
    !!card && cards.push(card);
  }

  if (!!qrcodeForContractedSpaces) {
    const card = convertToCardForContractedSpaces(user, base, qrcodeForContractedSpaces);
    !!card && cards.push(card);
  }

  if (!hasLength(spaceReservations)) {
    return cards;
  }

  return spaceReservations.reduce((prev: SpaceReservationCard[], current: SpaceReservation) => {
    const card = convertToCardForEachSpaceReservation(base, qrcodesForEachSpaceReservation, current);
    prev.push(card);
    return prev;
  }, cards);
};

const convertToCardForAllSpaces = (
  user: User,
  base: BaseDto,
  qrcodeForAllSpaces: QrCodeBuffer | undefined
): SpaceReservationCard | undefined => {
  if (base.spaceManagement !== SpaceManagement.DENSO) return undefined;
  const pic = hasLength(user.personInCharge)
    ? user.personInCharge!.find(pic => pic.baseDto.baseId === base.baseId)
    : undefined;
  const authority = !!pic ? pic.authority : undefined;
  const photoURL = !!authority && !!authority.photoURL ? authority.photoURL : EMPTY;
  const endAt = !!user.endDate ? toUTC(new Date(user.endDate)) : undefined;

  return {
    spaceReservationId: EMPTY,
    spaceName: EMPTY,
    spacePhotoUrl: photoURL,
    reservationName: EMPTY,
    reservationStartAt: undefined,
    reservationEndAt: endAt,
    reservationRemarks: EMPTY,
    blobQrCode: convertBufferToBlobPng(qrcodeForAllSpaces)
  };
};

const convertToCardForContractedSpaces = (
  user: User,
  base: BaseDto,
  qrcodeForContractedSpaces: QrCodeBuffer | undefined
): SpaceReservationCard | undefined => {
  if (base.spaceManagement !== SpaceManagement.DENSO) return undefined;
  const pic = getBasePIC(user, base);
  const authority = pic.authority;
  const endAt = !!user.endDate ? toUTC(new Date(user.endDate)) : undefined;

  return {
    spaceReservationId: EMPTY,
    spaceName: !!authority ? authority.authorityName : EMPTY,
    spacePhotoUrl: base.photoURL || EMPTY,
    reservationName: EMPTY,
    reservationStartAt: undefined,
    reservationEndAt: endAt,
    reservationRemarks: EMPTY,
    blobQrCode: convertBufferToBlobPng(qrcodeForContractedSpaces)
  };
};

export const convertToCardForEachSpaceReservation = (
  base: BaseDto,
  qrcodesForEachSpaceReservation: QrCodeSpaceReservationData[],
  spaceReservation: SpaceReservation
): SpaceReservationCard => {
  const startAt = toUTC(new Date(spaceReservation.startAt));
  const endAt = toUTC(new Date(spaceReservation.endAt));
  const blobQrCode =
    base.spaceManagement === SpaceManagement.DENSO
      ? convertBufferToBlobPng(
          qrcodesForEachSpaceReservation.find(
            qrcode => qrcode.spaceReservationId === spaceReservation.spaceReservationId
          )!.buffer
        )
      : EMPTY;

  return {
    spaceReservationId: spaceReservation.spaceReservationId,
    spaceName: spaceReservation.space.spaceName || spaceReservation.space.name,
    spacePhotoUrl: spaceReservation.space.photoURL || EMPTY,
    reservationName: EMPTY,
    reservationStartAt: startAt,
    reservationEndAt: endAt,
    reservationRemarks: spaceReservation.remarks,
    blobQrCode
  };
};

export interface SpaceReservationsGrid {
  [reservationDay: string]: SpaceReservation[];
}

export const convertToSpaceReservationsGrid = (spaceReservations: SpaceReservation[]): SpaceReservationsGrid => {
  if (!hasLength(spaceReservations)) return {};
  return spaceReservations.reduce((prev: SpaceReservationsGrid, current: SpaceReservation) => {
    const startAt = toUTC(new Date(current.startAt));
    const reservationDay = format(startAt!, 'yyyy年MM月dd日', { locale: jaLocale });
    if (!prev[reservationDay]) prev[reservationDay] = [];
    prev[reservationDay].push(current);
    return prev;
  }, {});
};
