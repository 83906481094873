import {
  Button,
  CheckBox,
  CommentBox,
  DateBox,
  ImageInputBox,
  InputBox,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  RadioBox,
  Scrollable,
  SelectBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityDefCodeEnum,
  AUTHORITY_CODE,
  BaseAuthority,
  BaseDto,
  BASE_CODE,
  Category,
  END_DATE,
  ErrorCodeEnum,
  ERROR_MESSAGES,
  FetchBaseAuthoritiesRequest,
  FetchBaseRequest,
  PersonInCharge,
  Prefecture,
  SaveFirebaseRequest,
  SavePersonInChargeRequest,
  START_DATE,
  UserInflowSource
} from '@atomica.co/irori';
import {
  Address,
  builder,
  Code,
  Email,
  EMAIL_CONST,
  EMPTY,
  hasLength,
  Message,
  MINUS_ONE,
  Name,
  Password,
  Phone,
  URL,
  uuid
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { CASHED_URL } from '../../constants/common-const';
import { setUserId, toUserToSave } from '../../converters/user-converter';
import { auth } from '../../firebase';
import useCashedURL from '../../redux/hooks/useCashedURL';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import BaseAuthorityRequest from '../../requests/base-authority-request';
import BaseRequest from '../../requests/base-request';
import PersonInChargeRequest from '../../requests/person-in-charge-request';
import QFaceRequest from '../../requests/qface-request';
import UserRequest from '../../requests/user-request';
import { Path } from '../../router/Routes';
import { FaceRecognitionService } from '../../services/face-recognition-service';
import { PREFECTURE_LABELS } from '../../texts/common-text';
import { USER_CATEGORY_LABELS, USER_INFLOW_SOURCE_LABELS } from '../../texts/user-text';
import mojaco_greeting from './../../assets/mojaco/mojaco_greeting.png';

type InflowSource = string;

interface P {}

const AUTHORITY_ERROR_MSG = '権限設定が不足しています\r\n管理者にお問い合わせください';

const RegisterAccountScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { params, queryParams, openBasePath } = usePath();
  const { saveCashedURL, hasCashedURL, openCashedURL } = useCashedURL();
  const { getUser, saveActivatedUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [base, setBase] = useSafeState<BaseDto>(unmountRef);
  const [authorities, setAuthorities] = useSafeState<BaseAuthority[]>(unmountRef, []);
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, true);
  const [isTermOfUseAgreed, setIsTermOfUseAgreed] = useSafeState<boolean>(unmountRef, false);
  const [familyNameToSave, setFamilyNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstNameToSave, setFirstNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [companyNameToSave, setCompanyNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [passwordToSave, setPasswordToSave] = useSafeState<Password>(unmountRef, EMPTY);
  const [dateOfBirthToSave, setDateOfBirthToSave] = useSafeState<Date>(unmountRef, new Date('1990-01-01'));
  const [phoneToSave, setPhoneToSave] = useSafeState<Phone>(unmountRef, EMPTY);
  const [postalCodeToSave, setPostalCodeToSave] = useSafeState<Code>(unmountRef, EMPTY);
  const [prefectureToSave, setPrefectureToSave] = useSafeState<Prefecture>(unmountRef, EMPTY as Prefecture);
  const [addressToSave, setAddressToSave] = useSafeState<Address>(unmountRef, EMPTY);
  const [categoryToSave, setCategoryToSave] = useSafeState<Category>(unmountRef, Category.WORK);
  const [inflowSourceToSave, setInflowSourceToSave] = useSafeState<UserInflowSource>(
    unmountRef,
    UserInflowSource.INTERNET
  );
  const [otherInflowSourceToSave, setOtherInflowSourceToSave] = useSafeState<InflowSource>(
    unmountRef,
    EMPTY as InflowSource
  );
  const [facePhoto, setFacePhoto] = useSafeState<File>(unmountRef, undefined);
  const [faceFeatureStrToSave, setFaceFeatureStrToSave] = useSafeState<string>(unmountRef, undefined);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  // FIXME
  const isPublic = useMemo<boolean>(() => {
    return !base || base.isPublic;
  }, [base]);

  const isFacePhotoRequired = useMemo<boolean>(() => {
    const authorityCode = queryParams[AUTHORITY_CODE];
    if (!authorityCode || !hasLength(authorities)) return false;

    const authority = authorities!.find(auth => auth.authorityCode === authorityCode);
    if (!authority) return false;

    const authorityDef = authority.authorityDef;
    return !!authorityDef && authorityDef.authorityDefCode === AuthorityDefCodeEnum.FACE_ACCESS;
  }, [authorities, queryParams]);

  const startDateToSave = useMemo<Date | undefined>(() => {
    const startDate = queryParams[START_DATE];
    return startDate ? new Date(startDate) : undefined;
  }, [queryParams]);

  const endDateToSave = useMemo<Date | undefined>(() => {
    const endDate = queryParams[END_DATE];
    return endDate ? new Date(endDate) : undefined;
  }, [queryParams]);

  const email = useMemo<Email | undefined>(() => {
    return queryParams[EMAIL_CONST];
  }, [queryParams]);

  const cashedURL = useMemo((): URL => {
    return queryParams[CASHED_URL];
  }, [queryParams]);

  const fetchBaseAndAuthorities = useSafeCallback(async (): Promise<void> => {
    const baseCode = params[BASE_CODE];

    const baseRequest = builder<FetchBaseRequest>().baseCode(baseCode).build();
    const authRequest = builder<FetchBaseAuthoritiesRequest>().baseCode(baseCode).build();

    const [baseResponse, authResponse] = await Promise.all([
      BaseRequest.fetchBase(baseRequest),
      BaseAuthorityRequest.fetchAuthorities(authRequest)
    ]);

    const base = baseResponse.base;
    const authorities = authResponse.authorities;
    setBase(base);
    setAuthorities(authorities);
  }, [params, setBase, setAuthorities]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    !!cashedURL && saveCashedURL(cashedURL);
    await fetchBaseAndAuthorities();
    setLoaded(true);
  }, [cashedURL, saveCashedURL, fetchBaseAndAuthorities, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const savingDisabled =
      !isTermOfUseAgreed ||
      !familyNameToSave ||
      !firstNameToSave ||
      (!!email && !passwordToSave) ||
      !dateOfBirthToSave ||
      !phoneToSave ||
      !categoryToSave ||
      !inflowSourceToSave ||
      (isFacePhotoRequired && !faceFeatureStrToSave);

    setDisabledSaveButton(savingDisabled);
  }, [
    isTermOfUseAgreed,
    familyNameToSave,
    firstNameToSave,
    email,
    passwordToSave,
    dateOfBirthToSave,
    phoneToSave,
    categoryToSave,
    inflowSourceToSave,
    faceFeatureStrToSave,
    isFacePhotoRequired,
    setDisabledSaveButton
  ]);

  const facePhotoToSaveOnChange = useSafeCallback(
    async (file: File): Promise<boolean> => {
      const arrayBuffer = await file.arrayBuffer();
      const imageData = Buffer.from(arrayBuffer).toString('base64');
      const response = await QFaceRequest.create({ imageData });

      if (!response.result) {
        setErrorMessage(response.message!);
        setFaceFeatureStrToSave(EMPTY);
        return false;
      }

      setFaceFeatureStrToSave(response.data!);
      setFacePhoto(file);
      return true;
    },
    [setFaceFeatureStrToSave, setErrorMessage, setFacePhoto]
  );

  const openMyAccountScreen = useSafeCallback((): void => {
    if (!isPublic) {
      openBasePath(Path.REGISTERED);
      return;
    }

    hasCashedURL() ? openCashedURL() : openBasePath(Path.REGISTER_SHOT);
  }, [isPublic, hasCashedURL, openCashedURL, openBasePath]);

  const constructErrorMessage = useSafeCallback(
    (code: ErrorCodeEnum): void => {
      if (!code) return;

      Object.entries(ERROR_MESSAGES)
        .filter(([key, value]) => code.toString().indexOf(key) !== MINUS_ONE)
        .forEach(([key, value]) => setErrorMessage(value));
    },
    [setErrorMessage]
  );

  const saveUserInformation = useSafeCallback(async (): Promise<void> => {
    try {
      setDisabledSaveButton(true);

      const authorityCode = queryParams[AUTHORITY_CODE];
      const authority = !!authorityCode
        ? authorities.find(auth => auth.authorityCode === authorityCode)
        : authorities.find(auth => auth.authorityDef.authorityDefCode === AuthorityDefCodeEnum.VISITOR);

      if (!authority) {
        setErrorMessage(AUTHORITY_ERROR_MSG);
        setDisabledSaveButton(false);
        return;
      }

      if (!!email) {
        const request = builder<SaveFirebaseRequest>().email(email).password(passwordToSave).build();
        const response = await UserRequest.saveFirebase(request);
        const customToken = response.customToken;
        const { user } = await auth.signInWithCustomToken(customToken!);
        !!user && (await user.updateEmail(email));
      }

      const user = await getUser();
      const facePhotoURL = isFacePhotoRequired ? await FaceRecognitionService.putImageFileToStorage(facePhoto) : EMPTY;

      const userToSave = toUserToSave(
        user,
        startDateToSave,
        endDateToSave,
        familyNameToSave,
        firstNameToSave,
        companyNameToSave,
        dateOfBirthToSave,
        phoneToSave,
        email,
        postalCodeToSave,
        prefectureToSave,
        addressToSave,
        categoryToSave,
        inflowSourceToSave === UserInflowSource.OTHER ? otherInflowSourceToSave : inflowSourceToSave,
        facePhotoURL,
        isFacePhotoRequired ? faceFeatureStrToSave : EMPTY
      );

      const userId = await saveActivatedUser(userToSave);

      const personInCharge = builder<PersonInCharge>()
        .personInChargeId(uuid())
        .authority(authority!)
        .authorityDef(authority!.authorityDef)
        .baseDto(authority!.base)
        .base(authority!.base.baseCode)
        .user(setUserId(userToSave, userId!))
        .build();

      const personInChargeRequest = builder<SavePersonInChargeRequest>().personInCharges([personInCharge]).build();
      await PersonInChargeRequest.savePersonInCharge(personInChargeRequest);

      openMyAccountScreen();
    } catch (err: any) {
      setDisabledSaveButton(false);
      constructErrorMessage(err.code);
    }
  }, [
    authorities,
    setErrorMessage,
    setDisabledSaveButton,
    email,
    passwordToSave,
    getUser,
    startDateToSave,
    endDateToSave,
    familyNameToSave,
    firstNameToSave,
    companyNameToSave,
    dateOfBirthToSave,
    phoneToSave,
    postalCodeToSave,
    prefectureToSave,
    addressToSave,
    categoryToSave,
    otherInflowSourceToSave,
    inflowSourceToSave,
    facePhoto,
    faceFeatureStrToSave,
    isFacePhotoRequired,
    queryParams,
    saveActivatedUser,
    openMyAccountScreen,
    constructErrorMessage
  ]);

  const openTermOfUse = useSafeCallback((): void => {
    window.open(base.termsOfUseURL, 'newtab');
  }, [base]);

  const openPrivacyPolicy = useSafeCallback((): void => {
    window.open('https://atomica.site/privacy-policy', 'newtab');
  }, []);

  return (
    <Screen loading={!loaded} className='register-account-screen'>
      <Container>
        <Content>
          <Scrollable>
            <MojacoWrapper>
              <CommentBox animation photoURL={mojaco_greeting}>
                <MojacoGreeting>
                  はじめまして！お得な情報をお送りするので、まずはアカウント登録をよろしくね！登録自体は1分で終わるよ！
                </MojacoGreeting>
              </CommentBox>
            </MojacoWrapper>

            <NameWrapper>
              <PartialNameWrapper>
                <InputBox required maxLength={15} label='姓' text={familyNameToSave} onChange={setFamilyNameToSave} />
              </PartialNameWrapper>

              <PartialNameWrapper>
                <InputBox required maxLength={15} label='名' text={firstNameToSave} onChange={setFirstNameToSave} />
              </PartialNameWrapper>
            </NameWrapper>

            {!!email && (
              <InputWrapper>
                <InputBox
                  required
                  maxLength={12}
                  type='password'
                  label='パスワード(6文字以上)'
                  text={passwordToSave}
                  onChange={setPasswordToSave}
                />
              </InputWrapper>
            )}

            <InputWrapper>
              <InputBox
                required
                maxLength={15}
                type='tel'
                label='電話番号（ハイフンなし）'
                text={phoneToSave}
                onChange={setPhoneToSave}
              />
            </InputWrapper>

            <DateWrapper>
              <DateBox label='誕生日' value={dateOfBirthToSave} onChange={setDateOfBirthToSave} />
            </DateWrapper>

            <InputWrapper>
              <InputBox type='number' label='郵便番号' text={postalCodeToSave} onChange={setPostalCodeToSave} />
            </InputWrapper>

            <InputWrapper>
              <SelectBox
                required
                label='都道府県'
                options={Object.values(Prefecture)}
                labels={PREFECTURE_LABELS}
                value={prefectureToSave}
                onChange={setPrefectureToSave}
              />
            </InputWrapper>

            <InputWrapper>
              <InputBox type='text' label='市区町村以降' text={addressToSave} onChange={setAddressToSave} />
            </InputWrapper>

            <InputWrapper>
              <InputBox type='text' label='法人名' text={companyNameToSave} onChange={setCompanyNameToSave} />
            </InputWrapper>

            {isPublic && (
              <RadioWrapper>
                <RadioBox
                  title='あてはまるものをお選びください'
                  options={Object.values(Category)}
                  labels={USER_CATEGORY_LABELS}
                  value={categoryToSave}
                  onChange={setCategoryToSave}
                />
              </RadioWrapper>
            )}

            {!!base && isPublic && (
              <RadioWrapper>
                <RadioBox
                  title={`${base.baseName} をどこで知りましたか？`}
                  options={Object.values(UserInflowSource)}
                  labels={USER_INFLOW_SOURCE_LABELS}
                  value={inflowSourceToSave}
                  onChange={setInflowSourceToSave}
                />
              </RadioWrapper>
            )}

            {inflowSourceToSave === UserInflowSource.OTHER && isPublic && (
              <InputWrapper>
                <InputBox
                  maxLength={15}
                  label='その他'
                  text={otherInflowSourceToSave}
                  onChange={setOtherInflowSourceToSave}
                />
              </InputWrapper>
            )}

            {isFacePhotoRequired && (
              <InputWrapper>
                <ImageInputBox required title='顔認証用の顔写真を登録する' onChange={facePhotoToSaveOnChange} />
              </InputWrapper>
            )}

            <Agreement>
              <CheckBox checked={isTermOfUseAgreed} onChange={setIsTermOfUseAgreed}>
                <StyledMessage>
                  <Link
                    onClick={e => {
                      e.stopPropagation();
                      openTermOfUse();
                    }}
                  >
                    ご利用規約
                  </Link>
                  および
                  <Link
                    onClick={e => {
                      e.stopPropagation();
                      openPrivacyPolicy();
                    }}
                  >
                    プライバシーポリシー
                  </Link>
                  に同意する
                </StyledMessage>
              </CheckBox>
            </Agreement>

            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            <ButtonWrapper>
              <Button type='primary' disabled={disabledSaveButton} onClick={saveUserInformation}>
                <Label>アカウント登録</Label>
              </Button>
            </ButtonWrapper>

            <BottomArea />
          </Scrollable>
        </Content>
      </Container>
    </Screen>
  );
});

export default RegisterAccountScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: 100%;
`;

const MojacoWrapper = styled.div`
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const MojacoGreeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const NameWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const PartialNameWrapper = styled.div`
  width: calc(50% - ${theme.mixins.spacing / 2}px);
  height: auto;
`;

const RadioWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const DateWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Agreement = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing / 2}px;
`;

const StyledMessage = styled.div`
  width: 240px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Link = styled(Typography)`
  display: inline;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  cursor: pointer;
`;

const ErrorMessage = styled.pre`
  width: 100%;
  height: auto;
  min-height: 18px;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 4}px;
`;

const Label = styled(Typography)`
  padding: 0px ${theme.mixins.spacing * 6}px;
`;

const BottomArea = styled.div`
  width: 100%;
  height: 120px;
`;
