import { uuid } from '@atomica.co/utils';
import { AxiosRequestConfig } from 'axios';
import querystring from 'querystring';

export const IRORI_CLIENT_ID = 1656282585;

export const IRORI_CLIENT_SECRET = 'f8f2138f8e47eb250a13131331eb0055';

export const NONCE = '09876xyz';

export const LINE_AUTHORIZE_URL = 'https://access.line.me/oauth2/v2.1/authorize';

export const LINE_AUTHORIZE_PARAMS: querystring.ParsedUrlQueryInput = {
  response_type: 'code',
  client_id: IRORI_CLIENT_ID,
  state: uuid(),
  scope: 'profile openid email',
  nonce: NONCE,
  prompt: 'consent',
  max_age: 120,
  bot_prompt: 'normal'
};

export const LINE_TOKEN_URL = 'https://api.line.me/oauth2/v2.1/token';

export const LINE_TOKEN_CONFIG: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};

export const LIFF_ID = '1656282585-nMV8O2E8';
