export enum SearchOption {
  OFFSET = 0,
  LIMIT = 200
}

export enum ReservationModalMode {
  NEW = 'new',
  EDIT = 'edit',
  READONLY = 'readonly'
}
