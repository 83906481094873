import {
  Button,
  Component,
  Skeleton,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BASE_CODE,
  constructExternalLink,
  DeleteSpaceReservationRequest,
  SaveSpaceReservationRequest,
  SHARED_SPACE_RESERVATION_END_AT,
  SHARED_SPACE_RESERVATION_ID,
  SHARED_SPACE_RESERVATION_START_AT,
  SpaceParticipantDiv,
  SpaceReservation,
  User
} from '@atomica.co/irori';
import { builder, copyURL, embedIdInPath, toUTC, URL, ZERO } from '@atomica.co/utils';
import Paper from '@material-ui/core/Paper';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SendIcon from '@material-ui/icons/Send';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { BOTTOM_NAGIGATOR_HEIGHT } from '../../../../constants/my-account-consts';
import {
  ERROR,
  INVITATION_LINK_COPIED,
  SPACE_RESERVE_DELETED,
  SPACE_RESERVE_ERROR,
  SPACE_RESERVE_UPDATED,
  SUCCESS
} from '../../../../constants/snackbar-const';
import env from '../../../../env/env';
import SpaceReservationRequest from '../../../../requests/space-reservation-request';
import { Path } from '../../../../router/Routes';
import { reservationTimeToStr } from '../../../../utils/space-reservation-util';
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../../consumer/ConsumerSwitcher';
import mojaco from './../../../../assets/mojaco/mojaco_break.png';
import ConfirmModal from './ConfirmModal';

interface P {
  base: BaseDto;
  spaceReservation: SpaceReservation;
  user: User;
  onClickBack: () => void;
}

const EventType = 'click';
const EventTarget = 'sub_menu';

const ReservationGrid: React.FC<P> = React.memo(props => {
  const { spaceReservation, user, base, onClickBack } = props;
  const unmountRef = useUnmountRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useSafeState<boolean>(unmountRef, false);
  const [isOpenSubMenu, setIsOpenSubMenu] = useSafeState<boolean>(unmountRef, false);
  // const [isTitleEditing, setIsTitleEditing] = useSafeState<boolean>(unmountRef, false);
  const [isRemarksEditing, setIsRemarksEditing] = useSafeState<boolean>(unmountRef, false);
  // const [title, setTitle] = useSafeState<string>(unmountRef, spaceReservation.reservationName);
  const [remarks, setRemarks] = useSafeState<string>(unmountRef, spaceReservation.remarks);

  const remarksRowCount = useMemo(() => {
    // eslint-disable-next-line no-control-regex
    return (remarks.match(new RegExp('\n', 'g')) || []).length + 1;
  }, [remarks]);

  const isOwner = useMemo<boolean>((): boolean => {
    return (
      !!user &&
      !!spaceReservation &&
      !!spaceReservation.participants.find(
        participant =>
          participant.user.userId === user.userId && participant.participantDiv === SpaceParticipantDiv.OWNER
      )
    );
  }, [spaceReservation, user]);

  const isEditableReservation = useMemo<boolean>((): boolean => {
    if (!base || !spaceReservation) return false;
    const now = new Date();
    const editableDueTime = toUTC(new Date(spaceReservation.startAt))!;

    if (!!base.spaceDueDays) {
      editableDueTime!.setDate(editableDueTime!.getDate() - (base.spaceDueDays - 1));
      editableDueTime!.setHours(ZERO);
      editableDueTime!.setMinutes(ZERO);
      editableDueTime!.setSeconds(ZERO);
    }

    return now < editableDueTime;
  }, [spaceReservation, base]);

  const switchIsOpenSubMenu = useSafeCallback(
    (event: any) => {
      event.target.closest(`.${EventTarget}`) ? setIsOpenSubMenu(true) : setIsOpenSubMenu(false);
    },
    [setIsOpenSubMenu]
  );

  const deleteSpaceReservation = useSafeCallback(async (): Promise<void> => {
    const deleteSpaceReservationRequest = builder<DeleteSpaceReservationRequest>()
      .base(base)
      .spaceReservationId(spaceReservation.spaceReservationId)
      .spacePaticipantIds(spaceReservation.participants.map(participant => participant.spaceParticipantId))
      .user(user)
      .build();

    const deleteSpaceReservationResponse = await SpaceReservationRequest.deleteSpaceReservation(
      deleteSpaceReservationRequest
    );
    const isDeletedSuccessfully = !!deleteSpaceReservationResponse.spaceReservationId;
    enqueueSnackbar(isDeletedSuccessfully ? SPACE_RESERVE_DELETED : SPACE_RESERVE_ERROR, {
      variant: isDeletedSuccessfully ? SUCCESS : ERROR
    });
    onClickBack();
  }, [base, enqueueSnackbar, onClickBack, spaceReservation, user]);

  // const saveTitle = useSafeCallback(async (): Promise<void> => {
  // spaceReservation.reservationName = title;
  // const request = builder<SaveSpaceReservationRequest>()
  //   .base(base)
  //   .user(user)
  //   .spaceReservation(spaceReservation)
  //   .build();

  // const response = await SpaceReservationRequest.saveSpaceReservation(request);
  // const isSavedSuccessfully = response.spaceReservationId;
  // enqueueSnackbar(isSavedSuccessfully ? SPACE_RESERVE_UPDATED : SPACE_RESERVE_ERROR, {
  //   variant: isSavedSuccessfully ? SUCCESS : ERROR
  // });
  // setIsTitleEditing(false);
  // }, [
  //   base,
  //   enqueueSnackbar,
  //   spaceReservation,
  // title,
  // user
  // setIsTitleEditing
  // ]);

  const saveRemarks = useSafeCallback(async (): Promise<void> => {
    spaceReservation.remarks = remarks;
    const request = builder<SaveSpaceReservationRequest>()
      .base(base)
      .user(user)
      .spaceReservation(spaceReservation)
      .build();

    const response = await SpaceReservationRequest.saveSpaceReservation(request);
    const isSavedSuccessfully = response.spaceReservationId;
    enqueueSnackbar(isSavedSuccessfully ? SPACE_RESERVE_UPDATED : SPACE_RESERVE_ERROR, {
      variant: isSavedSuccessfully ? SUCCESS : ERROR
    });
    setIsRemarksEditing(false);
  }, [base, enqueueSnackbar, remarks, spaceReservation, user, setIsRemarksEditing]);

  useEffect(() => {
    document.addEventListener(EventType, switchIsOpenSubMenu);
    return () => {
      document.removeEventListener(EventType, switchIsOpenSubMenu);
    };
  }, [switchIsOpenSubMenu]);

  const spaceReservationURLToShare = useMemo<URL | undefined>(() => {
    const startAt = format(toUTC(new Date(spaceReservation.startAt))!, 'yyyy/MM/dd HH:mm:ss').toString();
    const endAt = format(toUTC(new Date(spaceReservation.endAt))!, 'yyyy/MM/dd HH:mm:ss').toString();

    const queryParamOfSpaceReservationId = `${SHARED_SPACE_RESERVATION_ID}=${spaceReservation.spaceReservationId}`;
    const queryParamOfStartAt = `${SHARED_SPACE_RESERVATION_START_AT}=${startAt}`;
    const queryParamOfEndAt = `${SHARED_SPACE_RESERVATION_END_AT}=${endAt}`;

    const pathToOpen = `${embedIdInPath(
      Path.MY_ACCOUNT,
      [BASE_CODE],
      [base.baseCode]
    )}?${queryParamOfSpaceReservationId}&${queryParamOfStartAt}&${queryParamOfEndAt}`;

    const url = constructExternalLink(env, pathToOpen);
    return url;
  }, [spaceReservation, base]);

  const handleShareClicked = useSafeCallback(async (): Promise<void> => {
    if (!spaceReservationURLToShare) return;

    if (!!navigator.share) {
      await navigator.share({ url: spaceReservationURLToShare });
    } else {
      copyURL(spaceReservationURLToShare);
      enqueueSnackbar(INVITATION_LINK_COPIED, { variant: SUCCESS });
    }
  }, [spaceReservationURLToShare, enqueueSnackbar]);

  return (
    <Component className='reservation-detail'>
      <Container>
        <Content headerHeight={HEADER_HEIGHT} mobileHeaderHeight={MOBILE_HEADER_HEIGHT}>
          <ImageWrapper>
            <Image src={spaceReservation.space.photoURL} />
          </ImageWrapper>
          <DetailWrapper>
            {isOwner && isEditableReservation && (
              <SubMenuWrapper className={EventTarget}>
                <CustomMoreVertIcon onClick={() => setIsOpenSubMenu(true)} />
                {isOpenSubMenu && (
                  <SubMenu>
                    <SubMenuButton onClick={() => setIsOpenConfirmModal(true)}>予定を削除する</SubMenuButton>
                  </SubMenu>
                )}
              </SubMenuWrapper>
            )}
            {/* <FlexCenterRow>
              {isTitleEditing ? (
                <>
                  <CustomInput value={title} onChange={event => setTitle(event.target.value)} />
                  <CustomDoneOutlinedIcon onClick={saveTitle} />
                </>
              ) : (
                <>
                  <TitleLabel>{title}</TitleLabel>
                  {isOwner && <CustomEditOutlinedIcon onClick={() => setIsTitleEditing(true)} />}
                </>
              )}
            </FlexCenterRow> */}
            <FlexRow>
              <CustomRoomOutlinedIcon />
              <Label>{spaceReservation.space.name}</Label>
            </FlexRow>
            <FlexRow>
              <CustomScheduleIcon />
              <Label>
                {reservationTimeToStr(
                  toUTC(new Date(spaceReservation.startAt)),
                  toUTC(new Date(spaceReservation.startAt)),
                  toUTC(new Date(spaceReservation.endAt))
                )}
              </Label>
            </FlexRow>
            <FlexRow>
              <CustomSubjectOutlinedIcon />
              <RemarksWrapper>
                {isRemarksEditing ? (
                  <>
                    <CustomMultiInput
                      minRows={remarksRowCount}
                      multiline
                      value={remarks}
                      onChange={event => setRemarks(event.target.value)}
                    />
                    <CustomDoneOutlinedIcon onClick={saveRemarks} />
                  </>
                ) : (
                  <>
                    {!!remarks ? <Pre>{remarks}</Pre> : <NoPre>質問や要望など</NoPre>}
                    {isOwner && (
                      <EditButton onClick={() => setIsRemarksEditing(true)}>
                        <CustomEditOutlinedIcon />
                        編集する
                      </EditButton>
                    )}
                  </>
                )}
              </RemarksWrapper>
            </FlexRow>
            <FlexRow>
              <CustomPeopleOutlineOutlinedIcon />
              <ParticipantsWrapper>
                <ParticipantsTitle>
                  <GrayLabel>参加者</GrayLabel>
                  <>
                    <ShareButton onClick={handleShareClicked}>
                      <CustomSendOutlinedIcon />
                      招待する
                    </ShareButton>
                  </>
                </ParticipantsTitle>
                <Participants>
                  {spaceReservation.participants.map((participant, idx) => (
                    <Participant key={idx}>
                      <Skeleton style={styleForPhoto} src={participant.user.photoURL || mojaco} />
                      <GrayLabel>
                        {participant.user.familyName}
                        {participant.user.firstName}
                      </GrayLabel>
                    </Participant>
                  ))}
                </Participants>
              </ParticipantsWrapper>
            </FlexRow>
            <BackButtonWrapper>
              <Button type='secondary' onClick={onClickBack}>
                <ButtonLabel>戻る</ButtonLabel>
              </Button>
            </BackButtonWrapper>
          </DetailWrapper>
        </Content>
        <ConfirmModal
          isOpen={isOpenConfirmModal}
          onClick={deleteSpaceReservation}
          onClose={() => setIsOpenConfirmModal(false)}
        />
      </Container>
    </Component>
  );
});

export default ReservationGrid;

const Container = styled.div`
  width: 100%;
  height: auto;
`;
const Content = styled.div`
  width: 100%;
  min-height: calc(100vh - ${({ headerHeight }) => headerHeight || 0}px);
  background: ${theme.mixins.background.white};
  padding-bottom: ${BOTTOM_NAGIGATOR_HEIGHT || 0}px;

  ${media.lessThan('small')`
  min-height: calc(100vh - ${({ mobileHeaderHeight }) => mobileHeaderHeight || 0}px);
  `}
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 226px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const DetailWrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px;
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing}px;
  position: relative;
`;
const SubMenuWrapper = styled.div`
  position: absolute;
  top: ${theme.mixins.spacing}px;
  right: ${theme.mixins.spacing}px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const SubMenu = styled(Paper)`
  padding: ${theme.mixins.spacing}px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
`;
const SubMenuButton = styled.div`
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
`;
// const FlexCenterRow = styled.div`
//   display: flex;
//   gap: ${theme.mixins.spacing / 2}px;
//   align-items: center;
// `;
const FlexRow = styled.div`
  display: flex;
  gap: ${theme.mixins.spacing / 2}px;
`;
const RemarksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.mixins.spacing / 2}px;
  flex-grow: 1;
`;

const Label = styled.label`
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-size: 14px;
  margin: 0;
`;
const ParticipantsTitle = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${theme.mixins.spacing / 2}px;
  flex-grow: 1;
`;

const GrayLabel = styled(Label)`
  color: ${theme.mixins.typography.fontColor.gray};
`;
const ButtonLabel = styled(Label)`
  padding: 0 ${theme.mixins.spacing * 4}px;
`;

// const TitleLabel = styled.label`
//   font-family: ${theme.mixins.typography.fontFamily};
//   font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
//   font-size: ${theme.mixins.typography.fontSize.sixteen}px;
//   margin: 0;
// `;
// const CustomInput = styled(TextField)`
//   width: 80%;
//   input {
//     font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
//     font-size: ${theme.mixins.typography.fontSize.sixteen}px;
//     border-bottom: 1px solid ${theme.mixins.border.lightGray};
//   }
// `;

const CustomMultiInput = styled(TextField)`
  color: ${theme.mixins.typography.fontColor.gray};
  width: 100%;

  > div {
    padding: 0;
  }

  textarea {
    font-size: 14px;
    font-family: ${theme.mixins.typography.fontFamily};
    border-radius: 8px;
    border: 1px solid ${theme.mixins.border.lightGray};
    padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing}px;
  }
`;

const Pre = styled.pre`
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: 14px;
  line-height: 18px;
  overflow-y: hidden;
  margin: 0;
`;
const NoPre = styled.pre`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: 14px;
  line-height: 18px;
  overflow-y: hidden;
  margin: 0px 0px 0px ${theme.mixins.spacing}px;
`;
const EditButton = styled.div`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: 14px;
  line-height: 18px;
  overflow-y: hidden;
  margin: 0px 0px 0px ${theme.mixins.spacing}px;
`;
const ShareButton = styled.div`
  color: ${theme.mixins.typography.fontColor.lightBlue};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: 14px;
  line-height: 18px;
  overflow-y: hidden;
  margin: 0px 0px 0px ${theme.mixins.spacing}px;
`;
const ParticipantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing}px;
`;
const Participants = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;
const Participant = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${theme.mixins.spacing}px;
  * {
    cursor: pointer;
  }
`;
const CustomDoneOutlinedIcon = styled(DoneOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;
const CustomMoreVertIcon = styled(MoreVertIcon)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  margin ${theme.mixins.spacing / 2}px;
`;
const CustomEditOutlinedIcon = styled(EditOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  padding-right: ${theme.mixins.spacing / 2}px;
`;
const CustomSendOutlinedIcon = styled(SendIcon)`
  color: ${theme.mixins.typography.fontColor.lightBlue};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  padding-right: ${theme.mixins.spacing / 2}px;
`;
const CustomScheduleIcon = styled(ScheduleIcon)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;
const CustomRoomOutlinedIcon = styled(RoomOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;
const CustomSubjectOutlinedIcon = styled(SubjectOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;
const CustomPeopleOutlineOutlinedIcon = styled(PeopleOutlineOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;

const styleForPhoto: CSSProperties = {
  width: 72,
  height: 72,
  transform: 'none',
  background: theme.mixins.background.lightGray,
  border: `2px solid ${theme.mixins.border.lightGray}`,
  borderRadius: '50%',
  objectFit: 'contain'
};
