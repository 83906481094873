import { ContractPaymentMethod, InflowSource, PaymentType, UseAddress } from '@atomica.co/irori';
import { Labels } from '../models/common-model';

export const CONTRACT_PAYMENT_METHOD_LABELS: Labels = {
  [ContractPaymentMethod.CREDIT_CARD]: 'クレジットカード',
  [ContractPaymentMethod.BANK_TRANSFER]: '銀行振り込み',
  [ContractPaymentMethod.CASH]: '現金'
};

export const CONTRACT_PAYMENT_TYPE_LABELS: Labels = {
  [PaymentType.SINGLE]: '一括',
  [PaymentType.INSTALLMENTS]: '分割'
};

export const CONTRACT_USE_ADDRESS_LABELS: Labels = {
  [UseAddress.NO]: 'なし',
  [UseAddress.YES]: 'あり'
};

export const CONTRACT_INFLOW_SOURCE_LABELS: Labels = {
  [InflowSource.DIRECT]: '直営業',
  [InflowSource.ORGANIC]: '自然流入',
  [InflowSource.SHOT]: 'ショット会員'
};
