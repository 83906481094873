import {
  CommentBox,
  InputBox,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  MultiCheckBox,
  MultiCheckBoxOption,
  QRCode,
  RadioBox,
  Scrollable,
  SelectBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Base, BASE_CODE, Shot, UsageType, User } from '@atomica.co/irori';
import {
  Count,
  EMPTY,
  hasLength,
  Message,
  Name,
  Number,
  ONE,
  Option,
  removeBreaks,
  URL,
  uuid
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import {
  BaseInfo,
  ConferenceUsageType,
  showConferenceOneDayUsage,
  showUsageOption,
  toConferenceUsageTypeLabels,
  toConferenceUsageTypes,
  toUsageOptionLabels,
  toUsageOptions,
  toUsageTypes,
  UsageOption,
  USAGE_TYPE_LABELS
} from '../../constants/base-config';
import { NO_BASE } from '../../constants/error-message-const';
import { returnBaseOnlyIfExisted, toShot } from '../../converters/shot-usage-converter';
import { Hour, NumberOfPeople } from '../../enums/common-enum';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import ConferenceService from '../../services/conference-service';
import { HOURS_LABELS, NUMBER_OF_PEOPLE_LABELS } from '../../texts/common-text';
import { getReceptionURL } from '../../utils/path-util';
import mojaco_greeting from './../../assets/mojaco/mojaco_greeting.png';

export const toOption = (conferenceOneDayUsage: boolean): MultiCheckBoxOption[] => {
  return [
    {
      id: 'conference-one-day-usage',
      label: '1DAYも利用する',
      checked: conferenceOneDayUsage
    }
  ];
};

const toQRCode = (receptionURL: URL): React.ReactNode => {
  return <QRCode size={240} color={theme.mixins.background.yellow} url={receptionURL} />;
};

interface P extends RouteComponentProps {}

const InputShotScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { params } = usePath();
  const { getUser } = useUser();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef);
  const [signInUser, setSignInUser] = useSafeState<User>(unmountRef);
  const [base, setBase] = useSafeState<Base>(unmountRef);
  const [shotUsage, setShotUsage] = useSafeState<Shot>(unmountRef);
  const [usageType, setUsageType] = useSafeState<UsageType>(unmountRef, EMPTY as UsageType);
  const usageOptions = useMemo<Option[]>(() => toUsageOptions(params[BASE_CODE], usageType), [params, usageType]);
  const [usageOption, setUsageOption] = useSafeState<UsageOption>(unmountRef, EMPTY as UsageOption);
  const [cardNo, setCardNo] = useSafeState<Number>(unmountRef, EMPTY);
  const conferenceUsageTypes = useMemo<Option[]>(() => toConferenceUsageTypes(params[BASE_CODE]), [params]);
  const [conferenceUsageType, setConferenceUsageType] = useSafeState<ConferenceUsageType>(
    unmountRef,
    EMPTY as ConferenceUsageType
  );
  const [conferenceUsageHours, setConferenceUsageHours] = useSafeState<Hour>(unmountRef, Hour.ONE);
  const [conferenceNumberOfPeople, setConferenceNumberOfPeople] = useSafeState<Count>(unmountRef, ONE);
  const [conferenceOneDayUsage, setConferenceOneDayUsage] = useSafeState<boolean>(unmountRef, false);
  const [conferenceOneDayNumberOfPeople, setConferenceOneDayNumberOfPeople] = useSafeState<Count>(unmountRef, ONE);
  const [receiptName, setReceiptName] = useSafeState<Name>(unmountRef, EMPTY);

  const isConferenceGuest = useMemo<boolean>(() => {
    return ConferenceService.returnEligibleIfConferenceRoomReserved(usageType, conferenceUsageType, false, true);
  }, [usageType, conferenceUsageType]);

  const receptionURL = useMemo<URL>(() => {
    const url = getReceptionURL(shotUsage, base);
    console.log(url);
    return url;
  }, [shotUsage, base]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const base = returnBaseOnlyIfExisted(params[BASE_CODE]);

    if (!base) {
      setErrorMessage(NO_BASE);
      return;
    }

    const signInUser = (await getUser()) as User;

    setBase(base);
    setSignInUser(signInUser);
    setLoaded(true);
  }, [params, setErrorMessage, getUser, setBase, setSignInUser, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (!hasLength(usageOptions)) return;
    setUsageOption(usageOptions[0] as UsageOption);
  }, [usageOptions, setUsageOption]);

  useEffect(() => {
    setShotUsage(prevShotUsage => {
      if (!signInUser) return prevShotUsage;

      const shotUsageToUpdate = toShot(
        !!prevShotUsage ? prevShotUsage.shotId : uuid(),
        usageType,
        base,
        signInUser,
        usageOption,
        conferenceUsageType,
        conferenceUsageHours,
        conferenceNumberOfPeople,
        conferenceOneDayUsage,
        conferenceOneDayNumberOfPeople,
        receiptName,
        cardNo
      );

      return shotUsageToUpdate;
    });
  }, [
    setShotUsage,
    usageType,
    base,
    signInUser,
    conferenceUsageType,
    conferenceUsageHours,
    conferenceNumberOfPeople,
    conferenceOneDayUsage,
    conferenceOneDayNumberOfPeople,
    usageOption,
    receiptName,
    cardNo
  ]);

  // const openGuidance = useSafeCallback((): void => {
  //   window.open(GUIDANCE_URL[base], 'newtab');
  // }, [base]);

  return (
    <Screen loading={!loaded} errorMsg={errorMessage} className='input-shot-screen'>
      <Container>
        <Content>
          <Scrollable>
            <MojacoWrapper>
              <CommentBox animation photoURL={mojaco_greeting}>
                <Greeting>
                  {!!BaseInfo[base] ? `${removeBreaks(BaseInfo[base].name)}へようこそ！` : EMPTY}
                  利用する項目を選んでね！1日利用の場合は「1DAY利用」を選んでね！
                </Greeting>
              </CommentBox>
            </MojacoWrapper>

            <RadioWrapper>
              <RadioBox
                title='本日のご利用内容'
                value={usageType}
                options={toUsageTypes(base)}
                labels={USAGE_TYPE_LABELS}
                onChange={setUsageType}
              />
            </RadioWrapper>

            {showUsageOption(usageType) && (
              <>
                {usageOptions.length > ONE && (
                  <>
                    <MojacoWrapper>
                      <CommentBox animation photoURL={mojaco_greeting}>
                        <Greeting>該当するものがあれば選んでね！</Greeting>
                      </CommentBox>
                    </MojacoWrapper>

                    <RadioWrapper>
                      <RadioBox
                        title='該当するものをお選びください'
                        options={usageOptions}
                        labels={toUsageOptionLabels(base, usageType)}
                        value={usageOption}
                        onChange={setUsageOption}
                      />
                    </RadioWrapper>
                  </>
                )}

                <MojacoWrapper>
                  <CommentBox animation photoURL={mojaco_greeting}>
                    <Greeting>領収書が必要な場合は宛名を記入してね！</Greeting>
                  </CommentBox>
                </MojacoWrapper>

                <InputWrapper>
                  <InputBox type='text' label='領収書の宛名' text={receiptName} onChange={setReceiptName} />
                </InputWrapper>

                <MojacoWrapper>
                  <CommentBox animation photoURL={mojaco_greeting}>
                    <Greeting>スタッフから貸出カードを受け取ったら、その番号を入力してね！</Greeting>
                  </CommentBox>
                </MojacoWrapper>

                <InputWrapper>
                  <InputBox
                    required={false}
                    maxLength={10}
                    type='number'
                    label='貸出カードNo.'
                    text={cardNo}
                    onChange={setCardNo}
                  />
                </InputWrapper>

                <MojacoWrapper>
                  <CommentBox animation photoURL={mojaco_greeting}>
                    <Greeting>
                      入力ありがとう！このQRコードをスタッフに見せてね！QRの読み取りが終わったら入館登録は完了だよ！そうしたらこの画面は閉じても大丈夫！
                    </Greeting>
                  </CommentBox>
                </MojacoWrapper>

                <QRCodeWrapper>{toQRCode(receptionURL)}</QRCodeWrapper>

                {/* <MojacoWrapper>
                      <CommentBox
                        animation
                        photoURL={mojaco_greeting}
                      >
                        <Greeting>
                          受付が混み合っている場合は、この施設案内を読んで待っててね！
                        </Greeting>
                      </CommentBox>
                    </MojacoWrapper>

                    <ButtonWrapper>
                      <Button
                        type="primary"
                        width={144}
                        label="施設案内はコチラ"
                        onClick={openGuidance}
                      />
                    </ButtonWrapper> */}
              </>
            )}

            {usageType === UsageType.CONFERENCE && (
              <>
                <MojacoWrapper>
                  <CommentBox animation photoURL={mojaco_greeting}>
                    <Greeting>
                      会議室の申込者は申し込んだ会議室を選んでね！申込者以外は「ゲスト利用」を選んでね！
                    </Greeting>
                  </CommentBox>
                </MojacoWrapper>

                <RadioWrapper>
                  <RadioBox
                    title='会議室'
                    options={conferenceUsageTypes}
                    labels={toConferenceUsageTypeLabels(base)}
                    value={conferenceUsageType}
                    onChange={setConferenceUsageType}
                  />
                </RadioWrapper>

                {!!conferenceUsageType && !isConferenceGuest && (
                  <>
                    <MojacoWrapper>
                      <CommentBox animation photoURL={mojaco_greeting}>
                        <Greeting>利用時間と人数を選んでね！</Greeting>
                      </CommentBox>
                    </MojacoWrapper>

                    <SelectWrapper>
                      <SelectBox
                        title='利用時間'
                        options={Object.values(Hour)}
                        labels={HOURS_LABELS}
                        value={conferenceUsageHours}
                        onChange={setConferenceUsageHours}
                      />
                    </SelectWrapper>

                    <SelectWrapper>
                      <SelectBox
                        title='利用人数'
                        options={Object.values(NumberOfPeople)}
                        labels={NUMBER_OF_PEOPLE_LABELS}
                        value={conferenceNumberOfPeople}
                        onChange={setConferenceNumberOfPeople}
                      />
                    </SelectWrapper>

                    {showConferenceOneDayUsage(base) && (
                      <>
                        <MojacoWrapper>
                          <CommentBox animation photoURL={mojaco_greeting}>
                            <Greeting>会議室の利用以外に1DAYも利用する場合は、チェックを入れてね！</Greeting>
                          </CommentBox>
                        </MojacoWrapper>

                        <CheckWrapper>
                          <MultiCheckBox
                            title='1DAYの併用有無'
                            options={toOption(conferenceOneDayUsage)}
                            onChange={options => setConferenceOneDayUsage(options[0].checked)}
                          />
                        </CheckWrapper>
                      </>
                    )}

                    {!!conferenceOneDayUsage && (
                      <>
                        <MojacoWrapper>
                          <CommentBox animation photoURL={mojaco_greeting}>
                            <Greeting>1DAYを利用する人数を選んでね！</Greeting>
                          </CommentBox>
                        </MojacoWrapper>

                        <SelectWrapper>
                          <SelectBox
                            title='1DAYの利用人数'
                            options={Object.values(NumberOfPeople)}
                            labels={NUMBER_OF_PEOPLE_LABELS}
                            value={conferenceOneDayNumberOfPeople}
                            onChange={setConferenceOneDayNumberOfPeople}
                          />
                        </SelectWrapper>
                      </>
                    )}

                    <MojacoWrapper>
                      <CommentBox animation photoURL={mojaco_greeting}>
                        <Greeting>領収書が必要な場合は宛名を記入してね！</Greeting>
                      </CommentBox>
                    </MojacoWrapper>

                    <InputWrapper>
                      <InputBox type='text' label='領収書の宛名' text={receiptName} onChange={setReceiptName} />
                    </InputWrapper>

                    <MojacoWrapper>
                      <CommentBox animation photoURL={mojaco_greeting}>
                        <Greeting>スタッフから貸出カードを受け取ったら、その番号を入力してね！</Greeting>
                      </CommentBox>
                    </MojacoWrapper>

                    <InputWrapper>
                      <InputBox
                        required={false}
                        maxLength={10}
                        type='number'
                        label='貸出カードNo.'
                        text={cardNo}
                        onChange={setCardNo}
                      />
                    </InputWrapper>

                    <MojacoWrapper>
                      <CommentBox animation photoURL={mojaco_greeting}>
                        <Greeting>
                          入力ありがとう！このQRコードをスタッフに見せてね！QRの読み取りが終わったら入館登録は完了だよ！そうしたらこの画面は閉じても大丈夫！
                        </Greeting>
                      </CommentBox>
                    </MojacoWrapper>

                    <QRCodeWrapper>{toQRCode(receptionURL)}</QRCodeWrapper>

                    {/* <MojacoWrapper>
                      <CommentBox
                        animation
                        photoURL={mojaco_greeting}
                      >
                        <Greeting>
                          受付が混み合っている場合は、この施設案内を読んで待っててね！
                        </Greeting>
                      </CommentBox>
                    </MojacoWrapper>

                    <ButtonWrapper>
                      <Button
                        width={144}
                        type="primary"
                        label="施設案内はコチラ"
                        onClick={openGuidance}
                      />
                    </ButtonWrapper> */}
                  </>
                )}

                {!!conferenceUsageType && isConferenceGuest && (
                  <>
                    <MojacoWrapper>
                      <CommentBox animation photoURL={mojaco_greeting}>
                        <Greeting>スタッフから貸出カードを受け取ったら、その番号を入力してね！</Greeting>
                      </CommentBox>
                    </MojacoWrapper>

                    <InputWrapper>
                      <InputBox
                        required={false}
                        maxLength={10}
                        type='number'
                        label='貸出カードNo.'
                        text={cardNo}
                        onChange={setCardNo}
                      />
                    </InputWrapper>

                    <MojacoWrapper>
                      <CommentBox animation photoURL={mojaco_greeting}>
                        <Greeting>
                          入力ありがとう！このQRコードをスタッフに見せてね！QRの読み取りが終わったら入館登録は完了だよ！そうしたらこの画面は閉じても大丈夫！
                        </Greeting>
                      </CommentBox>
                    </MojacoWrapper>

                    <QRCodeWrapper>{toQRCode(receptionURL)}</QRCodeWrapper>

                    {/* <MojacoWrapper>
                      <CommentBox
                        animation
                        photoURL={mojaco_greeting}
                      >
                        <Greeting>
                          受付が混み合っている場合は、この施設案内を読んで待っててね！
                        </Greeting>
                      </CommentBox>
                    </MojacoWrapper>

                    <ButtonWrapper>
                      <Button
                        width={144}
                        type="primary"
                        label="施設案内はコチラ"
                        onClick={openGuidance}
                      />
                    </ButtonWrapper> */}
                  </>
                )}
              </>
            )}

            {(usageType === UsageType.COWORKING ||
              usageType === UsageType.EVENT ||
              usageType === UsageType.GUEST ||
              usageType === UsageType.PREVIEW) && (
              <>
                <MojacoWrapper>
                  <CommentBox animation photoURL={mojaco_greeting}>
                    <Greeting>スタッフから貸出カードを受け取ったら、その番号を入力してね！</Greeting>
                  </CommentBox>
                </MojacoWrapper>

                <InputWrapper>
                  <InputBox
                    required={false}
                    maxLength={10}
                    type='number'
                    label='貸出カードNo.'
                    text={cardNo}
                    onChange={setCardNo}
                  />
                </InputWrapper>

                <MojacoWrapper>
                  <CommentBox animation photoURL={mojaco_greeting}>
                    <Greeting>
                      入力ありがとう！このQRコードをスタッフに見せてね！QRの読み取りが終わったら入館登録は完了だよ！そうしたらこの画面は閉じても大丈夫！
                    </Greeting>
                  </CommentBox>
                </MojacoWrapper>

                <QRCodeWrapper>{toQRCode(receptionURL)}</QRCodeWrapper>

                {/* <MojacoWrapper>
                  <CommentBox
                    animation
                    photoURL={mojaco_greeting}
                  >
                    <Greeting>
                      受付が混み合っている場合は、この施設案内を読んで待っててね！
                    </Greeting>
                  </CommentBox>
                </MojacoWrapper>

                <ButtonWrapper>
                  <Button
                    width={144}
                    type="primary"
                    label="施設案内はコチラ"
                    onClick={openGuidance}
                  />
                </ButtonWrapper> */}
              </>
            )}

            <BottomArea />
          </Scrollable>
        </Content>
      </Container>
    </Screen>
  );
});

export default InputShotScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  height: 100%;
  scroll-behavior: smooth;
  margin-bottom: ${window.innerHeight / 2}px;
`;

const MojacoWrapper = styled.div`
  margin: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const Greeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const RadioWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 4}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 4}px;
`;

const SelectWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 4}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const InputWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 4}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 4}px;
`;

const CheckWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 4}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const QRCodeWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 8}px);
  height: auto;
  display: flex;
  justify-content: center;
  margin: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 8}px;
`;

const BottomArea = styled.div`
  width: 100%;
  height: 120px;
`;
