import { DOMAINS } from '@atomica.co/irori';
import { Code, embedIdInPath, URL } from '@atomica.co/utils';
import liff from '@line/liff';
import querystring from 'querystring';
import env from '../env/env';
import { Path, PATH_IDS } from '../router/Routes';
import { IRORI_CLIENT_ID, IRORI_CLIENT_SECRET, LIFF_ID, LINE_AUTHORIZE_PARAMS, LINE_AUTHORIZE_URL } from './config';

export const constructLineLoginURL = (baseCode: Code): URL =>
  LINE_AUTHORIZE_URL +
  `?${querystring.stringify(LINE_AUTHORIZE_PARAMS)}` +
  '&redirect_uri=' +
  `https://${DOMAINS[env]}${embedIdInPath(Path.SIGN_IN, PATH_IDS, [baseCode])}`;

export const getLineTokenBody = (lineCode: Code, baseCode: Code) => {
  return {
    grant_type: 'authorization_code',
    code: lineCode,
    redirect_uri: `https://${DOMAINS[env]}${embedIdInPath(Path.SIGN_IN, PATH_IDS, [baseCode])}`,
    client_id: IRORI_CLIENT_ID,
    client_secret: IRORI_CLIENT_SECRET
  };
};

export const getLiff = async () => {
  await liff.init({ liffId: LIFF_ID });
  return liff;
};
