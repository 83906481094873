import { CommentBox, Component, theme } from '@atomica.co/components';
import { EventParticipant } from '@atomica.co/irori';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { toFullName } from '../../../../utils/user-util';

interface P {
  participant: EventParticipant;
}

const ParticipantCard: React.FC<P> = React.memo(props => {
  const { participant } = props;

  return (
    <Component className='participant-card'>
      <Container>
        <CommentBox animation photoURL={participant.user.photoURL!}>
          <Wrapper>
            <UserName>{toFullName(participant.user)}</UserName>

            <Comment>{participant.comment}</Comment>
          </Wrapper>
        </CommentBox>
      </Container>
    </Component>
  );
});

export default ParticipantCard;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const UserName = styled(Typography)`
  width: 100%;
  height: 40px;
  border-bottom: solid 1px ${theme.mixins.border.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Comment = styled(Typography)`
  width: 100%;
  height: auto;
  min-height: 18px;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  justify-content: flex-start;
  word-break: break-all;
  white-space: pre-wrap;
  padding-bottom: ${theme.mixins.spacing}px;
`;
