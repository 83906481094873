import {
  CardDeckRef,
  CircularLoader,
  Component,
  theme,
  Tips,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AuthorityDefCodeEnum,
  BaseDto,
  CreateQrCodeForAllSpacesRequest,
  CreateQrCodeForContractedSpacesRequest,
  CreateQrCodesForEachSpaceReservationRequest,
  FetchSpaceReservationsByUserRequest,
  QrCodeSpaceReservationData,
  SpaceReservation,
  User
} from '@atomica.co/irori';
import { builder, Code, hasLength, QrCodeBuffer, toEndOfDay } from '@atomica.co/utils';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import React, { useEffect, useMemo, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import QrCodeRequest from '../../../requests/qrcode-request';
import SpaceReservationRequest from '../../../requests/space-reservation-request';
import { convertToSpaceReservationCards, SpaceReservationCard } from './../../../converters/my-account-converter';
import ReservationCards from './reservation-card/ReservationCards';

const TIPS_MESSAGE =
  '本日の予定はありません。\n会議室の予約をするか\n会議室の予約の招待を受け取ると\n本日の予定が表示されます。';

interface P extends RouteComponentProps {
  authorityDefCode: Code | null;
  user: User;
  base: BaseDto;
}

const MyAccountHome: React.FC<P> = React.memo(props => {
  const { authorityDefCode, base, user } = props;
  const ref = useRef<CardDeckRef>(null);
  const unmountRef = useUnmountRef();
  const [isLoading, setIsLoading] = useSafeState<boolean>(unmountRef, true);
  const [spaceReservationCards, setSpaceReservationCards] = useSafeState<SpaceReservationCard[]>(unmountRef, []);

  // const isCreateQrCodeForAllSpaces = useMemo<boolean>(() => {
  //   return (
  //     authorityDefCode === AuthorityDefCodeEnum.ADMIN ||
  //     authorityDefCode === AuthorityDefCodeEnum.FULL_CONDERENCE_ACCESS
  //   );
  // }, [authorityDefCode]);

  const isCreateQrCodeForContractedSpaces = useMemo<boolean>(() => {
    return authorityDefCode === AuthorityDefCodeEnum.FACE_ACCESS;
  }, [authorityDefCode]);

  const fetchSpaceReservations = useSafeCallback(async (): Promise<SpaceReservation[]> => {
    const fromDate = new Date();
    const toDate = toEndOfDay(new Date())!;
    const request = builder<FetchSpaceReservationsByUserRequest>()
      .base(base)
      .user(user)
      .fromDate(fromDate)
      .toDate(toDate)
      .build();
    const response = await SpaceReservationRequest.fetchSpaceReservationsByUser(request);
    return response.spaceReservations;
  }, [base, user]);

  const callCreateQrCodeForAllSpaces = useSafeCallback(async (): Promise<QrCodeBuffer | undefined> => {
    // if (!isCreateQrCodeForAllSpaces) return;
    const request = builder<CreateQrCodeForAllSpacesRequest>().base(base).user(user).build();
    const response = await QrCodeRequest.createQrCodeForAllSpaces(request);
    return response.qrcode;
  }, [
    base,
    // isCreateQrCodeForAllSpaces,
    user
  ]);

  const callCreateQrCodeForContractedSpaces = useSafeCallback(async (): Promise<QrCodeBuffer | undefined> => {
    if (!isCreateQrCodeForContractedSpaces) return;
    const request = builder<CreateQrCodeForContractedSpacesRequest>().base(base).user(user).build();
    const response = await QrCodeRequest.createQrCodeForContractedSpaces(request);
    return response.qrcode;
  }, [base, isCreateQrCodeForContractedSpaces, user]);

  const callCreateQrCodesForEachSpaceReservation = useSafeCallback(
    async (spaceReservations: SpaceReservation[]): Promise<QrCodeSpaceReservationData[]> => {
      if (!hasLength(spaceReservations)) return [];
      const request = builder<CreateQrCodesForEachSpaceReservationRequest>()
        .user(user)
        .spaceReservations(spaceReservations)
        .build();
      const response = await QrCodeRequest.createQrCodesForEachSpaceReservation(request);
      return response.qrcodes;
    },
    [user]
  );

  const initialize = useSafeCallback(async () => {
    const spaceReservationsPromise = fetchSpaceReservations();
    const [spaceReservations] = await Promise.all([spaceReservationsPromise]);

    const qrCodeForAllSpacesPromise = callCreateQrCodeForAllSpaces();
    const qrCodeForContractedSpacesPromise = callCreateQrCodeForContractedSpaces();
    const qrCodesForEachSpaceReservationPromise = callCreateQrCodesForEachSpaceReservation(spaceReservations);

    const [qrCodeForAllSpaces, qrCodeForContractedSpaces, qrCodesForEachSpaceReservation] = await Promise.all([
      qrCodeForAllSpacesPromise,
      qrCodeForContractedSpacesPromise,
      qrCodesForEachSpaceReservationPromise
    ]);

    setSpaceReservationCards(
      convertToSpaceReservationCards(
        user,
        base,
        qrCodeForAllSpaces,
        qrCodeForContractedSpaces,
        qrCodesForEachSpaceReservation,
        spaceReservations
      )
    );

    setIsLoading(false);
  }, [
    base,
    user,
    callCreateQrCodeForAllSpaces,
    callCreateQrCodeForContractedSpaces,
    callCreateQrCodesForEachSpaceReservation,
    fetchSpaceReservations,
    setIsLoading,
    setSpaceReservationCards
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component className='my-account-home'>
      <Container>
        {isLoading ? (
          <LoaderWrapper>
            <CircularLoader />
          </LoaderWrapper>
        ) : hasLength(spaceReservationCards) ? (
          <>
            <LabelWrapper>
              <CustomCalendarTodayIcon />
              <Label>本日の予定</Label>
            </LabelWrapper>

            <ReservationsWrapper>
              <ReservationCards showCompleted ref={ref} spaceReservationCards={spaceReservationCards} />
            </ReservationsWrapper>
          </>
        ) : (
          <NoReservationWrapper>
            <Tips message={TIPS_MESSAGE} />
          </NoReservationWrapper>
        )}
      </Container>
    </Component>
  );
});

export default MyAccountHome;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const LoaderWrapper = styled.div`
  width: min-content;
  height: min-content;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 6}px ${theme.mixins.spacing / 2}px;
`;
const CustomCalendarTodayIcon = styled(CalendarTodayIcon)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
`;
const Label = styled.label`
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  padding-left: ${theme.mixins.spacing / 2}px;
  margin: 0;
`;

const ReservationsWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const NoReservationWrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${theme.mixins.spacing * 15}px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
