import { Component, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseDto } from '@atomica.co/irori';
import { Email, EMPTY } from '@atomica.co/utils';
import React from 'react';
import ConfirmationEmailSent from './confirmation-email-sent/ConfirmationEmailSent';
import SignUpOptionList from './sign-up-option-list/SignUpOptionList';

export enum SignUpStatus {
  SELECT_SIGN_UP_OPTION,
  CONFIRMATION_EMAIL_SENT
}

interface P {
  base: BaseDto | undefined;
}

const SignUp: React.FC<P> = React.memo(props => {
  const { base } = props;
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<SignUpStatus>(unmountRef, SignUpStatus.SELECT_SIGN_UP_OPTION);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);

  const showEmailSentMessage = useSafeCallback(
    (email: Email): void => {
      setEmail(email);
      setIndex(SignUpStatus.CONFIRMATION_EMAIL_SENT);
    },
    [setEmail, setIndex]
  );

  return (
    <Component className='sign-up'>
      {index === SignUpStatus.SELECT_SIGN_UP_OPTION && (
        <SignUpOptionList base={base} onSendEmail={showEmailSentMessage} />
      )}

      {index === SignUpStatus.CONFIRMATION_EMAIL_SENT && <ConfirmationEmailSent email={email} />}
    </Component>
  );
});

export default SignUp;
