import {
  ACCESS_ACCESS_DATE,
  ACCESS_ACCESS_TYPE,
  ACCESS_ID,
  BASE,
  CONTRACT_BILLING_AMOUNT,
  CONTRACT_CONTRACT_NAME,
  CONTRACT_CONTRACT_PLAN,
  CONTRACT_CONTRACT_STATUS,
  CONTRACT_DISCOUNT_AMOUNT,
  CONTRACT_DISCOUNT_MONTHS,
  CONTRACT_END_DATE,
  CONTRACT_ID,
  CONTRACT_INFLOW_SOURCE,
  CONTRACT_INIT_DISCOUNT_AMOUNT,
  CONTRACT_NUMBER_OF_KEYS,
  CONTRACT_NUMBER_OF_LOCKERS,
  CONTRACT_PAYMENT_METHOD,
  CONTRACT_PAYMENT_TYPE,
  CONTRACT_REMARKS,
  CONTRACT_REPRESENTATIVE_EMAIL,
  CONTRACT_REPRESENTATIVE_NAME,
  CONTRACT_SERVICE_RETAINER,
  CONTRACT_START_DATE,
  CONTRACT_TAX_EXCLUDED_ADDRESS_PRICE,
  CONTRACT_TAX_EXCLUDED_KEY_ISSUE_PRICE,
  CONTRACT_TAX_EXCLUDED_LOCKER_PRICE,
  CONTRACT_TAX_EXCLUDED_PLAN_PRICE,
  CONTRACT_TAX_INCLUDED_ADDRESS_PRICE,
  CONTRACT_TAX_INCLUDED_KEY_ISSUE_PRICE,
  CONTRACT_TAX_INCLUDED_LOCKER_PRICE,
  CONTRACT_TAX_INCLUDED_PLAN_PRICE,
  CONTRACT_TAX_INCLUDED_TOTAL_AMOUNT,
  CONTRACT_USAGE_CONTRACT_MSEC,
  CONTRACT_USAGE_ID,
  CONTRACT_USAGE_REMAINING_MSEC,
  CONTRACT_USAGE_USAGE_MSEC,
  CONTRACT_USAGE_YEAR_AND_MONTH,
  CONTRACT_USE_ADDRESS,
  EVENT_PARTICIPANT_COMMENT,
  EVENT_PARTICIPANT_EVENT_PARTICIPANT_ID,
  EVENT_PARTICIPANT_INFLOW_SOURCE,
  EVENT_PARTICIPANT_REMARKS,
  EVENT_SCHEDULE_END_AT,
  EVENT_SCHEDULE_NAME,
  EVENT_SCHEDULE_START_AT,
  SHOT_CARD_NO,
  SHOT_CONFERENCE_NUMBER_OF_PEOPLE,
  SHOT_CONFERENCE_ONE_DAY_NUMBER_OF_PEOPLE,
  SHOT_CONFERENCE_ONE_DAY_USAGE,
  SHOT_CONFERENCE_USAGE_HOURS,
  SHOT_CONFERENCE_USAGE_TYPE,
  SHOT_ID,
  SHOT_OPTION_QUANTITY,
  SHOT_PAYMENT_METHOD,
  SHOT_PERSON_IN_CHARGE,
  SHOT_RECEIPT_NAME,
  SHOT_REMARKS,
  SHOT_TAX_EXCLUDED_OPTION_PRICE,
  SHOT_TAX_EXCLUDED_TOTAL_PRICE,
  SHOT_TAX_EXCLUDED_UNIT_PRICE,
  SHOT_TAX_INCLUDED_OPTION_PRICE,
  SHOT_TAX_INCLUDED_TOTAL_PRICE,
  SHOT_TAX_INCLUDED_UNIT_PRICE,
  SHOT_TOTAL_PRICE,
  SHOT_UNIT_QUANTITY,
  SHOT_USAGE_OPTION,
  SHOT_USAGE_TYPE,
  USER_ADDRESS,
  USER_CATEGORY,
  USER_COMPANY_NAME,
  USER_DATE_OF_BIRTH,
  USER_EMAIL,
  USER_FAMILY_NAME,
  USER_FIRST_NAME,
  USER_INFLOW_SOURCE,
  USER_PHONE,
  USER_POSTAL_CODE,
  USER_PREFECTURE
} from '@atomica.co/irori';
import { CREATED_AT } from '@atomica.co/utils';
import { ExportTarget } from '../enums/export-enum';
import { CSVTemplate, Labels } from '../models/common-model';

export const EXPORT_LABELS: Labels = {
  [ExportTarget.SHOT_USAGE]: 'ドロップイン利用履歴',
  [ExportTarget.CONTRACT]: '契約情報',
  // [ExportTarget.CONTRACT_USAGE]: '契約利用状況',
  [ExportTarget.ACCESS]: '入退室履歴'
  // [ExportTarget.MEETAT_PARTICIPANT]: 'MEET@参加者'
};

export const SHOT_HEADERS: CSVTemplate[] = [
  {
    key: SHOT_ID,
    label: '利用実績ID'
  },
  {
    key: BASE,
    label: '利用拠点'
  },
  {
    key: CREATED_AT,
    label: '利用日時'
  },
  {
    key: USER_FAMILY_NAME,
    label: '姓'
  },
  {
    key: USER_FIRST_NAME,
    label: '名'
  },
  {
    key: USER_PHONE,
    label: '電話番号'
  },
  {
    key: USER_EMAIL,
    label: 'メールアドレス'
  },
  {
    key: USER_DATE_OF_BIRTH,
    label: '誕生日'
  },
  {
    key: USER_POSTAL_CODE,
    label: '郵便番号'
  },
  {
    key: USER_PREFECTURE,
    label: '都道府県'
  },
  {
    key: USER_ADDRESS,
    label: '住所'
  },
  {
    key: USER_COMPANY_NAME,
    label: '法人名'
  },
  {
    key: USER_CATEGORY,
    label: '区分'
  },
  {
    key: USER_INFLOW_SOURCE,
    label: '流入元'
  },
  {
    key: SHOT_USAGE_TYPE,
    label: 'ご利用内容'
  },
  {
    key: SHOT_USAGE_OPTION,
    label: 'オプション'
  },
  {
    key: SHOT_CONFERENCE_USAGE_TYPE,
    label: '利用会議室'
  },
  {
    key: SHOT_CONFERENCE_USAGE_HOURS,
    label: '利用時間'
  },
  {
    key: SHOT_CONFERENCE_NUMBER_OF_PEOPLE,
    label: '利用人数'
  },
  {
    key: SHOT_CONFERENCE_ONE_DAY_USAGE,
    label: '1DAY併用の有無'
  },
  {
    key: SHOT_CONFERENCE_ONE_DAY_NUMBER_OF_PEOPLE,
    label: '1DAY併用人数'
  },
  {
    key: SHOT_RECEIPT_NAME,
    label: '領収書の宛名'
  },
  {
    key: SHOT_CARD_NO,
    label: '貸出カードNo.'
  },
  {
    key: SHOT_TAX_EXCLUDED_UNIT_PRICE,
    label: '単価(税抜)'
  },
  {
    key: SHOT_TAX_INCLUDED_UNIT_PRICE,
    label: '単価(税込)'
  },
  {
    key: SHOT_UNIT_QUANTITY,
    label: '単価数量'
  },
  {
    key: SHOT_TAX_EXCLUDED_OPTION_PRICE,
    label: 'オプション(税抜)'
  },
  {
    key: SHOT_TAX_INCLUDED_OPTION_PRICE,
    label: 'オプション(税込)'
  },
  {
    key: SHOT_OPTION_QUANTITY,
    label: 'オプション数量'
  },
  {
    key: SHOT_TAX_EXCLUDED_TOTAL_PRICE,
    label: '合計金額(税抜)'
  },
  {
    key: SHOT_TAX_INCLUDED_TOTAL_PRICE,
    label: '合計金額(税込)'
  },
  {
    key: SHOT_TOTAL_PRICE,
    label: '請求金額(税込)'
  },
  {
    key: SHOT_PAYMENT_METHOD,
    label: '支払い方法'
  },
  {
    key: SHOT_PERSON_IN_CHARGE,
    label: '担当者'
  },
  {
    key: SHOT_REMARKS,
    label: '備考'
  }
];

export const CONTRACT_HEADERS: CSVTemplate[] = [
  {
    key: CONTRACT_ID,
    label: '契約ID'
  },
  {
    key: BASE,
    label: '契約拠点'
  },
  {
    key: CONTRACT_CONTRACT_NAME,
    label: '契約名称'
  },
  {
    key: CONTRACT_CONTRACT_STATUS,
    label: '契約状況'
  },
  {
    key: CONTRACT_START_DATE,
    label: '契約開始日'
  },
  {
    key: CONTRACT_END_DATE,
    label: '契約終了日'
  },
  {
    key: CONTRACT_CONTRACT_PLAN,
    label: '契約プラン'
  },
  {
    key: CONTRACT_REPRESENTATIVE_NAME,
    label: '代表者名'
  },
  {
    key: CONTRACT_REPRESENTATIVE_EMAIL,
    label: 'メールアドレス'
  },
  {
    key: CONTRACT_PAYMENT_METHOD,
    label: '支払い方法'
  },
  {
    key: CONTRACT_PAYMENT_TYPE,
    label: '支払い回数'
  },
  {
    key: CONTRACT_USE_ADDRESS,
    label: '住所利用'
  },
  {
    key: CONTRACT_NUMBER_OF_KEYS,
    label: '鍵発行数'
  },
  {
    key: CONTRACT_NUMBER_OF_LOCKERS,
    label: 'ロッカー契約数'
  },
  {
    key: CONTRACT_TAX_EXCLUDED_PLAN_PRICE,
    label: 'プラン料金（税抜）'
  },
  {
    key: CONTRACT_TAX_INCLUDED_PLAN_PRICE,
    label: 'プラン料金（税込）'
  },
  {
    key: CONTRACT_TAX_EXCLUDED_KEY_ISSUE_PRICE,
    label: '鍵発行料金（税抜）'
  },
  {
    key: CONTRACT_TAX_INCLUDED_KEY_ISSUE_PRICE,
    label: '鍵発行料金（税込）'
  },
  {
    key: CONTRACT_TAX_EXCLUDED_LOCKER_PRICE,
    label: 'ロッカー料金（税抜）'
  },
  {
    key: CONTRACT_TAX_INCLUDED_LOCKER_PRICE,
    label: 'ロッカー料金（税込）'
  },
  {
    key: CONTRACT_TAX_EXCLUDED_ADDRESS_PRICE,
    label: '住所利用料金（税抜）'
  },
  {
    key: CONTRACT_TAX_INCLUDED_ADDRESS_PRICE,
    label: '住所利用料金（税込）'
  },
  {
    key: CONTRACT_SERVICE_RETAINER,
    label: 'サービスリテイナー'
  },
  {
    key: CONTRACT_INIT_DISCOUNT_AMOUNT,
    label: '初月割引額'
  },
  {
    key: CONTRACT_DISCOUNT_AMOUNT,
    label: '割引額'
  },
  {
    key: CONTRACT_DISCOUNT_MONTHS,
    label: '割引月数'
  },
  {
    key: CONTRACT_TAX_INCLUDED_TOTAL_AMOUNT,
    label: '合計金額（税込）'
  },
  {
    key: CONTRACT_BILLING_AMOUNT,
    label: '請求金額（税込）'
  },
  {
    key: CONTRACT_REMARKS,
    label: '備考'
  },
  {
    key: CONTRACT_INFLOW_SOURCE,
    label: '流入元'
  },
  {
    key: CREATED_AT,
    label: '登録日時'
  }
];

export const CONTRACT_USAGE_HEADERS: CSVTemplate[] = [
  {
    key: CONTRACT_USAGE_ID,
    label: '契約利用ID'
  },
  {
    key: BASE,
    label: '契約拠点'
  },
  {
    key: CONTRACT_USAGE_YEAR_AND_MONTH,
    label: '対象年月'
  },
  {
    key: CONTRACT_USAGE_CONTRACT_MSEC,
    label: '契約時間'
  },
  {
    key: CONTRACT_USAGE_USAGE_MSEC,
    label: '利用時間'
  },
  {
    key: CONTRACT_USAGE_REMAINING_MSEC,
    label: '残り時間'
  },
  {
    key: CONTRACT_CONTRACT_NAME,
    label: '契約名称'
  },
  {
    key: CONTRACT_CONTRACT_STATUS,
    label: '契約状況'
  },
  {
    key: CONTRACT_START_DATE,
    label: '契約開始日'
  },
  {
    key: CONTRACT_END_DATE,
    label: '契約終了日'
  },
  {
    key: CONTRACT_CONTRACT_PLAN,
    label: '契約プラン'
  },
  {
    key: CONTRACT_REPRESENTATIVE_NAME,
    label: '代表者名'
  },
  {
    key: CONTRACT_REPRESENTATIVE_EMAIL,
    label: 'メールアドレス'
  },
  {
    key: CONTRACT_PAYMENT_METHOD,
    label: '支払い方法'
  },
  {
    key: CONTRACT_PAYMENT_TYPE,
    label: '支払い回数'
  },
  {
    key: CONTRACT_USE_ADDRESS,
    label: '住所利用'
  },
  {
    key: CONTRACT_NUMBER_OF_KEYS,
    label: '鍵発行数'
  },
  {
    key: CONTRACT_NUMBER_OF_LOCKERS,
    label: 'ロッカー契約数'
  },
  {
    key: CONTRACT_REMARKS,
    label: '備考'
  }
];

export const ACCESS_HEADERS: CSVTemplate[] = [
  {
    key: ACCESS_ID,
    label: '入退室履歴ID'
  },
  {
    key: BASE,
    label: '利用拠点'
  },
  {
    key: ACCESS_ACCESS_TYPE,
    label: '区分'
  },
  {
    key: ACCESS_ACCESS_DATE,
    label: '入退室時刻'
  },
  {
    key: USER_FAMILY_NAME,
    label: '姓'
  },
  {
    key: USER_FIRST_NAME,
    label: '名'
  },
  {
    key: USER_PHONE,
    label: '電話番号'
  },
  {
    key: USER_EMAIL,
    label: 'メールアドレス'
  },
  {
    key: USER_DATE_OF_BIRTH,
    label: '誕生日'
  },
  {
    key: USER_POSTAL_CODE,
    label: '郵便番号'
  },
  {
    key: USER_PREFECTURE,
    label: '都道府県'
  },
  {
    key: USER_ADDRESS,
    label: '住所'
  },
  {
    key: USER_COMPANY_NAME,
    label: '法人名'
  }
];

export const PARTICIPANT_HEADERS: CSVTemplate[] = [
  {
    key: EVENT_PARTICIPANT_EVENT_PARTICIPANT_ID,
    label: '参加登録ID'
  },
  {
    key: BASE,
    label: '開催拠点'
  },
  {
    key: EVENT_SCHEDULE_NAME,
    label: '名称'
  },
  {
    key: EVENT_SCHEDULE_START_AT,
    label: '開始日時'
  },
  {
    key: EVENT_SCHEDULE_END_AT,
    label: '終了日時'
  },
  {
    key: USER_FAMILY_NAME,
    label: '姓'
  },
  {
    key: USER_FIRST_NAME,
    label: '名'
  },
  {
    key: USER_PHONE,
    label: '電話番号'
  },
  {
    key: USER_EMAIL,
    label: 'メールアドレス'
  },
  {
    key: USER_DATE_OF_BIRTH,
    label: '誕生日'
  },
  {
    key: USER_POSTAL_CODE,
    label: '郵便番号'
  },
  {
    key: USER_PREFECTURE,
    label: '都道府県'
  },
  {
    key: USER_ADDRESS,
    label: '住所'
  },
  {
    key: USER_COMPANY_NAME,
    label: '法人名'
  },
  {
    key: USER_CATEGORY,
    label: '区分'
  },
  {
    key: EVENT_PARTICIPANT_INFLOW_SOURCE,
    label: '流入元'
  },
  {
    key: EVENT_PARTICIPANT_COMMENT,
    label: 'ひと言'
  },
  {
    key: EVENT_PARTICIPANT_REMARKS,
    label: '質問や要望など'
  },
  {
    key: CREATED_AT,
    label: '登録日時'
  }
];
