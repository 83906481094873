import { EventInflowSource } from '@atomica.co/irori';
import { Labels } from '../models/common-model';

export const EVENT_INFLOW_SOURCE_LABELS: Labels = {
  [EventInflowSource.CONTINUE]: 'これまでに参加したことがある',
  [EventInflowSource.STAFF]: '店頭（スタッフからの声掛け）',
  [EventInflowSource.FRIEND]: '知り合いからの紹介',
  [EventInflowSource.INSTAGRAM]: 'Instagram',
  [EventInflowSource.TWITTER]: 'Twitter',
  [EventInflowSource.FACEBOOK]: 'Facebook',
  [EventInflowSource.LINE]: 'LINE',
  [EventInflowSource.FLYER]: 'チラシ',
  [EventInflowSource.OTHER]: 'その他'
};
