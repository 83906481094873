import { Button, Component, Icon, theme, useSafeCallback } from '@atomica.co/components';
import { BaseDto } from '@atomica.co/irori';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Process } from '../../../../../enums/action-enum';
import { constructLineLoginURL } from '../../../../../line';
import useProcess from '../../../../../redux/hooks/useProcess';
import line from './../../../../../assets/icon/icon_line.png';

interface P {
  base: BaseDto | undefined;
}

const LineSignUp: React.FC<P> = React.memo(props => {
  const { base } = props;
  const { setProcess } = useProcess();

  const openLineLoginPage = useSafeCallback((): void => {
    if (!base) return;
    setProcess(Process.PROCESSING);
    const lineLoginURL = constructLineLoginURL(base.baseCode);
    window.location.href = lineLoginURL;
  }, [base, setProcess]);

  return (
    <Component className='line-sign-up'>
      <ButtonWrapper>
        <Button type='default' onClick={openLineLoginPage}>
          <IconWrapper>
            <Icon size='xsmall' src={line} />
          </IconWrapper>

          <Label>LINEで登録</Label>
        </Button>
      </ButtonWrapper>
    </Component>
  );
});

export default LineSignUp;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled(Typography)`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
