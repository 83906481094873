import {
  Button,
  CommentBox,
  InputBox,
  MultiCheckBox,
  RadioBox,
  Scrollable,
  SelectBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef,
  VerticalEllipsisButton,
  VerticalEllipsisButtonOption
} from '@atomica.co/components';
import {
  Base,
  BASE_CODE,
  CountShotUsageRequest,
  DeleteShotUsageRequest,
  FetchUserRequest,
  SaveShotUsageRequest,
  Shot,
  ShotPaymentMethod,
  toLatestUserPath,
  UsageType,
  User,
  USER_ID
} from '@atomica.co/irori';
import {
  Amount,
  builder,
  Category,
  Count,
  EMPTY,
  isMoreThanZero,
  Label,
  Message,
  Name,
  Number,
  ONE,
  Option,
  PersonInCharge,
  Quantity,
  Remarks,
  scroll,
  uuid,
  ZERO
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import {
  ConferenceUsageType,
  showConferenceOneDayUsage,
  showUsageOption,
  toConferenceUsageTypeLabels,
  toConferenceUsageTypes,
  toUsageOptionLabels,
  toUsageOptions,
  toUsageTypes,
  UsageOption,
  USAGE_TYPE_LABELS
} from '../../constants/base-config';
import { NO_PRIVILEGE } from '../../constants/error-message-const';
import { SHOT_USAGE_DELETED, SHOT_USAGE_SAVED, SHOT_USAGE_UPDATED, SUCCESS } from '../../constants/snackbar-const';
import { fromQueryParams, toShot } from '../../converters/shot-usage-converter';
import { Hour, NumberOfPeople } from '../../enums/common-enum';
import { database } from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import ShotUsageRequest from '../../requests/shot-request';
import UserRequest from '../../requests/user-request';
import ConferenceService from '../../services/conference-service';
import PriceService from '../../services/price-service';
import { HOURS_LABELS, NUMBER_OF_PEOPLE_LABELS } from '../../texts/common-text';
import { PAYMENT_METHOD_LABELS } from '../../texts/shot-text';
import { USER_CATEGORY_LABELS } from '../../texts/user-text';
import { isPIC, toFullName } from '../../utils/user-util';
import mojaco_greeting from './../../assets/mojaco/mojaco_greeting.png';
import { toOption } from './InputShotScreen';

const TOP = 'top';

const UPDATE_BUTTON_LABEL = '入力内容を更新する';

const CLOSE_WINDOW_LABEL = 'ウィンドウを閉じる';

const toCategory = (user: User): Category =>
  !!user && !!user.category ? `${USER_CATEGORY_LABELS[user.category]}の` : EMPTY;

enum VerticalEllipsisButtonType {
  EDIT_USAGE = 'edit_usage',
  DELETE_USAGE = 'delete_usage'
}

const VERTICAL_ELLIPSIS_BUTTON_OPTIONS: VerticalEllipsisButtonOption[] = [
  {
    id: VerticalEllipsisButtonType.EDIT_USAGE,
    label: '入力内容を編集する'
  },
  {
    id: VerticalEllipsisButtonType.DELETE_USAGE,
    label: '入力内容を取り消す'
  }
];

interface P extends RouteComponentProps {}

const ShotReceptionScreen: React.FC<P> = React.memo(() => {
  const unmountRef = useUnmountRef();
  const { queryParams, params } = usePath();
  const { getUser } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [editable, setEditable] = useSafeState<boolean>(unmountRef, false);
  const [disabledAllButtons, setDisabledAllButtons] = useSafeState<boolean>(unmountRef, false);
  const [isUsageDeleted, setIsUsageDeleted] = useSafeState<boolean>(unmountRef, false);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef);
  const [base, setBase] = useSafeState<Base>(unmountRef);
  const [visitor, setVisitor] = useSafeState<User>(unmountRef);
  const [shotUsageCount, setShotUsageCount] = useSafeState<Count>(unmountRef);
  const [shotToSave, setShotToSave] = useSafeState<Shot>(unmountRef);
  const [usageType, setUsageType] = useSafeState<UsageType>(unmountRef);
  const usageOptions = useMemo<Option[]>(() => toUsageOptions(params[BASE_CODE], usageType), [params, usageType]);
  const [usageOption, setUsageOption] = useSafeState<UsageOption | undefined>(unmountRef);
  const [cardNo, setCardNo] = useSafeState<Number>(unmountRef, EMPTY);
  const conferenceUsageTypes = useMemo<Option[]>(() => toConferenceUsageTypes(params[BASE_CODE]), [params]);
  const [conferenceUsageType, setConferenceUsageType] = useSafeState<ConferenceUsageType | undefined>(unmountRef);
  const [conferenceUsageHours, setConferenceUsageHours] = useSafeState<Hour | undefined>(unmountRef, Hour.ONE);
  const [conferenceNumberOfPeople, setConferenceNumberOfPeople] = useSafeState<Count | undefined>(unmountRef);
  const [conferenceOneDayUsage, setConferenceOneDayUsage] = useSafeState<boolean>(unmountRef, false);
  const [conferenceOneDayNumberOfPeople, setConferenceOneDayNumberOfPeople] = useSafeState<Count | undefined>(
    unmountRef
  );
  const [receiptName, setReceiptName] = useSafeState<Name>(unmountRef, EMPTY);
  const [taxIncludedUnitPriceLabel, setTaxIncludedUnitPriceLabel] = useSafeState<Label>(unmountRef, EMPTY);
  const [taxIncludedUnitPrice, setTaxIncludedUnitPrice] = useSafeState<Amount>(unmountRef, ZERO);
  const [unitQuantityLabel, setUnitQuantityLabel] = useSafeState<Label>(unmountRef, EMPTY);
  const [unitQuantity, setUnitQuantity] = useSafeState<Quantity>(unmountRef, ZERO);
  const [taxIncludedOptionPriceLabel, setTaxIncludedOptionPriceLabel] = useSafeState<Label>(unmountRef, EMPTY);
  const [taxIncludedOptionPrice, setTaxIncludedOptionPrice] = useSafeState<Amount>(unmountRef, ZERO);
  const [optionQuantityLabel, setOptionQuantityLabel] = useSafeState<Label>(unmountRef, EMPTY);
  const [optionQuantity, setOptionQuantity] = useSafeState<Quantity>(unmountRef, ZERO);
  const [taxIncludedTotalPrice, setTaxIncludedTotalPrice] = useSafeState<Amount>(unmountRef, ZERO);
  const [totalPrice, setTotalPrice] = useSafeState<Amount>(unmountRef, ZERO);
  const [paymentMethod, setPaymentMethod] = useSafeState<ShotPaymentMethod>(unmountRef, ShotPaymentMethod.CASH);
  const [personInCharge, setPersonInCharge] = useSafeState<PersonInCharge>(unmountRef, EMPTY);
  const [remarks, setRemarks] = useSafeState<Remarks>(unmountRef, EMPTY);

  const isConferenceGuest = useMemo<boolean>(() => {
    return ConferenceService.returnEligibleIfConferenceRoomReserved(usageType, conferenceUsageType, false, true);
  }, [usageType, conferenceUsageType]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const base = params[BASE_CODE]!;
    const visitorUserId = queryParams[USER_ID]!;

    const vistorRequest = builder<FetchUserRequest>().userId(visitorUserId).build();
    const countRequest = builder<CountShotUsageRequest>().userId(visitorUserId).build();

    const [staff, visitorResponse, countResponse] = await Promise.all([
      getUser() as Promise<User>,
      UserRequest.fetchUser(vistorRequest),
      ShotUsageRequest.countShotUsage(countRequest)
    ]);

    if (!isPIC(staff, base)) {
      setErrorMessage(NO_PRIVILEGE);
      return;
    }

    const visitor = visitorResponse.user!;
    const count = countResponse.count;
    const shotToSave = fromQueryParams(queryParams, base, staff, visitor);

    const taxIncludedUnitLabel = PriceService.getTaxIncludedUnitPriceLabel(
      base,
      shotToSave.usageType,
      shotToSave.conferenceUsageType as ConferenceUsageType
    );
    const unitQuantityLabel = PriceService.getUnitQuantityLabel(
      shotToSave.usageType,
      shotToSave.conferenceUsageType as ConferenceUsageType
    );
    const taxIncludedOptionPriceLabel = PriceService.getTaxIncludedOptionPriceLabel(
      base,
      shotToSave.usageType,
      shotToSave.usageOption as UsageOption,
      shotToSave.conferenceOneDayUsage
    );
    const optionQuantityLabel = PriceService.getOptionQuantityLabel(
      shotToSave.usageType,
      shotToSave.conferenceOneDayUsage
    );

    setBase(base);
    setVisitor(visitor);
    setShotUsageCount(count);
    setShotToSave(shotToSave);
    setUsageType(shotToSave.usageType);
    setUsageOption(shotToSave.usageOption as UsageOption);
    setConferenceUsageType(shotToSave.conferenceUsageType as ConferenceUsageType);
    setConferenceUsageHours(shotToSave.conferenceUsageHours as any);
    setConferenceNumberOfPeople(shotToSave.conferenceNumberOfPeople);
    setConferenceOneDayUsage(!!shotToSave.conferenceOneDayUsage);
    setConferenceOneDayNumberOfPeople(shotToSave.conferenceOneDayNumberOfPeople);
    setReceiptName(shotToSave.receiptName!);
    setCardNo(shotToSave.cardNo!);
    setTaxIncludedUnitPriceLabel(taxIncludedUnitLabel);
    setTaxIncludedUnitPrice(shotToSave.taxIncludedUnitPrice!);
    setUnitQuantityLabel(unitQuantityLabel);
    setUnitQuantity(shotToSave.unitQuantity!);
    setTaxIncludedOptionPriceLabel(taxIncludedOptionPriceLabel);
    setTaxIncludedOptionPrice(shotToSave.taxIncludedOptionPrice!);
    setOptionQuantityLabel(optionQuantityLabel);
    setOptionQuantity(shotToSave.optionQuantity!);
    setTaxIncludedTotalPrice(shotToSave.taxIncludedTotalPrice!);
    setTotalPrice(shotToSave.taxIncludedTotalPrice!);
    setPaymentMethod(shotToSave.paymentMethod!);
    setPersonInCharge(shotToSave.personInCharge!);
    setLoaded(true);

    const request = builder<SaveShotUsageRequest>().shot(shotToSave).build();
    const response = await ShotUsageRequest.saveShotUsage(request);

    !!response.shotId && database.ref(toLatestUserPath(base)).set({ userId: `${visitorUserId}-${uuid()}` });
    !!response.shotId && enqueueSnackbar(SHOT_USAGE_SAVED, { variant: SUCCESS });
  }, [
    params,
    queryParams,
    getUser,
    setErrorMessage,
    setBase,
    setVisitor,
    setShotUsageCount,
    setShotToSave,
    setUsageType,
    setUsageOption,
    setReceiptName,
    setCardNo,
    setConferenceUsageType,
    setConferenceUsageHours,
    setConferenceNumberOfPeople,
    setConferenceOneDayUsage,
    setConferenceOneDayNumberOfPeople,
    setTaxIncludedUnitPriceLabel,
    setTaxIncludedUnitPrice,
    setUnitQuantityLabel,
    setUnitQuantity,
    setTaxIncludedOptionPriceLabel,
    setTaxIncludedOptionPrice,
    setOptionQuantityLabel,
    setOptionQuantity,
    setTaxIncludedTotalPrice,
    setTotalPrice,
    setPaymentMethod,
    setPersonInCharge,
    setLoaded,
    enqueueSnackbar
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    setShotToSave(prevShot => {
      if (!prevShot) return prevShot;

      const shotUsageToUpdate = toShot(
        prevShot.shotId,
        usageType,
        base,
        visitor,
        usageOption,
        conferenceUsageType,
        conferenceUsageHours,
        conferenceNumberOfPeople,
        conferenceOneDayUsage,
        conferenceOneDayNumberOfPeople,
        receiptName,
        cardNo,
        totalPrice,
        paymentMethod,
        personInCharge,
        remarks
      );

      const taxIncludedUnitLabel = PriceService.getTaxIncludedUnitPriceLabel(
        base,
        shotUsageToUpdate.usageType,
        shotUsageToUpdate.conferenceUsageType as ConferenceUsageType
      );
      const unitQuantityLabel = PriceService.getUnitQuantityLabel(
        shotUsageToUpdate.usageType,
        shotUsageToUpdate.conferenceUsageType as ConferenceUsageType
      );
      const taxIncludedOptionPriceLabel = PriceService.getTaxIncludedOptionPriceLabel(
        base,
        shotUsageToUpdate.usageType,
        shotUsageToUpdate.usageOption as UsageOption,
        shotUsageToUpdate.conferenceOneDayUsage
      );
      const optionQuantityLabel = PriceService.getOptionQuantityLabel(
        shotUsageToUpdate.usageType,
        shotUsageToUpdate.conferenceOneDayUsage
      );

      setTaxIncludedUnitPriceLabel(taxIncludedUnitLabel);
      setTaxIncludedUnitPrice(shotUsageToUpdate.taxIncludedUnitPrice!);
      setUnitQuantityLabel(unitQuantityLabel);
      setUnitQuantity(shotUsageToUpdate.unitQuantity!);
      setTaxIncludedOptionPriceLabel(taxIncludedOptionPriceLabel);
      setTaxIncludedOptionPrice(shotUsageToUpdate.taxIncludedOptionPrice!);
      setOptionQuantityLabel(optionQuantityLabel);
      setOptionQuantity(shotUsageToUpdate.optionQuantity!);
      setTaxIncludedTotalPrice(shotUsageToUpdate.taxIncludedTotalPrice!);
      return shotUsageToUpdate;
    });
  }, [
    setShotToSave,
    usageType,
    base,
    visitor,
    conferenceUsageType,
    conferenceUsageHours,
    conferenceNumberOfPeople,
    conferenceOneDayUsage,
    conferenceOneDayNumberOfPeople,
    usageOption,
    receiptName,
    cardNo,
    totalPrice,
    paymentMethod,
    personInCharge,
    remarks,
    setTaxIncludedUnitPriceLabel,
    setTaxIncludedUnitPrice,
    setUnitQuantityLabel,
    setUnitQuantity,
    setTaxIncludedOptionPriceLabel,
    setTaxIncludedOptionPrice,
    setOptionQuantityLabel,
    setOptionQuantity,
    setTaxIncludedTotalPrice
  ]);

  const updateShotUsage = useSafeCallback(async (): Promise<void> => {
    const request = builder<SaveShotUsageRequest>().shot(shotToSave).build();
    const response = await ShotUsageRequest.saveShotUsage(request);
    !!response.shotId && database.ref(toLatestUserPath(base)).set({ userId: `${shotToSave.user!.userId}-${uuid()}` });
    !!response.shotId && enqueueSnackbar(SHOT_USAGE_UPDATED, { variant: SUCCESS });
    setDisabledAllButtons(false);
  }, [shotToSave, base, enqueueSnackbar, setDisabledAllButtons]);

  const deleteShotUsage = useSafeCallback(async (): Promise<void> => {
    const request = builder<DeleteShotUsageRequest>().shotId(shotToSave.shotId).build();
    await ShotUsageRequest.deleteShotUsage(request);
    database.ref(toLatestUserPath(base)).set({ userId: `${shotToSave.user!.userId}-${uuid()}` });
    enqueueSnackbar(SHOT_USAGE_DELETED, { variant: SUCCESS });
    setDisabledAllButtons(false);
  }, [shotToSave, base, enqueueSnackbar, setDisabledAllButtons]);

  const handleVerticalEllipsisButtonClicked = useSafeCallback(
    (id: VerticalEllipsisButtonType): void => {
      switch (id) {
        case VerticalEllipsisButtonType.EDIT_USAGE:
          setEditable(true);
          scroll(TOP);
          break;

        case VerticalEllipsisButtonType.DELETE_USAGE:
          setDisabledAllButtons(true);
          deleteShotUsage();
          setIsUsageDeleted(true);
          break;
      }
    },
    [setEditable, setDisabledAllButtons, deleteShotUsage, setIsUsageDeleted]
  );

  return (
    <Screen loading={!loaded} errorMsg={errorMessage} className='reception-screen'>
      <Container>
        <Scrollable>
          <MojacoWrapper id={TOP}>
            <CommentBox animation photoURL={mojaco_greeting}>
              <Greeting>
                受付おつかれさま！{toCategory(visitor)}
                {toFullName(visitor)}さんが来たよ！直近１ヶ月で1DAYを
                {shotUsageCount}回利用してくれてるよ！
              </Greeting>
            </CommentBox>
          </MojacoWrapper>

          <RadioWrapper>
            <RadioBox
              editable={editable}
              title='本日のご利用内容'
              options={toUsageTypes(base)}
              labels={USAGE_TYPE_LABELS}
              value={usageType}
              onChange={setUsageType}
            />
          </RadioWrapper>

          {showUsageOption(usageType) && (
            <>
              {usageOptions.length > ONE && (
                <RadioWrapper>
                  <RadioBox
                    editable={editable}
                    title='該当するものがあれば、お選びください'
                    options={usageOptions}
                    labels={toUsageOptionLabels(base, usageType)}
                    value={usageOption}
                    onChange={setUsageOption}
                  />
                </RadioWrapper>
              )}

              <InputWrapper>
                <InputBox
                  editable={editable}
                  type='text'
                  label='領収書の宛名'
                  text={receiptName}
                  onChange={setReceiptName}
                />
              </InputWrapper>

              <InputWrapper>
                <InputBox
                  required={false}
                  editable={editable}
                  maxLength={10}
                  type='number'
                  label='貸出カードNo.'
                  text={cardNo}
                  onChange={setCardNo}
                />
              </InputWrapper>
            </>
          )}

          {usageType === UsageType.CONFERENCE && (
            <>
              <RadioWrapper>
                <RadioBox
                  editable={editable}
                  title='会議室'
                  options={conferenceUsageTypes}
                  labels={toConferenceUsageTypeLabels(base)}
                  value={conferenceUsageType}
                  onChange={setConferenceUsageType}
                />
              </RadioWrapper>

              {!isConferenceGuest && (
                <>
                  <SelectWrapper>
                    <SelectBox
                      editable={editable}
                      title='利用時間'
                      options={Object.values(Hour)}
                      labels={HOURS_LABELS}
                      value={conferenceUsageHours}
                      onChange={setConferenceUsageHours}
                    />
                  </SelectWrapper>

                  <SelectWrapper>
                    <SelectBox
                      editable={editable}
                      title='利用人数'
                      options={Object.values(NumberOfPeople)}
                      labels={NUMBER_OF_PEOPLE_LABELS}
                      value={conferenceNumberOfPeople}
                      onChange={setConferenceNumberOfPeople}
                    />
                  </SelectWrapper>

                  {showConferenceOneDayUsage(base) && (
                    <CheckBoxWrapper>
                      <MultiCheckBox
                        editable={editable}
                        title='1DAYの併用有無'
                        options={toOption(conferenceOneDayUsage)}
                        onChange={options => setConferenceOneDayUsage(options[0].checked)}
                      />
                    </CheckBoxWrapper>
                  )}

                  {!!conferenceOneDayUsage && (
                    <>
                      <SelectWrapper>
                        <SelectBox
                          editable={editable}
                          title='1DAYの利用人数'
                          options={Object.values(NumberOfPeople)}
                          labels={NUMBER_OF_PEOPLE_LABELS}
                          value={conferenceOneDayNumberOfPeople}
                          onChange={setConferenceOneDayNumberOfPeople}
                        />
                      </SelectWrapper>
                    </>
                  )}

                  <InputWrapper>
                    <InputBox
                      editable={editable}
                      type='text'
                      label='領収書の宛名'
                      text={receiptName}
                      onChange={setReceiptName}
                    />
                  </InputWrapper>
                </>
              )}

              <InputWrapper>
                <InputBox
                  required={false}
                  editable={editable}
                  maxLength={10}
                  type='number'
                  label='貸出カードNo.'
                  text={cardNo}
                  onChange={setCardNo}
                />
              </InputWrapper>
            </>
          )}

          {(usageType === UsageType.COWORKING ||
            usageType === UsageType.EVENT ||
            usageType === UsageType.GUEST ||
            usageType === UsageType.PREVIEW) && (
            <>
              <InputWrapper>
                <InputBox
                  required={false}
                  editable={editable}
                  maxLength={10}
                  type='number'
                  label='貸出カードNo.'
                  text={cardNo}
                  onChange={setCardNo}
                />
              </InputWrapper>
            </>
          )}

          <MojacoWrapper>
            <CommentBox animation photoURL={mojaco_greeting}>
              <Greeting>料金を計算したよ！</Greeting>
            </CommentBox>
          </MojacoWrapper>

          {!!taxIncludedUnitPriceLabel && (
            <InputWrapper>
              <InputBox
                editable={false}
                type='text'
                label={taxIncludedUnitPriceLabel}
                text={taxIncludedUnitPrice}
                onChange={setTaxIncludedUnitPrice}
              />
            </InputWrapper>
          )}

          {!!unitQuantityLabel && (
            <InputWrapper>
              <InputBox
                editable={false}
                type='number'
                label={unitQuantityLabel}
                text={unitQuantity}
                onChange={setUnitQuantity}
              />
            </InputWrapper>
          )}

          {!!taxIncludedOptionPriceLabel && (
            <InputWrapper>
              <InputBox
                editable={false}
                type='number'
                label={taxIncludedOptionPriceLabel}
                text={taxIncludedOptionPrice}
                onChange={setTaxIncludedOptionPrice}
              />
            </InputWrapper>
          )}

          {!!optionQuantityLabel && (
            <InputWrapper>
              <InputBox
                editable={false}
                type='number'
                label={optionQuantityLabel}
                text={optionQuantity}
                onChange={setOptionQuantity}
              />
            </InputWrapper>
          )}

          <InputWrapper>
            <InputBox
              editable={false}
              type='number'
              label='合計料金(税込)'
              text={taxIncludedTotalPrice}
              onChange={setTaxIncludedTotalPrice}
            />
          </InputWrapper>

          <InputWrapper>
            <InputBox
              required={true}
              editable={!isUsageDeleted}
              type='number'
              label='請求料金(税込)'
              text={totalPrice}
              onChange={setTotalPrice}
            />
          </InputWrapper>

          {isMoreThanZero(totalPrice) && (
            <RadioWrapper>
              <RadioBox
                editable={!isUsageDeleted}
                title='支払い方法'
                options={Object.values(ShotPaymentMethod)}
                labels={PAYMENT_METHOD_LABELS}
                value={paymentMethod}
                onChange={setPaymentMethod}
              />
            </RadioWrapper>
          )}

          <InputWrapper>
            <InputBox
              editable={!isUsageDeleted}
              type='text'
              label='担当者'
              text={personInCharge}
              onChange={setPersonInCharge}
            />
          </InputWrapper>

          <InputWrapper>
            <InputBox editable={!isUsageDeleted} type='text' label='備考' text={remarks} onChange={setRemarks} />
          </InputWrapper>

          {!editable && (
            <>
              <MultiButtonsArea>
                <MultiButtonsWrapper>
                  {!isUsageDeleted && (
                    <ButtonWrapper>
                      <Button
                        disabled={disabledAllButtons}
                        type='primary'
                        onClick={() => {
                          setDisabledAllButtons(true);
                          updateShotUsage();
                        }}
                      >
                        <StyledLabel>{UPDATE_BUTTON_LABEL}</StyledLabel>
                      </Button>
                    </ButtonWrapper>
                  )}

                  <ButtonWrapper>
                    <Button disabled={disabledAllButtons} type='secondary' onClick={() => window.close()}>
                      <StyledLabel>{CLOSE_WINDOW_LABEL}</StyledLabel>
                    </Button>
                  </ButtonWrapper>
                </MultiButtonsWrapper>

                {!isUsageDeleted && (
                  <EllipsisWrapper>
                    <VerticalEllipsisButton
                      options={VERTICAL_ELLIPSIS_BUTTON_OPTIONS}
                      onClick={handleVerticalEllipsisButtonClicked}
                    />
                  </EllipsisWrapper>
                )}
              </MultiButtonsArea>
            </>
          )}

          {editable && (
            <MultiButtonsArea>
              <MultiButtonsWrapper>
                <Button
                  disabled={disabledAllButtons}
                  type='primary'
                  onClick={() => {
                    setDisabledAllButtons(true);
                    setEditable(false);
                    updateShotUsage();
                  }}
                >
                  <StyledLabel>保存する</StyledLabel>
                </Button>
              </MultiButtonsWrapper>
            </MultiButtonsArea>
          )}

          <BottomArea />
        </Scrollable>
      </Container>
    </Screen>
  );
});

export default ShotReceptionScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

const MojacoWrapper = styled.div`
  margin: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 4}px ${theme.mixins.spacing}px;
`;

const Greeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const RadioWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 8}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const SelectWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 8}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const InputWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 8}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const CheckBoxWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 8}px);
  height: auto;
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const MultiButtonsArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const MultiButtonsWrapper = styled.div`
  width: ${window.innerWidth > 440 ? '384px' : '200px'};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div`
  margin: ${theme.mixins.spacing / 2}px;
`;

const EllipsisWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: ${theme.mixins.spacing}px;
`;

const StyledLabel = styled(Typography)`
  width: 160px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
`;

const BottomArea = styled.div`
  width: 100%;
  height: 120px;
`;
