import { BoxFrame, Component, Scrollable, theme } from '@atomica.co/components';
import { BaseDto } from '@atomica.co/irori';
import { Email } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../constants/common-const';
import atomica from './../../../../assets/atomica.gif';
import EmailSignUp from './parts/EmailSignUp';
import GoogleSignUp from './parts/GoogleSignUp';
import LineSignUp from './parts/LineSignUp';

export enum SignUpEnum {
  INPUT_EMAIL,
  EMAIL_SENT
}

interface P {
  base: BaseDto | undefined;
  onSendEmail(email: Email): void;
}

const SignUpOptionList: React.FC<P> = React.memo(props => {
  const { base, onSendEmail } = props;

  return (
    <Component className='sign-up-option-list'>
      <Container>
        <Scrollable>
          <Content>
            <LogoWrapper>
              <Logo src={atomica} />
            </LogoWrapper>

            <BoxWrapper>
              <BoxFrame
                content={
                  <>
                    <Title>アトミカは</Title>
                    <Title>クリエイティブなコミュニティスペース</Title>
                    <Title>高セキュリティのプライベートルーム</Title>
                    <Title>洗練された会議室など</Title>
                    <Title>多様な"働く形"に対応できる</Title>
                    <Title>コワーキングスペースです</Title>
                  </>
                }
              />
            </BoxWrapper>

            <EmailSignUp onSendEmail={onSendEmail} />

            <GoogleSignUp />

            <LineSignUp base={base} />
          </Content>
        </Scrollable>
      </Container>
    </Component>
  );
});

export default SignUpOptionList;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${theme.mixins.spacing * 6}px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const Logo = styled.img`
  width: 100%;
  height: auto;
  min-height: 160px;
  object-fit: cover;
`;

const BoxWrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Title = styled(Typography)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;
