import {
  Component,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  ReactUseGestureEventHandlers,
  theme,
  useSafeCallback
} from '@atomica.co/components';
import {
  BASE_CODE,
  constructExternalLink,
  SHARED_SPACE_RESERVATION_END_AT,
  SHARED_SPACE_RESERVATION_ID,
  SHARED_SPACE_RESERVATION_START_AT
} from '@atomica.co/irori';
import { copyURL, embedIdInPath, ID, URL } from '@atomica.co/utils';
import { Card, CardContent, CardMedia } from '@material-ui/core';
import { animated } from '@react-spring/web';
import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import IconShare from '../../../../assets/icon/icon_share.png';
import { BOTTOM_NAGIGATOR_HEIGHT } from '../../../../constants/my-account-consts';
import { INVITATION_LINK_COPIED, SUCCESS } from '../../../../constants/snackbar-const';
import { SpaceReservationCard } from '../../../../converters/my-account-converter';
import env from '../../../../env/env';
import usePath from '../../../../redux/hooks/usePath';
import { Path } from '../../../../router/Routes';
import { dueTimeToStr, reservationTimeToStr } from '../../../../utils/space-reservation-util';

interface P {
  isFront: boolean;
  showCompleted: boolean;
  spaceReservationCard: SpaceReservationCard;
  bind: (id: ID) => ReactUseGestureEventHandlers;
}

const SwipableSpaceReservationCard: React.FC<P> = React.memo(props => {
  const { bind, isFront, spaceReservationCard } = props;
  const { params } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const {
    spaceReservationId,
    spaceName,
    spacePhotoUrl,
    // reservationName,
    reservationStartAt,
    reservationEndAt,
    reservationRemarks,
    blobQrCode
  } = spaceReservationCard;

  const dateLabel = useMemo<string>(() => {
    if (!reservationStartAt && !reservationEndAt) return '指定なし';

    return !!reservationStartAt && !!reservationEndAt
      ? reservationTimeToStr(reservationStartAt, reservationStartAt, reservationEndAt)
      : dueTimeToStr(reservationEndAt);
  }, [reservationStartAt, reservationEndAt]);

  const spaceReservationURLToShare = useMemo<URL | undefined>(() => {
    if (!spaceReservationId || !reservationStartAt || !reservationEndAt) return;

    const startAt = format(reservationStartAt, 'yyyy/MM/dd HH:mm:ss').toString();
    const endAt = format(reservationEndAt, 'yyyy/MM/dd HH:mm:ss').toString();

    const queryParamOfSpaceReservationId = `${SHARED_SPACE_RESERVATION_ID}=${spaceReservationId}`;
    const queryParamOfStartAt = `${SHARED_SPACE_RESERVATION_START_AT}=${startAt}`;
    const queryParamOfEndAt = `${SHARED_SPACE_RESERVATION_END_AT}=${endAt}`;

    const pathToOpen = `${embedIdInPath(
      Path.MY_ACCOUNT,
      [BASE_CODE],
      [params[BASE_CODE]]
    )}?${queryParamOfSpaceReservationId}&${queryParamOfStartAt}&${queryParamOfEndAt}`;

    const url = constructExternalLink(env, pathToOpen);
    return url;
  }, [spaceReservationId, reservationEndAt, reservationStartAt, params]);

  const handleShareClicked = useSafeCallback(async (): Promise<void> => {
    if (!spaceReservationURLToShare) return;

    if (!!navigator.share) {
      await navigator.share({ url: spaceReservationURLToShare });
    } else {
      copyURL(spaceReservationURLToShare);
      enqueueSnackbar(INVITATION_LINK_COPIED, { variant: SUCCESS });
    }
  }, [spaceReservationURLToShare, enqueueSnackbar]);

  return (
    <Component className='reservation-card'>
      <Container {...bind(spaceReservationCard.spaceReservationId)}>
        <Content isFront={isFront}>
          <CardWrapper paddingBottom={BOTTOM_NAGIGATOR_HEIGHT}>
            <CustomCard isfront={isFront ? 1 : 0}>
              <CustomCardMedia component='img' height='120' image={spacePhotoUrl} />
              <CustomCardContent>
                <Table>
                  <tbody>
                    {/* <Tr>
                      <Td colSpan='3'>
                        <SpaceLabel>{reservationName}</SpaceLabel>
                      </Td>
                    </Tr> */}
                    <Tr>
                      <TdNoWrap>会議室</TdNoWrap>
                      <Td colSpan='2'>{spaceName ? spaceName : '指定なし'}</Td>
                    </Tr>
                    <Tr>
                      <TdNoWrap>時刻</TdNoWrap>
                      <Td colSpan='2'>{dateLabel}</Td>
                    </Tr>
                    <Tr>
                      <TdNoWrap>備考</TdNoWrap>
                      <Td colSpan='2'>{reservationRemarks}</Td>
                    </Tr>
                    <Tr>
                      <Td colSpan='3'>
                        <QrCodeWrapper>{!!blobQrCode && <QrCode src={blobQrCode} />}</QrCodeWrapper>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td colSpan='3'>
                        <ShareWrapper>
                          {!!spaceReservationURLToShare && (
                            <ShareContent onClick={handleShareClicked}>
                              <CustomIconShare src={IconShare} />
                              <Label>送る</Label>
                            </ShareContent>
                          )}
                        </ShareWrapper>
                      </Td>
                    </Tr>
                  </tbody>
                </Table>
              </CustomCardContent>
            </CustomCard>
          </CardWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default SwipableSpaceReservationCard;

const Container = styled(animated.div)`
  width: calc(100vw - ${theme.mixins.spacing * 4}px);
  max-width: ${MOBILE_MAX_WIDTH - theme.mixins.spacing * 4}px;
  min-width: ${MOBILE_MIN_WIDTH - theme.mixins.spacing * 4}px;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CardWrapper = styled.div`
  max-width: 340px;
  min-width: 280px;
  width: 85%;
  position: relative;
  color: white;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || 0}px;
`;
const CustomCard = styled(Card)`
  height: auto !important;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: ${({ isfront }) => (isfront ? 'auto' : '520px')};
  user-select: none;
`;
const CustomCardMedia = styled(CardMedia)`
  background: ${theme.mixins.background.lightGray};
  user-drag: none;
`;
// const SpaceLabel = styled(Typography)`
//   color: ${theme.mixins.typography.fontColor.black};
//   font-family: ${theme.mixins.typography.fontFamily};
//   font-size: ${theme.mixins.typography.fontSize.sixteen}px;
//   padding-bottom: ${theme.mixins.spacing}px;
// `;
const Table = styled.table`
  width: 100%;
`;
const Tr = styled.tr`
  width: 100%;
`;
const Td = styled.td`
  max-width: 196px;
  font-size: 14px;
  font-family: ${theme.mixins.typography.fontFamily};
  vertical-align: top;
  ${theme.mixins.textAbbreviated};
`;
const TdNoWrap = styled(Td)`
  width: 68px;
  white-space: nowrap;
`;
const CustomCardContent = styled(CardContent)`
  height: calc(100% - 188px);
  padding: 8px 16px !important;
`;
const QrCodeWrapper = styled.div`
  width: 100%;
  height: 196px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const QrCode = styled.img`
  widdth: 180px;
  height: 180px;
  user-drag: none;
`;
const ShareWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 ${theme.mixins.spacing * 2}px;
`;
const ShareContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const CustomIconShare = styled.img`
  widdth: 30px;
  height: 30px;
  user-drag: none;
`;
const Label = styled.label`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: 14px;
  margin-bottom: 0px;
`;
