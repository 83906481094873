import {
  ButtonOption,
  CommentBox,
  InputBox,
  MultiButtons,
  MultiCheckBox,
  MultiCheckBoxOption,
  RadioBox,
  theme,
  Tips,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  Base,
  Event,
  EventInflowSource,
  EventParticipant,
  EventSchedule,
  ParticipantStatus,
  SaveEventParticipantsRequest,
  User
} from '@atomica.co/irori';
import { builder, Comment, Email, EMPTY, Language, Name, Remarks, toDateTimeStr, toTimeStr } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Screen from '../../../components/screen/Screen';
import { EVENT_JOINED, SUCCESS } from '../../../constants/snackbar-const';
import EventParticipantRequest from '../../../requests/event-participant-request';
import { EVENT_INFLOW_SOURCE_LABELS } from '../../../texts/event-text';
import mojaco_greeting from './../../../assets/mojaco/mojaco_greeting.png';
import ParticipantCards from './participant-card/ParticipantCards';

enum ButtonType {
  JOIN_EVENT = 'join_event'
}

const JOIN_EVENT_BUTTONS: ButtonOption[] = [
  {
    id: ButtonType.JOIN_EVENT,
    isPrimary: true,
    color: theme.mixins.typography.fontColor.white,
    background: theme.mixins.background.pink,
    label: '参加する'
  }
];

const toScheduleOptions = (event: Event, user: User): MultiCheckBoxOption[] => {
  if (!event.schedules) return [];

  return event.schedules.map(schedule => {
    const startAt = new Date(schedule.startAt);
    const endAt = new Date(schedule.endAt);
    const participant = schedule.participants!.find(participant => participant.user.userId === user.userId);

    return {
      id: schedule.eventScheduleId,
      label: `${toDateTimeStr(startAt, Language.JAPANESE)} - ${toTimeStr(endAt)}\n${schedule.name}`,
      checked: !!participant
    };
  });
};

const TIPS_MESSAGE = '参加情報を更新したい方は\nスタッフまでお問い合わせください';

interface P {
  base: Base;
  user: User;
  event: Event;
}

const EventSchedules: React.FC<P> = React.memo(props => {
  const { base, user, event } = props;
  const unmountRef = useUnmountRef();
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [editable, setEditable] = useSafeState<boolean>(unmountRef, false);
  const [scheduleOptions, setScheduleOptions] = useSafeState<MultiCheckBoxOption[]>(
    unmountRef,
    toScheduleOptions(event, user)
  );
  const [schedules, setSchedules] = useSafeState<EventSchedule[]>(unmountRef, event.schedules);
  const [emailToSave, setEmailToSave] = useSafeState<Email>(unmountRef, EMPTY);
  const [inflowSource, setInflowSourceToSave] = useSafeState<EventInflowSource>(unmountRef, EventInflowSource.CONTINUE);
  const [introducerToSave, setIntroducerToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [commentToSave, setCommentToSave] = useSafeState<Comment>(unmountRef, EMPTY);
  const [remarksToSave, setRemarksToSave] = useSafeState<Remarks>(unmountRef, EMPTY);

  const disabledJoinButton = useMemo<boolean>(() => {
    const isScheduleSelected = !!scheduleOptions.find(option => option.checked);
    return !isScheduleSelected || !emailToSave;
  }, [scheduleOptions, emailToSave]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const participants = event.schedules!.map(schedule => schedule.participants).flat();

    const savedOwnParticipantInfo = participants.find(participant => participant!.user.userId === user.userId);

    setEditable(!savedOwnParticipantInfo);
    setEmailToSave(!!savedOwnParticipantInfo ? savedOwnParticipantInfo.email : EMPTY);
    setInflowSourceToSave(
      !!savedOwnParticipantInfo ? savedOwnParticipantInfo.inflowSource : EventInflowSource.CONTINUE
    );
    setIntroducerToSave(!!savedOwnParticipantInfo ? savedOwnParticipantInfo.comment : EMPTY);
    setCommentToSave(!!savedOwnParticipantInfo ? savedOwnParticipantInfo.comment : EMPTY);
    setRemarksToSave(!!savedOwnParticipantInfo ? savedOwnParticipantInfo.remarks : EMPTY);
    setLoaded(true);
  }, [
    event,
    user,
    setEditable,
    setEmailToSave,
    setInflowSourceToSave,
    setIntroducerToSave,
    setCommentToSave,
    setRemarksToSave,
    setLoaded
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveAsJoined = useSafeCallback(async (): Promise<void> => {
    setEditable(false);

    const updatedSchedules: EventSchedule[] = [];
    const participantsToSave: EventParticipant[] = [];
    for (const updatedSchedule of event.schedules!) {
      const isScheduleSelected = !!scheduleOptions.find(
        option => option.checked && option.id === updatedSchedule.eventScheduleId
      );

      if (!isScheduleSelected) {
        updatedSchedules.push(updatedSchedule);
        continue;
      }

      const participantToSave = builder<EventParticipant>()
        .eventParticipantId(EventParticipantRequest.getEventParticipantId())
        .status(ParticipantStatus.PARTICIPATE)
        .schedule({
          eventScheduleId: updatedSchedule.eventScheduleId
        } as EventSchedule)
        .email(emailToSave)
        .inflowSource(inflowSource)
        .comment(commentToSave)
        .remarks(remarksToSave)
        .base(base)
        .user(user)
        .build();

      updatedSchedule.participants = [...updatedSchedule.participants!, participantToSave];
      updatedSchedules.push(updatedSchedule);
      participantsToSave.push(participantToSave);
    }

    setSchedules(updatedSchedules);

    const request = builder<SaveEventParticipantsRequest>().participants(participantsToSave).build();

    await EventParticipantRequest.saveEventParticipants(request);
    enqueueSnackbar(EVENT_JOINED, { variant: SUCCESS });
  }, [
    setEditable,
    event,
    scheduleOptions,
    emailToSave,
    inflowSource,
    commentToSave,
    remarksToSave,
    base,
    user,
    setSchedules,
    enqueueSnackbar
  ]);

  const handleJoinButtonClicked = useSafeCallback(
    (option: ButtonOption): void => {
      switch (option.id) {
        case ButtonType.JOIN_EVENT:
          saveAsJoined();
          break;

        default:
          throw new Error(`${option.id} is out of target.`);
      }
    },
    [saveAsJoined]
  );

  return (
    <Screen loading={!loaded} className='event-schedules'>
      <Container>
        <MojacoWrapper>
          <CommentBox animation photoURL={mojaco_greeting}>
            <MojacoGreeting>
              {!!event ? `こんにちは！\n次回の${event.name}の開催日が決定したよ！\n奮って参加してね✨` : EMPTY}
            </MojacoGreeting>
          </CommentBox>
        </MojacoWrapper>

        {!!event && (
          <Participants>
            {schedules.map((schedule, index) => (
              <ParticipantsPerSchedule key={index}>
                <Label>{`${schedule.name}の参加者一覧`}</Label>

                <ParticipantCards participants={schedule.participants!} />
              </ParticipantsPerSchedule>
            ))}
          </Participants>
        )}

        <Label>参加フォーム</Label>

        <InputWrapper>
          <MultiCheckBox
            required
            editable={editable}
            title='参加希望日時'
            options={scheduleOptions}
            onChange={setScheduleOptions}
          />
        </InputWrapper>

        <InputWrapper>
          <InputBox
            required
            editable={editable}
            type='text'
            label='メールアドレス'
            text={emailToSave}
            onChange={setEmailToSave}
          />
        </InputWrapper>

        {!!event && (
          <InputWrapper>
            <RadioBox
              required
              editable={editable}
              title={`${event.name}を知ったきっかけ`}
              options={Object.values(EventInflowSource)}
              labels={EVENT_INFLOW_SOURCE_LABELS}
              value={inflowSource}
              onChange={setInflowSourceToSave}
            />
          </InputWrapper>
        )}

        {inflowSource === EventInflowSource.FRIEND && (
          <InputWrapper>
            <InputBox
              editable={editable}
              type='text'
              label='紹介者のお名前'
              text={introducerToSave}
              onChange={setIntroducerToSave}
            />
          </InputWrapper>
        )}

        <InputWrapper>
          <InputBox
            multiline
            editable={editable}
            type='text'
            label='質問や要望など'
            text={remarksToSave}
            onChange={setRemarksToSave}
          />
        </InputWrapper>

        <InputWrapper>
          <InputBox
            multiline
            editable={editable}
            type='text'
            label='ひと言（上記の参加者一覧に表示されます）'
            text={commentToSave}
            onChange={setCommentToSave}
          />
        </InputWrapper>

        {!editable && (
          <TipsWrapper>
            <Tips message={TIPS_MESSAGE} />
          </TipsWrapper>
        )}

        <FooterArea>
          {editable && (
            <ButtonWrapper>
              <MultiButtons
                disabled={disabledJoinButton}
                options={JOIN_EVENT_BUTTONS}
                onClick={handleJoinButtonClicked}
              />
            </ButtonWrapper>
          )}
        </FooterArea>
      </Container>
    </Screen>
  );
});

export default EventSchedules;

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const MojacoWrapper = styled.div`
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const MojacoGreeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Participants = styled.div``;

const ParticipantsPerSchedule = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 4}px;
`;

const Label = styled(Typography)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-decoration-line: underline;
  user-select: none;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px 0px;
`;

const TipsWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px;
`;

const FooterArea = styled.div`
  width: 100%;
  height: 64px;
  position: fixed;
  left: 0px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ButtonWrapper = styled.div`
  width: 280px;
  height: 48px;
`;
