import {
  Button,
  Component,
  Modal,
  RadioBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Space, SpaceId, SpaceReservation, SpaceReservationId, User } from '@atomica.co/irori';
import { Option, ZERO } from '@atomica.co/utils';
import { TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { convertToDisabledOption, convertToLabel, convertToOption } from '../../../converters/space-converter';
import { ReservationModalMode } from '../../../enums/space-enum';
import { Labels } from '../../../models/common-model';
import { reservationTimeToStr } from '../../../utils/space-reservation-util';
import ConfirmationModal from './ConfirmationModal';

interface P {
  isOpen: boolean;
  mode: ReservationModalMode;
  remarks: string;
  reservationName: string;
  selectedDate: Date;
  startAt: Date | undefined;
  endAt: Date | undefined;
  spaceReservationIdToUpdate: SpaceReservationId | undefined;
  spaces: Space[];
  spaceIdToReserve: SpaceId | undefined;
  reservations: SpaceReservation[];
  user: User;
  saveSpaceReservation(): Promise<SpaceReservationId | undefined>;
  deleteSpaceReservation(): void;
  setRemarks(remarks: string): void;
  setReservationName(reservationName: string): void;
  setSpaceIdToReserve(spaceId: SpaceId | undefined): void;
  onClose(): void;
}

const ReservationModal: React.FC<P> = React.memo(props => {
  const {
    isOpen,
    mode,
    selectedDate,
    startAt,
    endAt,
    spaceReservationIdToUpdate,
    spaces,
    remarks,
    // reservationName,
    reservations,
    spaceIdToReserve,
    user,
    saveSpaceReservation,
    deleteSpaceReservation,
    // setReservationName,
    setRemarks,
    setSpaceIdToReserve,
    onClose
  } = props;
  const unmountRef = useUnmountRef();
  const [showErrorMessage, setShowErrorMessage] = useSafeState<boolean>(unmountRef, false);
  const [disabledButtons, setDisabledButtons] = useSafeState<boolean>(unmountRef, true);
  // const [errorReservationName, setErrorReservationName] = useSafeState<string>(unmountRef, EMPTY);
  const [isConfirmationOpen, setIsOpenConfirm] = useSafeState<boolean>(unmountRef, false);

  const labels = useMemo<Labels>(() => convertToLabel(spaces), [spaces]);
  const options = useMemo<string[]>(() => convertToOption(spaces), [spaces]);
  const disabledOptions = useMemo<Option[]>(
    () => convertToDisabledOption(spaces, reservations, selectedDate, startAt, endAt, spaceReservationIdToUpdate),
    [spaces, reservations, selectedDate, startAt, endAt, spaceReservationIdToUpdate]
  );
  const selectedSpace = useMemo<Space | undefined>(
    () => spaces.find(space => space.spaceId === spaceIdToReserve),
    [spaces, spaceIdToReserve]
  );

  // const handleChangeReservationName = useSafeCallback(
  //   (event: any): void => {
  //     setErrorReservationName(EMPTY);
  //     setReservationName(event.target.value);
  //   },
  //   [setErrorReservationName, setReservationName]
  // );

  const handleClickSave = useSafeCallback(async (): Promise<void> => {
    setDisabledButtons(true);
    await saveSpaceReservation();
    setDisabledButtons(false);
  }, [setDisabledButtons, saveSpaceReservation]);

  const handleClickDelete = useSafeCallback(async (): Promise<void> => {
    setDisabledButtons(true);
    setIsOpenConfirm(false);
    await deleteSpaceReservation();
    setDisabledButtons(false);
  }, [setDisabledButtons, setIsOpenConfirm, deleteSpaceReservation]);

  const initialize = useSafeCallback((): void => {
    if (!startAt || !endAt || !selectedSpace) return;
    const reservationMinutes = Math.floor((endAt.getTime() - startAt.getTime()) / (1000 * 60));

    if (
      selectedSpace.minimumReservation > reservationMinutes ||
      reservationMinutes % selectedSpace.reservationUnit !== ZERO
    ) {
      setShowErrorMessage(true);
      setDisabledButtons(true);
      return;
    }

    setShowErrorMessage(false);
    setDisabledButtons(false);
  }, [startAt, endAt, selectedSpace, setShowErrorMessage, setDisabledButtons]);

  useEffect(() => {
    if (!isOpen) return;
    initialize();
  }, [isOpen, initialize]);

  return (
    <Component className='reservation-modal'>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Container onClick={onClose}>
          <Frame onClick={e => e.stopPropagation()}>
            <CloseButtonWrapper>
              <CustomCloseIcon onClick={onClose} />
            </CloseButtonWrapper>
            <Content>
              {/* <TextWrapper>
                <CustomInput
                  error={!!errorReservationName}
                  helperText={errorReservationName}
                  placeholder='タイトルと日時を追加'
                  value={reservationName}
                  onChange={handleChangeReservationName}
                  variant='standard'
                />
              </TextWrapper> */}
              <TitleWrapper>
                <Title>予約内容</Title>
              </TitleWrapper>
              <ItemWrapper>
                <ItemName>
                  <CustomScheduleIcon />
                  <Label>{reservationTimeToStr(selectedDate, startAt, endAt)}</Label>
                </ItemName>
              </ItemWrapper>
              <ItemWrapper>
                <ItemName>
                  <CustomRoomOutlinedIcon />
                  <Label>会議室</Label>
                </ItemName>
                <RadioWrapper>
                  <CustomRadio
                    disabledOptions={disabledOptions}
                    options={options}
                    labels={labels}
                    value={spaceIdToReserve}
                    onChange={setSpaceIdToReserve}
                  />
                </RadioWrapper>
              </ItemWrapper>
              <ItemWrapper>
                <ItemRemark>
                  <CustomSubjectOutlinedIcon />
                  <CustomTextField
                    minRows={5}
                    multiline
                    placeholder='質問や要望など'
                    value={remarks}
                    onChange={event => setRemarks(event.target.value)}
                  />
                </ItemRemark>
              </ItemWrapper>
              <ItemWrapper>
                {!!selectedSpace && showErrorMessage && (
                  <ErrorMessage>
                    予約可能時間は{selectedSpace.minimumReservation}分から{selectedSpace.reservationUnit}
                    分刻みのみとなりますので、再度時間の選択をお願いいたします。
                  </ErrorMessage>
                )}
              </ItemWrapper>
              <ButtonWrapper>
                {mode !== ReservationModalMode.READONLY &&
                  (mode === ReservationModalMode.EDIT ? (
                    <>
                      <LeftButtonWrapper>
                        <Button type='primary' shape='rect' disabled={disabledButtons} onClick={handleClickSave}>
                          <Label>予約内容を変更する</Label>
                        </Button>
                      </LeftButtonWrapper>
                      <Button type='secondary' shape='rect' onClick={() => setIsOpenConfirm(true)}>
                        <Label>予約を取り消す</Label>
                      </Button>
                    </>
                  ) : (
                    <Button type='primary' shape='rect' disabled={disabledButtons} onClick={handleClickSave}>
                      <Label>{!!user ? '予約する' : 'ログインして予約する'}</Label>
                    </Button>
                  ))}
              </ButtonWrapper>
            </Content>
          </Frame>
        </Container>
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClick={handleClickDelete}
        onClose={() => setIsOpenConfirm(false)}
      />
    </Component>
  );
});

export default ReservationModal;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Frame = styled.div`
  width: 480px;
  height: auto;
  background: ${theme.mixins.background.lightGray};
  border-radius: 12px;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 3}px;
  display: flex;
  flex-direction: column;

  ${media.lessThan('small')`
    width: 95vw;
    max-height: 80vh;
    overflow-y: auto;
  `}
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing * 2}px;

  ${media.lessThan('small')`
    gap: ${theme.mixins.spacing / 2}px;
  `}
`;
const CloseButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const CustomCloseIcon = styled(CloseIcon)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.mixins.spacing}px;
`;
const Title = styled(Typography)`
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  border-left: 4px solid ${theme.mixins.border.lightGray};
  padding-left: ${theme.mixins.spacing * 2}px;

  ${media.lessThan('small')`
    font-size: ${theme.mixins.typography.fontSize.sixteen}px;
    padding: ${theme.mixins.spacing}px;
  `}
`;
// const TextWrapper = styled.div``;
// const CustomInput = styled(TextField)`
//   width: 100%;
//   font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
//   input {
//     border-bottom: 2px solid ${theme.mixins.border.lightGray};
//   }
// `;
const ItemWrapper = styled.div``;
const ItemName = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${theme.mixins.spacing}px;
`;
const ItemRemark = styled.div`
  display: flex;
  gap: ${theme.mixins.spacing}px;
`;
const CustomScheduleIcon = styled(ScheduleIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
`;
const CustomRoomOutlinedIcon = styled(RoomOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
`;
const CustomSubjectOutlinedIcon = styled(SubjectOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  margin-top: ${theme.mixins.spacing / 4}px;
`;
const Label = styled(Typography)`
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: bold;
`;
const RadioWrapper = styled.div`
  width: 100%;
  height: auto;
`;
const CustomRadio = styled(RadioBox)`
  background: ${theme.mixins.background.lightGray};
`;
const ErrorMessage = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const LeftButtonWrapper = styled.div`
  margin-right: ${theme.mixins.spacing}px;
`;
const CustomTextField = styled(TextField)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  width: 100%;
  padding: 0 !important;
`;
