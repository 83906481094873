import {
  CreateQrCodeForAllSpacesRequest,
  CreateQrCodeForAllSpacesResponse,
  CreateQrCodeForContractedSpacesRequest,
  CreateQrCodeForContractedSpacesResponse,
  CreateQrCodesForEachSpaceReservationRequest,
  CreateQrCodesForEachSpaceReservationResponse,
  CREATE_QRCODES_FOR_EACH_SPACE_RESERVATION,
  CREATE_QRCODE_FOR_ALL_SPACES,
  CREATE_QRCODE_FOR_CONTRACTED_SPACES,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class QrCodeRequest {
  public static createQrCodeForAllSpaces = async (
    request: CreateQrCodeForAllSpacesRequest
  ): Promise<CreateQrCodeForAllSpacesResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(CREATE_QRCODE_FOR_ALL_SPACES));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static createQrCodeForContractedSpaces = async (
    request: CreateQrCodeForContractedSpacesRequest
  ): Promise<CreateQrCodeForContractedSpacesResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(CREATE_QRCODE_FOR_CONTRACTED_SPACES));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static createQrCodesForEachSpaceReservation = async (
    request: CreateQrCodesForEachSpaceReservationRequest
  ): Promise<CreateQrCodesForEachSpaceReservationResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(CREATE_QRCODES_FOR_EACH_SPACE_RESERVATION));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
