export const SUCCESS = 'success';

export const ERROR = 'error';

export const CONTRACT_UPDATED = '契約内容を更新しました';

export const CONTRACT_DELETED = '契約を削除しました';

export const EMAIL_SENT = 'メールを送信しました';

export const ACCESS_RECORDED = '入退室を記録しました';

export const SHOT_USAGE_SAVED = '入力内容を登録しました';

export const SHOT_USAGE_UPDATED = '入力内容を更新しました';

export const SHOT_USAGE_DELETED = '入力内容を取り消しました';

export const REGISTERED_AS_EVENT_WAITING = '開催通知をONにしました';

export const EVENT_JOINED = '参加申し込みが完了しました';

export const USER_NO_SELECTED = '対象のユーザーを選択してください';

export const USER_AUTH_SAVED = '権限を変更しました';

export const USER_AUTH_ERROR = '権限の変更に失敗しました';

export const SPACE_RESERVE_ERROR = '会議室の予約に失敗しました';

export const SPACE_RESERVE_SAVED = '会議室の予約が完了しました';

export const SPACE_RESERVE_UPDATED = '会議室の予約内容を更新しました';

export const SPACE_RESERVE_DELETED = '会議室の予約を取り消しました';

export const QFACE_SET_ERROR = '写真の更新に失敗しました';

export const QFACE_SET_SAVED = '写真の更新が完了しました';

export const INVITATION_LINK_COPIED = '招待リンクをコピーしました';
