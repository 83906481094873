import { useSafeCallback } from '@atomica.co/components';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Screen from '../../components/screen/Screen';
import { auth } from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import { persistor } from '../../redux/store';
import { Path } from '../../router/Routes';

interface P extends RouteComponentProps {}

const SignOutScreen: React.FC<P> = React.memo(() => {
  const { openBasePath } = usePath();

  const signOut = useSafeCallback(async (): Promise<void> => {
    await auth.signOut();
    await persistor.purge();
    openBasePath(Path.SIGN_IN);
  }, [openBasePath]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <Screen className='sign-out-screen' />;
});

export default SignOutScreen;
