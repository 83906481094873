import {
  Button,
  Component,
  InputBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, BASE_CODE, DOMAINS, ErrorCodeEnum, ERROR_MESSAGES, FetchBaseRequest } from '@atomica.co/irori';
import { BCC_EMAIL, builder, Email, EMAIL_CONST, embedIdInPath, EMPTY, Message, MINUS_ONE } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CASHED_URL } from '../../../../../constants/common-const';
import env from '../../../../../env/env';
import firebase, { auth } from '../../../../../firebase';
import useCashedURL from '../../../../../redux/hooks/useCashedURL';
import usePath from '../../../../../redux/hooks/usePath';
import BaseRequest from '../../../../../requests/base-request';
import { Path } from '../../../../../router/Routes';

interface P {
  onSendEmail(email: Email): void;
}

const EmailSignUp: React.FC<P> = React.memo(props => {
  const { onSendEmail } = props;
  const { params } = usePath();
  const { cashedURL } = useCashedURL();
  const unmountRef = useUnmountRef();
  const [base, setBase] = useSafeState<BaseDto | undefined>(unmountRef);
  const [disabledSendButton, setDisabledSendButton] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const baseCode = params[BASE_CODE];
    if (!baseCode) return;
    const request = builder<FetchBaseRequest>().baseCode(baseCode).build();
    const response = await BaseRequest.fetchBase(request);
    const base = response.base;
    setBase(base);
  }, [params, setBase]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    setDisabledSendButton(!email);
  }, [setDisabledSendButton, email]);

  const constructErrorMessage = useSafeCallback(
    (code: ErrorCodeEnum): void => {
      if (!code) return;

      Object.entries(ERROR_MESSAGES)
        .filter(([key, value]) => code.toString().indexOf(key) !== MINUS_ONE)
        .forEach(([key, value]) => setErrorMessage(value));
    },
    [setErrorMessage]
  );

  const createUserWithEmail = useSafeCallback(async (): Promise<void> => {
    if (!base) return;

    if (!email) {
      constructErrorMessage(ErrorCodeEnum.INVALID_EMAIL);
      return;
    }

    setDisabledSendButton(true);

    const redirectUrl = `https://${DOMAINS[env]}${embedIdInPath(
      Path.REGISTER_ACCOUNT,
      [BASE_CODE],
      [base.baseCode]
    )}?${EMAIL_CONST}=${email}&${CASHED_URL}=${cashedURL}`;

    const settings: firebase.auth.ActionCodeSettings = {
      handleCodeInApp: true,
      url: redirectUrl
    };

    auth
      .sendSignInLinkToEmail(email, settings)
      .then(() => {
        auth.sendSignInLinkToEmail(BCC_EMAIL, settings);
        onSendEmail(email);
      })
      .catch(err => {
        setDisabledSendButton(false);
        constructErrorMessage(err.code);
      });
  }, [base, setDisabledSendButton, email, cashedURL, constructErrorMessage, onSendEmail]);

  return (
    <Component className='email-sign-up'>
      <Container>
        <InputWrapper>
          <InputBox type='email' label='Email' text={email} onChange={setEmail} />

          <ErrorMessage>{errorMessage}</ErrorMessage>
        </InputWrapper>

        <ButtonWrapper>
          <Button type='primary' disabled={disabledSendButton} onClick={createUserWithEmail}>
            <IconWrapper>
              <MailIcon fontSize='small' />
            </IconWrapper>

            <Label>メールアドレスで登録</Label>
          </Button>
        </ButtonWrapper>
      </Container>
    </Component>
  );
});

export default EmailSignUp;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const ErrorMessage = styled(Typography)`
  width: 100%;
  height: auto;
  min-height: 18px;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding: ${theme.mixins.spacing / 2}px 0px 0px ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled(Typography)`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
