import { FetchBaseRequest, FetchBaseResponse, FETCH_BASE, toFuncName } from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class BaseRequest {
  public static fetchBase = async (request: FetchBaseRequest): Promise<FetchBaseResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BASE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
