import {
  CircularLoader,
  Component,
  SearchBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, SearchSpacesRequest, Space, SpaceId, User } from '@atomica.co/irori';
import { builder, EMPTY, hasLength, isMoreThanZero } from '@atomica.co/utils';
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { CheckCircleRounded } from '@material-ui/icons';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { SearchOption } from '../../../enums/space-enum';
import SpaceRequest from '../../../requests/space-request';
import AvailableTimeTable from './AvailableTimeTable';

interface P {
  base: BaseDto;
  user: User | undefined;
  selectedSpaceIds: SpaceId[];
  spaces: Space[];
  setSpaces: (spaces: Space[]) => void;
  handleClick: (spacesId: SpaceId) => void;
}

const SpaceList: React.FC<P> = React.memo(props => {
  const { base, user, selectedSpaceIds, spaces, setSpaces, handleClick } = props;
  const unmountRef = useUnmountRef();
  const [isSearching, setIsSearching] = useSafeState<boolean>(unmountRef, false);
  const [inputVal, setInputVal] = useSafeState<string>(unmountRef, EMPTY);

  const searchSpaces = useSafeCallback(async (): Promise<void> => {
    setIsSearching(true);

    const personInCharge = !!user && hasLength(user.personInCharge) ? user.personInCharge![0] : undefined;
    const authorityId =
      !!personInCharge && !!personInCharge.authority ? personInCharge.authority.authorityId : undefined;

    const { LIMIT, OFFSET } = SearchOption;
    const request = builder<SearchSpacesRequest>()
      .base(base)
      .authorityId(authorityId!)
      .limit(LIMIT)
      .offset(OFFSET)
      .word(inputVal)
      .build();
    const response = await SpaceRequest.fetchSpaces(request);

    setSpaces(response.spaces);
    setIsSearching(false);
  }, [setIsSearching, user, base, inputVal, setSpaces]);

  useEffect(() => {
    searchSpaces();
  }, [searchSpaces]);

  return (
    <Component className='space-list'>
      <Container>
        <Content>
          <ItemNameWrapper>
            <ItemName>1. 会議室の選択（1/3）</ItemName>
            <SearchBoxWrapper>
              <SearchBox type='text' placeholder='検索' text={inputVal} onChange={setInputVal} />
            </SearchBoxWrapper>
          </ItemNameWrapper>

          <Description>空き状況を確認したい会議室を選択してください（複数選択可）</Description>

          {isSearching && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}

          {!isSearching && (
            <SpacesWrapper>
              {!!spaces &&
                Object.values(spaces).map((space, idx) => (
                  <CardWrapper key={idx}>
                    <CustomCard onClick={() => handleClick(space.spaceId)}>
                      <IconWrapper>
                        <CustomCheckIcon fontSize='large' checked={selectedSpaceIds.includes(space.spaceId)} />
                      </IconWrapper>
                      <SpacePhoto component='img' image={space.photoURL} alt='Paella dish' />
                      <CardDetails>
                        <Table>
                          <tbody>
                            <Tr>
                              <Td colSpan='3'>
                                <SpaceLabel>{space.name}</SpaceLabel>
                                <Spacer />
                              </Td>
                            </Tr>
                            <AvailableTimeTable availabilities={space.availabilities} />
                            <Tr>
                              <Td colSpan='3'>最大収容人数</Td>
                            </Tr>
                            <Tr>
                              <Td colSpan='3'>
                                {`${space.maximumCapacity}名程度`}
                                <Spacer />
                              </Td>
                            </Tr>
                            {!!space.pricePerHour && isMoreThanZero(space.pricePerHour) && (
                              <>
                                <Tr>
                                  <Td colSpan='3'>料金</Td>
                                </Tr>
                                <Tr>
                                  <Td colSpan='3'>{`￥${space.pricePerHour.toLocaleString()} / 時間`}</Td>
                                </Tr>
                              </>
                            )}
                          </tbody>
                        </Table>
                        <SpaceDetailLabel></SpaceDetailLabel>
                      </CardDetails>
                    </CustomCard>
                  </CardWrapper>
                ))}
            </SpacesWrapper>
          )}
        </Content>
      </Container>
    </Component>
  );
});

export default SpaceList;

const Container = styled.div`
  width: 100%;
  height: auto;
  background: ${theme.mixins.background.white};
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 3}px;
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing}px;

  ${media.lessThan('small')`
    padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
  `}
`;

const ItemNameWrapper = styled.div`
  display: flex;
  align-items: center;

  ${media.lessThan('small')`
    padding: 0px ${theme.mixins.spacing}px;
`}
`;

const ItemName = styled(Typography)`
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  border-left: 4px solid ${theme.mixins.border.lightGray};
  padding-left: ${theme.mixins.spacing * 2}px;

  ${media.lessThan('small')`
    font-size: 14px;
    padding: ${theme.mixins.spacing}px;
  `}
`;

const SearchBoxWrapper = styled.div`
  margin-left: auto;

  ${media.lessThan('small')`
    width: 150px;
    input {
      font-size: 14px;
    }
  `}
`;

const Description = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;

  ${media.lessThan('small')`
    font-size: 14px;
    padding: 0px ${theme.mixins.spacing}px;
  `}
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpacesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  justify-items: center;
  gap: ${theme.mixins.spacing}px;

  ${media.lessThan('small')`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `};
`;
const CardWrapper = styled.div`
  width: 340px;
  position: relative;
  color: white;

  ${media.lessThan('small')`
    width: 165px;
  `};
`;
const CustomCard = styled(Card)`
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;
const IconWrapper = styled.div`
  position: absolute;
  top: ${theme.mixins.spacing * 2}px;
  right: ${theme.mixins.spacing * 2}px;
`;
const SpacePhoto = styled(CardMedia)`
  height: 188px;

  ${media.lessThan('small')`
    height: 120px;
  `};
`;
const CardDetails = styled(CardContent)`
  padding: ${theme.mixins.spacing}px !important;
`;
const CustomCheckIcon = styled(CheckCircleRounded)`
  color: ${({ checked }) => (checked ? theme.mixins.background.pink : theme.mixins.background.gray)};
  background: ${theme.mixins.background.white};
  border-radius: 50%;
`;
const SpaceLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
`;
const SpaceDetailLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: 14px;
`;
const Table = styled.table``;
const Tr = styled.tr``;
const Td = styled.td`
  font-size: 14px;
  font-family: ${theme.mixins.typography.fontFamily};
  padding: 0 ${theme.mixins.spacing * 2}px 0 0;
  vertical-align: top;
`;
const Spacer = styled.div`
  padding-bottom: ${theme.mixins.spacing}px;
`;
