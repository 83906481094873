import {
  SAVE_SPACE_PARTICIPANT,
  SaveSpaceParticipantRequest,
  SaveSpaceParticipantResponse,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class SpaceParticipantRequest {
  public static saveSpaceParticipant = async (
    request: SaveSpaceParticipantRequest
  ): Promise<SaveSpaceParticipantResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_SPACE_PARTICIPANT));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
