import { Component, Skeleton, theme } from '@atomica.co/components';
import { SpaceReservation } from '@atomica.co/irori';
import { toUTC } from '@atomica.co/utils';
import { Card } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React from 'react';
import styled from 'styled-components';
import {
  BOTTOM_NAGIGATOR_HEIGHT,
  DISPLAY_AVATOR_COUNT,
  SUB_BUTTON_HEIGHT
} from '../../../../constants/my-account-consts';
import { SpaceReservationsGrid } from '../../../../converters/my-account-converter';
import { reservationTimeToStr } from '../../../../utils/space-reservation-util';
import mojaco from './../../../../assets/mojaco/mojaco_break.png';

interface P {
  spaceReservationsGrid: SpaceReservationsGrid;
  onClickCard: (spaceReservation: SpaceReservation) => void;
}

const ReservationGrid: React.FC<P> = React.memo(props => {
  const { spaceReservationsGrid, onClickCard } = props;

  return (
    <Component className='reservation-grid'>
      <Container>
        <Content paddingBottom={BOTTOM_NAGIGATOR_HEIGHT + SUB_BUTTON_HEIGHT}>
          {Object.keys(spaceReservationsGrid).map((reservationDay, keyIdx) => (
            <SpaceReservationsOfDayWrapper key={keyIdx}>
              <LabelWrapper>
                <Label>{reservationDay}</Label>
              </LabelWrapper>
              {spaceReservationsGrid[reservationDay].map((spaceReservation, rsvIdx) => {
                const {
                  // reservationName,
                  space,
                  startAt,
                  endAt,
                  participants
                } = spaceReservation;
                const startAtUTC = toUTC(new Date(startAt));
                const endAtUTC = toUTC(new Date(endAt));
                const isAvatarOmited = participants.length > DISPLAY_AVATOR_COUNT;
                const displayParticipants = isAvatarOmited
                  ? participants.concat().reverse().slice(-DISPLAY_AVATOR_COUNT)
                  : participants.concat().reverse();

                return (
                  <SpaceReservationCard
                    key={rsvIdx}
                    onClick={() => {
                      onClickCard(spaceReservation);
                    }}
                  >
                    <Label>{space.name}</Label>
                    <CardBody>
                      <InformationWrapper>
                        {/* <InformationRow>
                          <CustomRoomOutlinedIcon />
                          <IconLabel>{space.name}</IconLabel>
                        </InformationRow> */}
                        <InformationRow>
                          <CustomScheduleIcon />
                          <IconLabel>{reservationTimeToStr(startAtUTC, startAtUTC, endAtUTC)}</IconLabel>
                        </InformationRow>
                      </InformationWrapper>
                      <ParticipantWrapper>
                        {isAvatarOmited && <CustomMoreHorizIcon />}
                        {displayParticipants.map((participant, participantIdx) => (
                          <SkeletonWrapper key={participantIdx}>
                            <Skeleton style={styleForPhoto} src={participant.user.photoURL || mojaco} />
                          </SkeletonWrapper>
                        ))}
                      </ParticipantWrapper>
                    </CardBody>
                  </SpaceReservationCard>
                );
              })}
            </SpaceReservationsOfDayWrapper>
          ))}
        </Content>
      </Container>
    </Component>
  );
});

export default ReservationGrid;

const Container = styled.div`
  width: 95%;
  height: auto;
  margin: 0 auto;
`;
const Content = styled.div`
  width: 100%;
  height: auto;
  padding-top: ${theme.mixins.spacing * 2}px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || 0}px;
`;
const SpaceReservationsOfDayWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  * {
    cursor: pointer;
  }
`;

const LabelWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;
const Label = styled.label`
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  margin: 0;
`;
const CardBody = styled.div`
  display: flex;
  align-items: flex-end;
`;
const SpaceReservationCard = styled(Card)`
  margin-bottom: ${theme.mixins.spacing}px;
  padding: ${theme.mixins.spacing}px;
`;
const InformationWrapper = styled.div``;

const InformationRow = styled.div`
  display: flex;
`;
const IconLabel = styled.label`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: 14px;
  margin: 0;
`;

const CustomScheduleIcon = styled(ScheduleIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
`;
// const CustomRoomOutlinedIcon = styled(RoomOutlinedIcon)`
//   color: ${theme.mixins.typography.fontColor.lightGray};
//   font-size: ${theme.mixins.typography.fontSize.twenty}px;
// `;
const CustomMoreHorizIcon = styled(MoreHorizIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: 28px;
  margin-right: ${theme.mixins.spacing * 2}px;
`;

const ParticipantWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: flex-end;
`;
const SkeletonWrapper = styled.div`
  margin-left: -${theme.mixins.spacing * 2}px;
`;

const styleForPhoto: CSSProperties = {
  width: 40,
  height: 40,
  transform: 'none',
  background: theme.mixins.background.lightGray,
  border: `1px solid ${theme.mixins.border.lightGray}`,
  borderRadius: '50%',
  objectFit: 'contain'
};
