import { EMPTY } from '@atomica.co/utils';
import { format } from 'date-fns';
import jaLocale from 'date-fns/locale/ja';

export const reservationTimeToStr = (day?: Date, startAt?: Date, endAt?: Date): string => {
  if (!day || !startAt || !endAt) return EMPTY;
  return `${format(day, 'M月d日 (E) ', { locale: jaLocale })} ${format(startAt, 'HH:mm', {
    locale: jaLocale
  })} - ${format(endAt, 'HH:mm', { locale: jaLocale })}`;
};

export const dueTimeToStr = (date?: Date): string => {
  if (!date) return EMPTY;
  return `${format(date, 'yyyy年M月d日 (E) HH:mm', { locale: jaLocale })} 迄`;
};
