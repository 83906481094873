import { MasterSearchOption } from '@atomica.co/components';
import {
  Contract,
  Space,
  SpaceAvailability,
  SpaceId,
  SpaceParticipant,
  SpaceParticipantDiv,
  SpaceReservation,
  SpaceReservationId,
  User
} from '@atomica.co/irori';
import { EMPTY, hasLength, Option, toUTC } from '@atomica.co/utils';
import { DAY_OF_WEEK_IDX, EVENT_PARTICIPANT_DIV_COLOR } from '../constants/calendar-consts';
import { DISABLE_DATE_TIME, THIRTY_MINUTES, TIMELINE_UNIT, WEEKDAY, ZERO_MINUTES } from '../constants/space-const';
import { AvailableTime, Labels } from '../models/common-model';

interface AvailableTimeRow {
  time: string;
  weekdayIdx: number[];
}

interface BusinessHour {
  daysOfWeek: Array<number>;
  startTime: string | undefined;
  endTime: string | undefined;
}

export interface SpaceResourceRow {
  id: SpaceId;
  order: number;
  title: string;
  businessHours: BusinessHour;
}

export interface TimelineEventRow {
  resourceId: SpaceId;
  id: SpaceReservationId;
  title: string;
  color: string;
  start: Date | undefined;
  end: Date | undefined;
}

const isAfter = (baseTime: Date | string | undefined, targetTime: Date | string | undefined): boolean => {
  if (!baseTime || !targetTime) return false;
  const baseHour = typeof baseTime === 'string' ? Number(baseTime.substring(0, 2)) : baseTime.getHours();
  const baseMinutes = typeof baseTime === 'string' ? Number(baseTime.substring(3, 4)) : baseTime.getMinutes();
  const targetHour = typeof targetTime === 'string' ? Number(targetTime.substring(0, 2)) : targetTime.getHours();
  const targetMinutes = typeof targetTime === 'string' ? Number(targetTime.substring(3, 4)) : targetTime.getMinutes();

  return baseHour < targetHour || (baseHour === targetHour && baseMinutes <= targetMinutes);
};

export const convertToAvailableTimeLabels = (availabilities: SpaceAvailability[] | undefined): AvailableTime[] => {
  if (!availabilities) return [];
  const businessDay = availabilities.reduce((prev: AvailableTimeRow[], current: SpaceAvailability) => {
    const startAt = `${Number(current.startAt?.slice(0, 2))}${current.startAt?.slice(2, 5)}`;
    const endAt = `${Number(current.endAt?.slice(0, 2))}${current.endAt?.slice(2, 5)}`;
    const businessTime = `${startAt} - ${endAt}`;
    const dayOfWeek = current.dayOfWeek;

    if (!hasLength(prev)) {
      prev.push({ time: businessTime, weekdayIdx: [dayOfWeek] });
      return prev;
    }

    const timeExistIdx = prev.findIndex(row => businessTime.includes(row.time));
    if (timeExistIdx >= 0) {
      prev[timeExistIdx].weekdayIdx.push(dayOfWeek);
    } else {
      prev.push({ time: businessTime, weekdayIdx: [dayOfWeek] });
    }
    return prev;
  }, []);

  const rows = businessDay.map((row: AvailableTimeRow) => {
    let aggregateFlag = false;
    return {
      time: row.time,
      weekday: row.weekdayIdx.reduce((prev: string, curr: number, idx: number, base: number[]) => {
        const weekday = WEEKDAY[curr];
        if (!prev) {
          prev = weekday;
          aggregateFlag = false;
          return prev;
        }

        if (curr === base[idx - 1] + 1) {
          prev = aggregateFlag ? `${prev.slice(0, -1)}${weekday}` : `${prev}～${weekday}`;
          aggregateFlag = true;
        } else {
          prev += ` ${weekday}`;
          aggregateFlag = false;
        }

        return prev;
      }, EMPTY)
    };
  });

  return rows;
};

export const convertToTimelineResources = (
  spaces: Space[] | undefined,
  selectedSpaceIds: SpaceId[] | undefined,
  selectedDate: Date
): SpaceResourceRow[] => {
  if (!spaces || !selectedDate) return [];
  const newSelectedDate = new Date(selectedDate);
  const targetWeekday = newSelectedDate.getDay();
  const targetWeek = Math.floor((newSelectedDate.getDate() - newSelectedDate.getDay() + 12) / 7).toString();

  const resources = spaces.reduce((prev: SpaceResourceRow[], current: Space) => {
    if (!selectedSpaceIds?.includes(current.spaceId)) return prev;
    const availability = current.availabilities?.find((availability: SpaceAvailability) => {
      return (
        (availability.weeks === 0 || availability.weeks.toString().indexOf(targetWeek) !== -1) &&
        availability.dayOfWeek === targetWeekday
      );
    });
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    const now = new Date();
    const isPast = newSelectedDate < today;
    const isFuture = today < newSelectedDate;
    prev.push({
      id: current.spaceId,
      order: current.order,
      title: current.name,
      businessHours: {
        daysOfWeek: DAY_OF_WEEK_IDX,
        startTime:
          !!availability && isFuture
            ? availability.startAt
            : !availability || isPast
            ? DISABLE_DATE_TIME
            : isAfter(availability.startAt, now)
            ? `${now.getHours()}:${now.getMinutes() > TIMELINE_UNIT ? THIRTY_MINUTES : ZERO_MINUTES}`
            : availability.startAt,
        endTime: !!availability && !isPast ? availability.endAt : DISABLE_DATE_TIME
      }
    });
    return prev;
  }, []);

  return resources;
};

export const convertToTimelineEvents = (
  reservations: SpaceReservation[] | undefined,
  selectedSpaceIds: SpaceId[] | undefined,
  user: User
): TimelineEventRow[] => {
  if (!reservations || !hasLength(reservations)) return [];
  const selectedReservation = reservations.filter((reservation: SpaceReservation) =>
    selectedSpaceIds?.find(selectedSpaceId => selectedSpaceId === reservation.space.spaceId)
  );
  const events = selectedReservation.map((reservation: SpaceReservation) => {
    const participant =
      user && reservation.participants.find((participant: SpaceParticipant) => participant.user.userId === user.userId);
    return {
      resourceId: reservation.space.spaceId,
      id: reservation.spaceReservationId,
      title: participant ? '自分の予定' : '予定あり',
      color: participant
        ? participant.participantDiv === SpaceParticipantDiv.MEMBER
          ? EVENT_PARTICIPANT_DIV_COLOR[SpaceParticipantDiv.MEMBER]
          : EVENT_PARTICIPANT_DIV_COLOR[SpaceParticipantDiv.OWNER]
        : EVENT_PARTICIPANT_DIV_COLOR.default,
      start: toUTC(new Date(reservation.startAt)),
      end: toUTC(new Date(reservation.endAt))
    };
  });
  return events;
};

export const convertToOption = (spaces: Space[]): Option[] => {
  if (!hasLength(spaces)) return [];
  return spaces.map(space => space.spaceId);
};

export const convertToDisabledOption = (
  spaces: Space[],
  reservations: SpaceReservation[],
  selectedDate: Date | undefined,
  reservationStartAt: Date | undefined,
  reservationEndAt: Date | undefined,
  spaceReservationIdToUpdate: SpaceReservationId | undefined
): Option[] => {
  if (!hasLength(spaces) || !selectedDate || !reservationStartAt || !reservationEndAt) return [];

  const targetWeekday = selectedDate.getDay();
  const targetWeek = Math.floor((selectedDate.getDate() - selectedDate.getDay() + 12) / 7).toString();

  return spaces.reduce((prev: Option[], current: Space) => {
    const reserveMinutes = Math.floor((reservationEndAt.getTime() - reservationStartAt.getTime()) / (1000 * 60));
    if (current.minimumReservation > reserveMinutes) {
      prev.push(current.spaceId);
      return prev;
    }

    const availability = current.availabilities?.find(
      (availability: SpaceAvailability) =>
        (availability.weeks === 0 || availability.weeks.toString().indexOf(targetWeek) !== -1) &&
        availability.dayOfWeek === targetWeekday
    );
    if (!availability) {
      prev.push(current.spaceId);
      return prev;
    }
    const availabilityStartAt = availability.startAt;
    const availabilityEndAt = availability.endAt;

    if (!isAfter(availabilityStartAt, reservationStartAt)) {
      prev.push(current.spaceId);
      return prev;
    }

    if (!isAfter(reservationEndAt, availabilityEndAt)) {
      prev.push(current.spaceId);
      return prev;
    }

    const hasAlreadyReserved = reservations.find(reservation => {
      const reservedStartAt = toUTC(new Date(reservation.startAt.getTime()));
      const reservedEndAt = toUTC(new Date(reservation.endAt.getTime()));
      return spaceReservationIdToUpdate === reservation.spaceReservationId
        ? false
        : reservation.space.spaceId === current.spaceId &&
            reservedStartAt &&
            reservedEndAt &&
            ((reservedStartAt <= reservationStartAt && reservationStartAt < reservedEndAt) ||
              (reservedStartAt < reservationEndAt && reservationEndAt <= reservedEndAt) ||
              (reservedStartAt < reservationStartAt && reservationEndAt < reservedEndAt));
    });

    if (hasAlreadyReserved) {
      prev.push(current.spaceId);
      return prev;
    }

    return prev;
  }, []);
};

export const convertToLabel = (spaces: Space[]): Labels => {
  if (!hasLength(spaces)) return {};
  return spaces.reduce((prev, current) => {
    prev[current.spaceId] = current.name;
    return prev;
  }, {});
};

const toMasterSearchOption = (space: Space): MasterSearchOption => {
  return { value: space.spaceId, label: space.name };
};

export const toMasterSearchOptions = (spaces: Space[]): MasterSearchOption[] => {
  return spaces.map(toMasterSearchOption);
};

export const setContractId = (space: Space, contract: Contract): Space => {
  space.contract = contract;
  return space;
};
