import { Component, theme } from '@atomica.co/components';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface P {
  title: string;
  text: string;
}

const Notion: React.FC<P> = React.memo(props => {
  const { title, text } = props;
  return (
    <Component className='notion'>
      <Container>
        <Content>
          <NotionFrame>
            <NotionTitle>{title}</NotionTitle>
            <NotionText>{text}</NotionText>
          </NotionFrame>
        </Content>
      </Container>
    </Component>
  );
});

export default Notion;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.mixins.spacing}px;
`;

const NotionFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.mixins.spacing * 3}px;
  background: ${theme.mixins.background.white};
  gap: ${theme.mixins.spacing * 2}px;

  ${media.lessThan('small')`
  gap: ${theme.mixins.spacing}px;
  padding: ${theme.mixins.spacing * 2}px;
  `}
`;

const NotionTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  border-left: 4px solid ${theme.mixins.border.lightGray};
  padding-left: ${theme.mixins.spacing * 2}px;

  ${media.lessThan('small')`
    font-size: 14px;
    line-height: 18px;
    padding: ${theme.mixins.spacing}px;
  `}
`;

const NotionText = styled(Typography)`
  font-family: ${theme.mixins.typography.fontFamily};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  line-height: 26px;
  white-space: pre-wrap;

  ${media.lessThan('small')`
    font-size: ${theme.mixins.typography.fontSize.twelve}px;
    line-height: 18px;
  `}
`;
