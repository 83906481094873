import {
  SAVE_PERSON_IN_CHARGE,
  SavePersonInChargeRequest,
  SavePersonInChargeResponse,
  toFuncName
} from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class PersonInChargeRequest {
  public static savePersonInCharge = async (
    request: SavePersonInChargeRequest
  ): Promise<SavePersonInChargeResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_PERSON_IN_CHARGE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
