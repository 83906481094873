import { Base } from '@atomica.co/irori';

export const GUIDANCE_URL = {
  [Base.MIYAZAKI]: 'https://atomica.site/base/guidance/miyazaki',
  [Base.NOBEOKA]: 'https://atomica.site/base/guidance/nobeoka',
  [Base.KITAKYUSHU]: 'https://atomica.site/base/guidance/kitakyushu',
  [Base.KYUKODAI]: 'https://atomica.site/base/guidance/kyukodai',
  [Base.KAGOSHIMA]: 'https://atomica.site/base/guidance/kagoshima'
};

export const STORAGE_FACE_RECOGNITION_PHOTOS_PATH = 'faceRecognitionPhotos';
