import { Component, InputBox, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Base, Contract, SearchContractsRequest } from '@atomica.co/irori';
import { builder, embedIdInPath, EMPTY, Language, Name, toDateStr } from '@atomica.co/utils';
import {
  Button as MuiButton,
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import ContractRequest from '../../requests/contract-request';
import { Path, PATH_IDS } from '../../router/Routes';
import { default as PrimaryRow } from './contract-row/ContractRow';

interface P {
  base: Base;
  onChange(path: Path): void;
}

const ContractListScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const { onChange } = props;
  const unmountRef = useUnmountRef();
  const [contractName, setContractName] = useSafeState<Name>(unmountRef, EMPTY);
  const [contracts, setContracts] = useSafeState<Contract[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const request = builder<SearchContractsRequest>().base(base).word(contractName).build();

    const response = await ContractRequest.searchContracts(request);
    setContracts(response.contracts);
  }, [base, contractName, setContracts]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const openContractDetailsScreen = useSafeCallback(
    (contract: Contract): void => {
      onChange(embedIdInPath(Path.CONTRACT_DETAILS, PATH_IDS, [contract.base, contract.contractId]));
    },
    [onChange]
  );

  const openRegisterContractScreen = useSafeCallback((): void => {
    onChange(embedIdInPath(Path.REGISTER_CONTRACT, PATH_IDS, [base]));
  }, [onChange, base]);

  return (
    <Component className='contract-list-screen'>
      <Container>
        <Content>
          <SubTitle>契約一覧</SubTitle>

          <Title>Contracts</Title>

          <InputWrapper>
            <InputBox type='text' label='契約名' text={contractName} onChange={setContractName} />
          </InputWrapper>

          <ListWrapper>
            <List>
              {contracts.map((contract, index) => (
                <ListItem key={index} onClick={() => openContractDetailsScreen(contract)}>
                  <ListItemIcon>
                    <DescriptionOutlinedIcon />
                  </ListItemIcon>

                  <ListItemText
                    primary={<PrimaryRow contract={contract} />}
                    secondary={
                      <SecondaryRow>
                        契約期間：
                        {toDateStr(new Date(contract.startDate), Language.JAPANESE)} ~{' '}
                        {!!contract.endDate ? toDateStr(new Date(contract.endDate), Language.JAPANESE) : EMPTY}
                      </SecondaryRow>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </ListWrapper>

          <ButtonWrapper>
            <Button disableRipple onClick={openRegisterContractScreen}>
              <AddIcon />
            </Button>
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default ContractListScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  margin: ${theme.mixins.spacing * 4}px 0px 0px ${theme.mixins.spacing * 3}px;
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 56px;
  margin: 0px 0px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
  cursor: pointer;
`;

const List = styled(MuiList)`
  background: ${theme.mixins.background.white};
  border-radius: ${theme.mixins.spacing / 2}px;
  padding: 0px;
`;

const SecondaryRow = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  right: 32px;
  bottom: 32px;
`;

const Button = styled(MuiButton)`
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${theme.mixins.background.white} !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`;
