import { EnvDef, hasLength, REGION } from '@atomica.co/utils';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/storage';
import { getFirebaseConfig } from './config';
import env from '../env/env';

if (!hasLength(firebase.apps)) {
  const config = getFirebaseConfig();
  firebase.initializeApp(config);
}

export default firebase;

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const storage = firebase.storage();

export const functions = firebase.app().functions(REGION);

export const performance = firebase.performance();

export const analytics = firebase.analytics();

export const database = firebase.database();

export const initAnalytics = (user: firebase.User) => {
  analytics.setUserId(user.uid);
  analytics.setUserProperties({ userId: user.uid, userName: user.displayName });
};

// ローカルEmulator設定
if (String(env) === EnvDef.LOCAL) {
  auth.useEmulator('http://localhost:9099');
  firestore.useEmulator('localhost', 8080);
  storage.useEmulator('localhost', 9199);
  functions.useEmulator('localhost', 5001);
  database.useEmulator('localhost', 9000);
}
