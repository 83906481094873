import { CreateQFaceStrRequest, CreateQFaceStrResponse, CREATE_QFACE_STR, toFuncName } from '@atomica.co/irori';
import { convert, RequestType } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class QFaceRequest {
  public static create = async (request: CreateQFaceStrRequest): Promise<CreateQFaceStrResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(CREATE_QFACE_STR));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
