import {
  Button,
  Component,
  DateBox,
  InputBox,
  MasterSearchBox,
  MasterSearchOption,
  RadioBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  Base,
  BaseDto,
  Contract,
  ContractPaymentMethod,
  ContractStatus,
  InflowSource,
  PaymentType,
  SaveContractRequest,
  SaveSpaceRequest,
  SearchSpacesForContractRequest,
  SendInvitationToContractMembersRequest,
  Space,
  UseAddress
} from '@atomica.co/irori';
import {
  Amount,
  builder,
  Count,
  Email,
  embedIdInPath,
  EMPTY,
  hasLength,
  Month,
  Name,
  noop,
  Option,
  Remarks,
  uuid,
  Width,
  ZERO
} from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  ContractPlan,
  ContractPlanLabelByBase,
  CONTRACT_ADDRESS_PRICE,
  CONTRACT_KEY_ISSUE_PRICE,
  CONTRACT_LOCKER_PRICE,
  toContractPlanPrice,
  toContractPlans
} from '../../constants/base-config';
import { toContract } from '../../converters/contract-converter';
import { setContractId, toMasterSearchOptions } from '../../converters/space-converter';
import ContractRequest from '../../requests/contract-request';
import SpaceRequest from '../../requests/space-request';
import { Path, PATH_IDS } from '../../router/Routes';
import PriceService from '../../services/price-service';
import {
  CONTRACT_INFLOW_SOURCE_LABELS,
  CONTRACT_PAYMENT_METHOD_LABELS,
  CONTRACT_PAYMENT_TYPE_LABELS,
  CONTRACT_USE_ADDRESS_LABELS
} from '../../texts/contract-text';

const MASTER_SEARCH_LIMIT = 10;

interface P {
  base: BaseDto;
  onChange(path: Path): void;
}

const RegisterContractScreen: React.FC<P> = React.memo(props => {
  const { base, onChange } = props;
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [spaces, setSpaces] = useSafeState<Space[]>(unmountRef, []);
  const [contractNameToSave, setContractNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [representativeNameToSave, setReprensentativeNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [representativeEmailToSave, setReprensentativeEmailToSave] = useSafeState<Email>(unmountRef, EMPTY);
  const [startDateToSave, setStartDateToSave] = useSafeState<Date>(unmountRef, new Date());
  const [endDateToSave, setEndDateToSave] = useSafeState<Date>(unmountRef, new Date());
  const contractPlans = useMemo<Option[]>(() => toContractPlans(base.baseCode as Base), [base]);
  const [contractPlanToSave, setContractPlanToSave] = useSafeState<ContractPlan>(unmountRef, EMPTY as ContractPlan);
  const [selectedSpaceToSave, setSelectedSpaceToSave] = useSafeState<Space | undefined>(unmountRef);
  const [numberOfKeysToSave, setNumberOfKeysToSave] = useSafeState<Count>(unmountRef, ZERO);
  const [numberOfLockersToSave, setNumberOfLockersToSave] = useSafeState<Count>(unmountRef, ZERO);
  const [useAddressToSave, setUseAdressToSave] = useSafeState<UseAddress>(unmountRef, UseAddress.NO);
  const [paymentMethodToSave, setPaymentMethodToSave] = useSafeState<ContractPaymentMethod>(
    unmountRef,
    ContractPaymentMethod.CREDIT_CARD
  );
  const [paymentTypeToSave, setPaymentTypeToSave] = useSafeState<PaymentType>(unmountRef, PaymentType.SINGLE);
  const [inflowSourceToSave, setInflowSourceToSave] = useSafeState<InflowSource>(unmountRef, InflowSource.ORGANIC);
  const [serviceRetainerToSave, setServiceRetainerToSave] = useSafeState<Amount>(unmountRef, ZERO);
  const [taxIncludedInitialDiscountAmountToSave, setTaxIncludedInitialDiscountAmountToSave] = useSafeState<Amount>(
    unmountRef,
    ZERO
  );
  const [taxIncludedLongTermDiscountAmountToSave, setTaxIncludedLongTermDiscountAmountToSave] = useSafeState<Amount>(
    unmountRef,
    ZERO
  );
  const [longTermDiscountMonthsToSave, setLongTermDiscountMonthsToSave] = useSafeState<Month>(unmountRef, ZERO);
  const [billingAmountToSave, setBillingAmountToSave] = useSafeState<Amount>(unmountRef, ZERO);
  const [remarksToSave, setRemarksToSave] = useSafeState<Remarks>(unmountRef, EMPTY);

  const disabledSaveButton = useMemo<boolean>(() => {
    return (
      !contractNameToSave ||
      !representativeNameToSave ||
      !representativeEmailToSave ||
      !startDateToSave ||
      !contractPlanToSave ||
      !useAddressToSave ||
      !paymentMethodToSave ||
      !paymentTypeToSave
    );
  }, [
    contractNameToSave,
    representativeNameToSave,
    representativeEmailToSave,
    startDateToSave,
    contractPlanToSave,
    useAddressToSave,
    paymentMethodToSave,
    paymentTypeToSave
  ]);

  const taxExcludedPlanPriceToSave = useMemo<Amount>(
    () => toContractPlanPrice(base.baseCode as Base, contractPlanToSave),
    [base, contractPlanToSave]
  );

  const taxExcludedKeyIssuePriceToSave = useMemo<Amount>(
    () => numberOfKeysToSave * CONTRACT_KEY_ISSUE_PRICE,
    [numberOfKeysToSave]
  );

  const taxExcludedLockerPriceToSave = useMemo<Amount>(
    () => numberOfLockersToSave * CONTRACT_LOCKER_PRICE,
    [numberOfLockersToSave]
  );

  const taxExcludedAddressPriceToSave = useMemo<Amount>(
    () => (useAddressToSave === UseAddress.YES ? CONTRACT_ADDRESS_PRICE : ZERO),
    [useAddressToSave]
  );

  const taxIncludedTotalAmountToSave = useMemo<Amount>(() => {
    const taxExcludedAmount = taxExcludedPlanPriceToSave + taxExcludedLockerPriceToSave + taxExcludedAddressPriceToSave;
    return PriceService.toTaxIncludedPrice(taxExcludedAmount);
  }, [taxExcludedPlanPriceToSave, taxExcludedLockerPriceToSave, taxExcludedAddressPriceToSave]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!base) return;

    const request = builder<SearchSpacesForContractRequest>()
      .base(base)
      .limit(MASTER_SEARCH_LIMIT)
      .offset(ZERO)
      .word(EMPTY)
      .build();
    const response = await SpaceRequest.fetchSpacesForContract(request);

    hasLength(contractPlans) && setContractPlanToSave(contractPlans[0] as ContractPlan);
    setSpaces(response.spaces);
    setLoaded(true);
  }, [base, contractPlans, setContractPlanToSave, setSpaces, setLoaded]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    setBillingAmountToSave(taxIncludedTotalAmountToSave);
  }, [setBillingAmountToSave, taxIncludedTotalAmountToSave]);

  const openContractListScreen = useSafeCallback((): void => {
    if (!base) return;
    onChange(embedIdInPath(Path.CONTRACT_LIST, PATH_IDS, [base.baseCode]));
  }, [onChange, base]);

  const openContractDetailsScreen = useSafeCallback(
    (contract: Contract): void => {
      onChange(embedIdInPath(Path.CONTRACT_DETAILS, PATH_IDS, [contract.base, contract.contractId]));
    },
    [onChange]
  );

  const saveNewContract = useSafeCallback(async (): Promise<void> => {
    if (!base) return;
    setSaving(true);

    const contractToSave = toContract(
      uuid(),
      contractNameToSave,
      ContractStatus.VALID,
      startDateToSave,
      endDateToSave,
      representativeNameToSave,
      representativeEmailToSave,
      contractPlanToSave,
      paymentMethodToSave,
      paymentTypeToSave,
      useAddressToSave,
      numberOfKeysToSave,
      numberOfLockersToSave,
      taxExcludedPlanPriceToSave,
      taxExcludedKeyIssuePriceToSave,
      taxExcludedLockerPriceToSave,
      taxExcludedAddressPriceToSave,
      serviceRetainerToSave,
      taxIncludedInitialDiscountAmountToSave,
      taxIncludedLongTermDiscountAmountToSave,
      longTermDiscountMonthsToSave,
      taxIncludedTotalAmountToSave,
      billingAmountToSave,
      remarksToSave,
      inflowSourceToSave,
      base.baseCode as Base
    );

    const contractRequest = builder<SaveContractRequest>().contract(contractToSave).build();

    const invitationRequest = builder<SendInvitationToContractMembersRequest>()
      .base(base)
      .contract(contractToSave)
      .emails([contractToSave.representativeEmail])
      .build();

    await Promise.all([
      ContractRequest.saveContract(contractRequest),
      ContractRequest.sendInvitationToContractMembers(invitationRequest)
    ]);

    if (!!selectedSpaceToSave) {
      setContractId(selectedSpaceToSave, contractToSave);
      const spaceRequest = builder<SaveSpaceRequest>().space(selectedSpaceToSave).build();
      await SpaceRequest.saveSpace(spaceRequest);
    }

    openContractDetailsScreen(contractToSave);
  }, [
    setSaving,
    selectedSpaceToSave,
    contractNameToSave,
    startDateToSave,
    endDateToSave,
    representativeNameToSave,
    representativeEmailToSave,
    contractPlanToSave,
    paymentMethodToSave,
    paymentTypeToSave,
    useAddressToSave,
    numberOfKeysToSave,
    numberOfLockersToSave,
    taxExcludedPlanPriceToSave,
    taxExcludedKeyIssuePriceToSave,
    taxExcludedLockerPriceToSave,
    taxExcludedAddressPriceToSave,
    serviceRetainerToSave,
    taxIncludedInitialDiscountAmountToSave,
    taxIncludedLongTermDiscountAmountToSave,
    longTermDiscountMonthsToSave,
    taxIncludedTotalAmountToSave,
    billingAmountToSave,
    remarksToSave,
    inflowSourceToSave,
    base,
    openContractDetailsScreen
  ]);

  const handleSpaceNameInput = useSafeCallback(
    async (spaceName: Name): Promise<void> => {
      const request = builder<SearchSpacesForContractRequest>()
        .base(base)
        .limit(MASTER_SEARCH_LIMIT)
        .offset(ZERO)
        .word(spaceName)
        .build();
      const response = await SpaceRequest.fetchSpacesForContract(request);
      setSpaces(response.spaces);
    },
    [base, setSpaces]
  );

  const handleSpaceSelected = useSafeCallback(
    (option: MasterSearchOption): void => {
      const selectedSpace = spaces.find(space => space.spaceId === option.value);
      setSelectedSpaceToSave(selectedSpace);
    },
    [spaces, setSelectedSpaceToSave]
  );

  return (
    <Component
      loading={!loaded || saving}
      style={{ width: '100%', height: `calc(100vh - 64px)` }}
      className='register-contract-screen'
    >
      <Container>
        <Content>
          <SubTitle>契約情報の登録</SubTitle>
          <Title>Contract Registration</Title>

          <FullInputWrapper>
            <InputBox
              required
              autoFocus
              type='text'
              label='契約名'
              text={contractNameToSave}
              onChange={setContractNameToSave}
            />
          </FullInputWrapper>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                required
                type='text'
                label='代表者のお名前'
                text={representativeNameToSave}
                onChange={setReprensentativeNameToSave}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                required
                type='email'
                label='代表者のメールアドレス'
                text={representativeEmailToSave}
                onChange={setReprensentativeEmailToSave}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <MultiDateArea>
            <HalfDateWrapper>
              <DateBox required label='契約開始日' value={startDateToSave} onChange={setStartDateToSave} />
            </HalfDateWrapper>

            <HalfDateWrapper>
              <DateBox required={false} label='契約終了日' value={endDateToSave} onChange={setEndDateToSave} />
            </HalfDateWrapper>
          </MultiDateArea>

          {!!base && (
            <FullRadioWrapper>
              <RadioBox
                required
                title='プラン'
                options={contractPlans}
                labels={ContractPlanLabelByBase[base.baseCode]}
                value={contractPlanToSave}
                onChange={setContractPlanToSave}
              />
            </FullRadioWrapper>
          )}

          <FullInputWrapper>
            <MasterSearchBox
              placeholder='会議室'
              options={toMasterSearchOptions(spaces)}
              onInputChange={handleSpaceNameInput}
              onChange={handleSpaceSelected}
            />
          </FullInputWrapper>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                required
                type='number'
                label='利用登録人数（鍵発行数）'
                text={numberOfKeysToSave}
                onChange={setNumberOfKeysToSave}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                required
                type='number'
                label='ロッカー利用数'
                text={numberOfLockersToSave}
                onChange={setNumberOfLockersToSave}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <FullRadioWrapper>
            <RadioBox
              required
              title='住所利用'
              options={Object.values(UseAddress)}
              labels={CONTRACT_USE_ADDRESS_LABELS}
              value={useAddressToSave}
              onChange={setUseAdressToSave}
            />
          </FullRadioWrapper>

          <MultiRadioArea>
            <FlexibleRadioWrapper width={65}>
              <RadioBox
                required
                title='支払い方法'
                options={Object.values(ContractPaymentMethod)}
                labels={CONTRACT_PAYMENT_METHOD_LABELS}
                value={paymentMethodToSave}
                onChange={setPaymentMethodToSave}
              />
            </FlexibleRadioWrapper>

            <FlexibleRadioWrapper width={33}>
              <RadioBox
                required
                title='支払い回数'
                options={Object.values(PaymentType)}
                labels={CONTRACT_PAYMENT_TYPE_LABELS}
                value={paymentTypeToSave}
                onChange={setPaymentTypeToSave}
              />
            </FlexibleRadioWrapper>
          </MultiRadioArea>

          <FullRadioWrapper>
            <RadioBox
              title='流入経路'
              options={Object.values(InflowSource)}
              labels={CONTRACT_INFLOW_SOURCE_LABELS}
              value={inflowSourceToSave}
              onChange={setInflowSourceToSave}
            />
          </FullRadioWrapper>

          <SubTitle>料金</SubTitle>
          <Title>Prices</Title>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='プラン料金（税込）'
                text={PriceService.toTaxIncludedPrice(taxExcludedPlanPriceToSave)}
                onChange={noop}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='鍵発行料金（税込）'
                text={PriceService.toTaxIncludedPrice(taxExcludedKeyIssuePriceToSave)}
                onChange={noop}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='ロッカー利用料金（税込）'
                text={PriceService.toTaxIncludedPrice(taxExcludedLockerPriceToSave)}
                onChange={noop}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='住所利用料金（税込）'
                text={PriceService.toTaxIncludedPrice(taxExcludedAddressPriceToSave)}
                onChange={noop}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                editable
                type='number'
                label='サービスリテイナー'
                text={serviceRetainerToSave}
                onChange={setServiceRetainerToSave}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                editable
                type='number'
                label='初月割引額（税込）'
                text={taxIncludedInitialDiscountAmountToSave}
                onChange={setTaxIncludedInitialDiscountAmountToSave}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                editable
                type='number'
                label='1ヶ月あたりの長期割引額（税込）'
                text={taxIncludedLongTermDiscountAmountToSave}
                onChange={setTaxIncludedLongTermDiscountAmountToSave}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                editable
                type='number'
                label='割引期間（月）'
                text={longTermDiscountMonthsToSave}
                onChange={setLongTermDiscountMonthsToSave}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <MultiInputArea>
            <HalfInputWrapper>
              <InputBox
                editable={false}
                type='number'
                label='合計金額（税込）'
                text={taxIncludedTotalAmountToSave}
                onChange={noop}
              />
            </HalfInputWrapper>

            <HalfInputWrapper>
              <InputBox
                editable
                type='number'
                label='請求金額（税込）'
                text={billingAmountToSave}
                onChange={setBillingAmountToSave}
              />
            </HalfInputWrapper>
          </MultiInputArea>

          <FullInputWrapper>
            <InputBox editable type='text' label='備考' text={remarksToSave} onChange={setRemarksToSave} />
          </FullInputWrapper>

          <MultiButtonsWrapper>
            <ButtonWrapper>
              <Button type='secondary' onClick={openContractListScreen}>
                <Label>契約一覧に戻る</Label>
              </Button>
            </ButtonWrapper>

            <ButtonWrapper>
              <Button type='primary' disabled={disabledSaveButton} onClick={saveNewContract}>
                <Label>契約の登録</Label>
              </Button>
            </ButtonWrapper>
          </MultiButtonsWrapper>
        </Content>
      </Container>
    </Component>
  );
});

export default RegisterContractScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  height: auto;
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  margin: ${theme.mixins.spacing * 4}px 0px 0px ${theme.mixins.spacing * 3}px;
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 56px;
  margin: 0px 0px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px;
`;

const MultiRadioArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const FullRadioWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const FlexibleRadioWrapper = styled.div<{ width: Width }>`
  width: ${props => props.width}%;
  height: auto;
`;

const MultiInputArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const FullInputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const HalfInputWrapper = styled.div`
  width: 49%;
  height: auto;
`;

const MultiDateArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const HalfDateWrapper = styled.div`
  width: 49%;
  height: auto;
`;

const MultiButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 4}px;
`;

const ButtonWrapper = styled.div`
  padding: 0px ${theme.mixins.spacing / 2}px;
`;

const Label = styled(Typography)`
  width: 160px;
  height: auto;
  color: ${theme.mixins.typography.fontColor.white};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
`;
