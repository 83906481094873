import { Component, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseDto,
  SaveSpaceParticipantRequest,
  SHARED_SPACE_RESERVATION_END_AT,
  SHARED_SPACE_RESERVATION_ID,
  SHARED_SPACE_RESERVATION_START_AT,
  SpaceParticipantDiv,
  User
} from '@atomica.co/irori';
import { builder } from '@atomica.co/utils';
import React, { CSSProperties, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import usePath from '../../../redux/hooks/usePath';
import SpaceParticipantRequest from '../../../requests/space-participant-request';

interface P extends RouteComponentProps {
  base: BaseDto;
  user: User;
  onSave(): void;
}

const MyAccountShare: React.FC<P> = React.memo(props => {
  const { base, user, onSave } = props;
  const { queryParams } = usePath();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [isShared, setIsShared] = useSafeState<boolean>(unmountRef);

  const saveAsSpaceReservationParticipant = useSafeCallback(async (): Promise<void> => {
    const sharedSpaceReservationId = queryParams[SHARED_SPACE_RESERVATION_ID];
    const sharedSpaceReservationStartAt = new Date(decodeURIComponent(queryParams[SHARED_SPACE_RESERVATION_START_AT]!));
    const sharedSpaceReservationEndAt = new Date(decodeURIComponent(queryParams[SHARED_SPACE_RESERVATION_END_AT]!));

    if (
      !user ||
      !sharedSpaceReservationId ||
      !sharedSpaceReservationStartAt ||
      !sharedSpaceReservationEndAt ||
      new Date() > sharedSpaceReservationEndAt
    ) {
      setLoaded(true);
      setIsShared(false);
      return;
    }

    const request = builder<SaveSpaceParticipantRequest>()
      .base(base)
      .user(user)
      .spaceReservationId(sharedSpaceReservationId)
      .participantDiv(SpaceParticipantDiv.MEMBER)
      .startAt(sharedSpaceReservationStartAt)
      .endAt(sharedSpaceReservationEndAt)
      .build();

    const response = await SpaceParticipantRequest.saveSpaceParticipant(request);

    if (!response.spaceParticipantId) {
      setLoaded(true);
      setIsShared(false);
      return;
    }

    onSave();
  }, [queryParams, setLoaded, setIsShared, base, user, onSave]);

  const initialize = useSafeCallback((): void => {
    saveAsSpaceReservationParticipant();
  }, [saveAsSpaceReservationParticipant]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component loading={!loaded} style={styleForComponent} className='my-account-share'>
      <Container>{isShared === false && <Label>このリンクは無効です</Label>}</Container>
    </Component>
  );
});

export default MyAccountShare;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: 'calc(100vh - 72px)',
  display: 'flex',
  justifyContent: 'center'
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
`;
