import { Component, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Base, BASE_CODE, FetchTodayUsersRequest, toLatestUserPath, UserEntity, UserId } from '@atomica.co/irori';
import { builder, embedIdInPath } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import MemberPolaroid from '../../components/card/MemberPolaroid';
import ShotPolaroid from '../../components/card/ShotPolaroid';
import { database } from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import UserRequest from '../../requests/user-request';
import { Path, PATH_IDS } from '../../router/Routes';
import { getRotate } from '../../utils/common-util';

interface P extends RouteComponentProps {
  onChange(path: Path): void;
}

const VisitorsScreen: React.FC<P> = React.memo(props => {
  const { onChange } = props;
  const { params } = usePath();
  const unmountRef = useUnmountRef();
  const [shotUsers, setShotUsers] = useSafeState<UserEntity[]>(unmountRef, []);
  const [members, setMembers] = useSafeState<UserEntity[]>(unmountRef, []);

  const base = useMemo<Base>(() => {
    return params[BASE_CODE];
  }, [params]);

  const loadTodayUsers = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchTodayUsersRequest>().base(base).build();

    const response = await UserRequest.fetchTodayUsers(request);

    setShotUsers(response.shotUsers);
    setMembers(response.members);
  }, [base, setShotUsers, setMembers]);

  useEffect(() => {
    loadTodayUsers();
  }, [loadTodayUsers]);

  useEffect(() => {
    const ref = database.ref(toLatestUserPath(base));
    ref.on('value', () => loadTodayUsers());
  }, [base, loadTodayUsers]);

  const handleCardClicked = useSafeCallback(
    (userId: UserId): void => {
      onChange(embedIdInPath(Path.USER_DETAILS, PATH_IDS, [base, userId]));
    },
    [onChange, base]
  );

  return (
    <Component className='visitors-screen'>
      <Container>
        <SubTitle>ショット利用者</SubTitle>

        <Title>Shot Users</Title>

        <PolaroidsWrapper>
          {shotUsers.map((user, index) => (
            <ShotPolaroid key={index} rotate={getRotate()} user={user} onClick={handleCardClicked} />
          ))}
        </PolaroidsWrapper>

        <SubTitle>会員利用者</SubTitle>

        <Title>Members</Title>

        <PolaroidsWrapper>
          {members.map((user, index) => (
            <MemberPolaroid key={index} rotate={getRotate()} user={user} onClick={handleCardClicked} />
          ))}
        </PolaroidsWrapper>
      </Container>
    </Component>
  );
});

export default VisitorsScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const SubTitle = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  margin-top: 24px;
`;

const Title = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: 56px;
  font-family: ${theme.mixins.typography.fontFamily};
  font-weight: ${theme.mixins.typography.fontWeight.nineHundreds};
  line-height: 64px;
`;

const PolaroidsWrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 336px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  padding: ${theme.mixins.spacing}px 0px ${theme.mixins.spacing * 3}px;
`;
