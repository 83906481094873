import {
  CircularLoader,
  Component,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  theme,
  Tips,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BaseDto, FetchSpaceReservationsByUserRequest, SpaceReservation, User } from '@atomica.co/irori';
import { builder, getEmptyObj, hasLength, Offset, ZERO } from '@atomica.co/utils';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import React, { useEffect, useMemo, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { BOTTOM_NAGIGATOR_HEIGHT, SUB_BUTTON_HEIGHT } from '../../../constants/my-account-consts';
import { convertToSpaceReservationsGrid, SpaceReservationsGrid } from '../../../converters/my-account-converter';
import usePath from '../../../redux/hooks/usePath';
import SpaceReservationRequest from '../../../requests/space-reservation-request';
import { Path } from '../../../router/Routes';
import ReservationDetail from './reservation-detail/ReservationDetail';
import ReservationsGrid from './reservations-grid/ReservationsGrid';

const LIMIT = 20;

const TIPS_MESSAGE = '会議室の予約をするか\n会議室の予約の招待を受け取ると\n本日の予定が表示されます。';

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P extends RouteComponentProps {
  user: User;
  base: BaseDto;
}

const MyAccountReservations: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { openBasePath } = usePath();
  const unmountRef = useUnmountRef();
  const bottomRef = useRef<HTMLDivElement>();
  const initialized = useRef<boolean>(false);
  const offset = useRef<Offset>(ZERO);
  const hasMore = useRef<boolean>(true);
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [isOpenDetail, setIsOpenDetail] = useSafeState<boolean>(unmountRef, false);
  const [spaceReservationsGrid, setSpaceReservationsGrid] = useSafeState<SpaceReservationsGrid>(
    unmountRef,
    getEmptyObj()
  );
  const [selectedSpaceReservation, setSelectedSpaceReservation] = useSafeState<SpaceReservation | undefined>(
    unmountRef
  );

  const isExistedSpaceReservations = useMemo<boolean>(
    () => hasLength(Object.keys(spaceReservationsGrid)),
    [spaceReservationsGrid]
  );

  const loadSpaceReservations = useSafeCallback(async (): Promise<void> => {
    if (!hasMore.current) return;

    const request = builder<FetchSpaceReservationsByUserRequest>()
      .base(base)
      .user(user)
      .fromDate(new Date())
      .limit(LIMIT)
      .offset(offset.current)
      .build();
    const response = await SpaceReservationRequest.fetchSpaceReservationsByUser(request);
    const reservations = response.spaceReservations;

    offset.current += LIMIT;
    hasMore.current = reservations.length === LIMIT;

    const gridToAdd = convertToSpaceReservationsGrid(reservations);
    setSpaceReservationsGrid(prevGrid => ({ ...prevGrid, ...gridToAdd }));
    setLoaded(true);
  }, [base, user, setSpaceReservationsGrid, setLoaded]);

  const onScroll = useSafeCallback(
    async (entries: IntersectionObserverEntry[]): Promise<void> => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        await loadSpaceReservations();
      }
    },
    [loadSpaceReservations]
  );

  useEffect(() => {
    if (initialized.current) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);
    bottomRef.current && observer.observe(bottomRef.current);
    initialized.current = true;

    return () => {
      observer.disconnect();
    };
  }, [initialized, onScroll]);

  const openDetail = useSafeCallback(
    (spaceReservation: SpaceReservation): void => {
      setSelectedSpaceReservation(spaceReservation);
      setIsOpenDetail(true);
    },
    [setSelectedSpaceReservation, setIsOpenDetail]
  );

  const closeDetail = useSafeCallback(async (): Promise<void> => {
    setIsOpenDetail(false);
    setSelectedSpaceReservation(undefined);
  }, [setIsOpenDetail, setSelectedSpaceReservation]);

  return (
    <Component className='my-account-reservations'>
      <Container>
        {!loaded && (
          <LoaderWrapper>
            <CircularLoader />
          </LoaderWrapper>
        )}

        {loaded && (
          <>
            {!isOpenDetail && (
              <>
                {isExistedSpaceReservations && (
                  <ReservationsGrid spaceReservationsGrid={spaceReservationsGrid} onClickCard={openDetail} />
                )}

                {!isExistedSpaceReservations && (
                  <NoReservationWrapper>
                    <Tips message={TIPS_MESSAGE} />
                  </NoReservationWrapper>
                )}
              </>
            )}

            {isOpenDetail && !!selectedSpaceReservation && (
              <ReservationDetail
                base={base}
                spaceReservation={selectedSpaceReservation}
                user={user}
                onClickBack={closeDetail}
              />
            )}
          </>
        )}

        <AddReservationWrapper>
          <IconWrapper onClick={() => openBasePath(Path.RESERVE_SPACE)}>
            <CustomAddOutlinedIcon />
          </IconWrapper>
        </AddReservationWrapper>

        <Bottom ref={bottomRef} />
      </Container>
    </Component>
  );
});

export default MyAccountReservations;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const LoaderWrapper = styled.div`
  width: min-content;
  height: min-content;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

const NoReservationWrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${theme.mixins.spacing * 15}px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddReservationWrapper = styled.div`
  width: 100%;
  height: ${SUB_BUTTON_HEIGHT}px;
  max-width: ${MOBILE_MAX_WIDTH}px;
  min-width: ${MOBILE_MIN_WIDTH}px;
  position: fixed;
  right: 8px;
  bottom: ${BOTTOM_NAGIGATOR_HEIGHT + 8}px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 ${theme.mixins.spacing}px;
  pointer-events: none;
`;
const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background: ${theme.mixins.background.white};
  pointer-events: auto;
  cursor: pointer;
`;
const CustomAddOutlinedIcon = styled(AddOutlinedIcon)`
  font-size: ${theme.mixins.typography.fontSize.twentyEight}px;
`;

const Bottom = styled.div``;
