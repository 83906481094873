import { theme } from '@atomica.co/components';
import { SpaceAvailability } from '@atomica.co/irori';
import { hasLength } from '@atomica.co/utils';
import React from 'react';
import styled from 'styled-components';
import { convertToAvailableTimeLabels } from '../../../converters/space-converter';

interface P {
  availabilities: SpaceAvailability[] | undefined;
}

const AvailableTimeTable: React.FC<P> = React.memo(props => {
  const { availabilities } = props;
  const availableTimeLabels = convertToAvailableTimeLabels(availabilities);

  return hasLength(availableTimeLabels) ? (
    <>
      <tr>
        <Td colSpan='3'>ご利用可能時間</Td>
      </tr>

      {availableTimeLabels.map((row, idx) => (
        <Tr key={idx}>
          <Td colSpan='1'>{row.weekday}</Td>
          <Td colSpan='2'>{row.time}</Td>
        </Tr>
      ))}

      <tr>
        <td>
          <Spacer />
        </td>
      </tr>
    </>
  ) : (
    <></>
  );
});
export default AvailableTimeTable;

const Td = styled.td`
  font-size: 14px;
  font-family: ${theme.mixins.typography.fontFamily};
  padding-right: ${theme.mixins.spacing}px;
  vertical-align: top;
`;

const Tr = styled.tr`
  padding-left: 3px;
`;

const Spacer = styled.div`
  padding-bottom: ${theme.mixins.spacing}px;
`;
