import {
  Access,
  ACCESS_ACCESS_DATE,
  ACCESS_ACCESS_TYPE,
  Base,
  BASE,
  CONTRACT,
  Contract,
  ContractUsage,
  CONTRACT_CONTRACT_PLAN,
  CONTRACT_CONTRACT_STATUS,
  CONTRACT_END_DATE,
  CONTRACT_INFLOW_SOURCE,
  CONTRACT_PAYMENT_METHOD,
  CONTRACT_PAYMENT_TYPE,
  CONTRACT_START_DATE,
  CONTRACT_USAGE_CONTRACT_MSEC,
  CONTRACT_USAGE_REMAINING_MSEC,
  CONTRACT_USAGE_USAGE_MSEC,
  CONTRACT_USE_ADDRESS,
  EventParticipant,
  EventSchedule,
  EVENT_PARTICIPANT_BASE,
  EVENT_PARTICIPANT_INFLOW_SOURCE,
  EVENT_PARTICIPANT_SCHEDULE,
  EVENT_PARTICIPANT_USER,
  EVENT_SCHEDULE_DUE_DATE,
  EVENT_SCHEDULE_END_AT,
  EVENT_SCHEDULE_START_AT,
  Shot,
  SHOT_CONFERENCE_ONE_DAY_USAGE,
  SHOT_CONFERENCE_USAGE_TYPE,
  SHOT_PAYMENT_METHOD,
  SHOT_USAGE_OPTION,
  SHOT_USAGE_TYPE,
  User,
  USER,
  USER_CATEGORY,
  USER_DATE_OF_BIRTH,
  USER_INFLOW_SOURCE,
  USER_PHONE,
  USER_PREFECTURE
} from '@atomica.co/irori';
import {
  CREATED_AT,
  EMPTY,
  MILLI_SECOND,
  toDateStr,
  toDateTimeStr,
  toUTC,
  UNIX_TIME_ONE_HOUR,
  UPDATED_AT,
  ZERO
} from '@atomica.co/utils';
import {
  BaseInfo,
  ContractPlanLabelByBase as CONTRACT_PLAN_LABEL_BY_BASE,
  toConferenceUsageTypeLabel,
  toUsageOptionPriceLabel,
  USAGE_TYPE_LABELS
} from '../constants/base-config';
import { CONTRACT_STATUS_LABELS } from '../constants/contract-const';
import { ACCESS_LABELS } from '../texts/access-text';
import { PREFECTURE_LABELS } from '../texts/common-text';
import {
  CONTRACT_INFLOW_SOURCE_LABELS,
  CONTRACT_PAYMENT_METHOD_LABELS,
  CONTRACT_PAYMENT_TYPE_LABELS
} from '../texts/contract-text';
import { EVENT_INFLOW_SOURCE_LABELS } from '../texts/event-text';
import { PAYMENT_METHOD_LABELS as SHOT_PAYMENT_METHOD_LABELS } from '../texts/shot-text';
import { USER_CATEGORY_LABELS, USER_INFLOW_SOURCE_LABELS } from '../texts/user-text';

const DUMMY: any[] = [
  {
    hoge: EMPTY
  }
];

export const convertToShotCSV = (shotUsageList: Shot[]): Shot[] => {
  if (!shotUsageList.length) return DUMMY;

  return shotUsageList.map(shotUsage => {
    const shotUsageToExport = {};

    Object.entries(shotUsage).forEach(([key, value]) => {
      switch (key) {
        case USER:
          setUserProps(value, shotUsageToExport);
          break;

        case BASE:
          shotUsageToExport[key] = BaseInfo[value].name;
          break;

        case SHOT_USAGE_TYPE:
          shotUsageToExport[key] = USAGE_TYPE_LABELS[value];
          break;

        case SHOT_USAGE_OPTION:
          shotUsageToExport[key] = toUsageOptionPriceLabel(shotUsage.base, shotUsage.usageType, value);
          break;

        case SHOT_CONFERENCE_USAGE_TYPE:
          shotUsageToExport[key] = toConferenceUsageTypeLabel(shotUsage.base, value);
          break;

        case SHOT_CONFERENCE_ONE_DAY_USAGE:
          shotUsageToExport[key] = !!value ? '有' : '無';
          break;

        case SHOT_PAYMENT_METHOD:
          shotUsageToExport[key] = SHOT_PAYMENT_METHOD_LABELS[value];
          break;

        case CREATED_AT:
          shotUsageToExport[key] = toDateTimeStr(new Date(value));
          break;

        default:
          shotUsageToExport[key] = value;
          break;
      }
    });

    return shotUsageToExport as Shot;
  });
};

export const convertToContractsCSV = (base: Base, contracts: Contract[]): Contract[] => {
  if (!contracts.length) return DUMMY;

  return contracts.map(contract => {
    const contractToExport = {};

    Object.entries(contract).forEach(([key, value]) => {
      switch (key) {
        case BASE:
          contractToExport[key] = BaseInfo[value].name;
          break;

        case CONTRACT_CONTRACT_STATUS:
          contractToExport[key] = CONTRACT_STATUS_LABELS[value];
          break;

        case CONTRACT_START_DATE:
        case CONTRACT_END_DATE:
          contractToExport[key] = !!value ? toDateTimeStr(toUTC(new Date(value))!) : EMPTY;
          break;

        case CREATED_AT:
          contractToExport[key] = toDateTimeStr(new Date(value));
          break;

        case CONTRACT_CONTRACT_PLAN:
          contractToExport[key] = CONTRACT_PLAN_LABEL_BY_BASE[base][value];
          break;

        case CONTRACT_PAYMENT_METHOD:
          contractToExport[key] = CONTRACT_PAYMENT_METHOD_LABELS[value];
          break;

        case CONTRACT_PAYMENT_TYPE:
          contractToExport[key] = CONTRACT_PAYMENT_TYPE_LABELS[value];
          break;

        case CONTRACT_USE_ADDRESS:
          contractToExport[key] = !!value ? '有' : '無';
          break;

        case CONTRACT_INFLOW_SOURCE:
          contractToExport[key] = CONTRACT_INFLOW_SOURCE_LABELS[value];
          break;

        default:
          contractToExport[key] = value;
          break;
      }
    });

    return contractToExport as Contract;
  });
};

export const convertToContractUsagesCSV = (base: Base, usages: ContractUsage[]): ContractUsage[] => {
  if (!usages.length) return DUMMY;

  return usages.map(usage => {
    const usageToExport = {};

    Object.entries(usage).forEach(([key, value]) => {
      switch (key) {
        case CONTRACT:
          setContractProps(base, usage.contract, usageToExport);
          break;

        case BASE:
          usageToExport[key] = BaseInfo[value].name;
          break;

        case CONTRACT_USAGE_CONTRACT_MSEC:
        case CONTRACT_USAGE_USAGE_MSEC:
        case CONTRACT_USAGE_REMAINING_MSEC:
          usageToExport[key] = !!value ? Math.round(value / (UNIX_TIME_ONE_HOUR * MILLI_SECOND)) : ZERO;
          break;

        default:
          usageToExport[key] = value;
          break;
      }
    });

    return usageToExport as ContractUsage;
  });
};

export const convertToAccessesCSV = (accesses: Access[]): Access[] => {
  if (!accesses.length) return DUMMY;

  return accesses.map(access => {
    const accessToExport = {};

    Object.entries(access).forEach(([key, value]) => {
      switch (key) {
        case USER:
          setUserProps(value, accessToExport);
          break;

        case BASE:
          accessToExport[key] = BaseInfo[value].name;
          break;

        case ACCESS_ACCESS_TYPE:
          accessToExport[key] = ACCESS_LABELS[value];
          break;

        case ACCESS_ACCESS_DATE:
          accessToExport[key] = toDateTimeStr(toUTC(new Date(value))!);
          break;

        default:
          accessToExport[key] = value;
          break;
      }
    });

    return accessToExport as Access;
  });
};

export const convertToParticipantsCSV = (participants: EventParticipant[]): EventParticipant[] => {
  if (!participants.length) return DUMMY;

  return participants.map(participant => {
    const participantToExport = {};

    Object.entries(participant).forEach(([key, value]) => {
      switch (key) {
        case EVENT_PARTICIPANT_USER:
          setUserProps(value, participantToExport);
          break;

        case EVENT_PARTICIPANT_SCHEDULE:
          setEventScheduleProps(value, participantToExport);
          break;

        case EVENT_PARTICIPANT_INFLOW_SOURCE:
          participantToExport[key] = EVENT_INFLOW_SOURCE_LABELS[value];
          break;

        case EVENT_PARTICIPANT_BASE:
          participantToExport[key] = BaseInfo[value].name;
          break;

        case CREATED_AT:
          participantToExport[key] = toDateTimeStr(new Date(value));
          break;

        default:
          participantToExport[key] = value;
          break;
      }
    });

    return participantToExport as EventParticipant;
  });
};

const setUserProps = (user: User, objectToExport: Object): void => {
  Object.entries(user).forEach(([key, value]) => {
    switch (key) {
      case CREATED_AT:
      case UPDATED_AT:
        break;

      case USER_DATE_OF_BIRTH:
        objectToExport[key] = toDateStr(new Date(value));
        break;

      case USER_PHONE:
        objectToExport[key] = `'${value}`;
        break;

      case USER_PREFECTURE:
        objectToExport[key] = !!value ? PREFECTURE_LABELS[value] : EMPTY;
        break;

      case USER_CATEGORY:
        objectToExport[key] = USER_CATEGORY_LABELS[value];
        break;

      case USER_INFLOW_SOURCE:
        objectToExport[key] = !!USER_INFLOW_SOURCE_LABELS[value] ? USER_INFLOW_SOURCE_LABELS[value] : value;
        break;

      default:
        objectToExport[key] = value;
        break;
    }
  });
};

const setContractProps = (base: Base, contract: Contract, objectToExport: Object): void => {
  Object.entries(contract).forEach(([key, value]) => {
    switch (key) {
      case CONTRACT_CONTRACT_STATUS:
        objectToExport[key] = CONTRACT_STATUS_LABELS[value];
        break;

      case CONTRACT_START_DATE:
      case CONTRACT_END_DATE:
        objectToExport[key] = toDateTimeStr(new Date(value));
        break;

      case CONTRACT_CONTRACT_PLAN:
        objectToExport[key] = CONTRACT_PLAN_LABEL_BY_BASE[base][value];
        break;

      case CONTRACT_PAYMENT_METHOD:
        objectToExport[key] = CONTRACT_PAYMENT_METHOD_LABELS[value];
        break;

      case CONTRACT_PAYMENT_TYPE:
        objectToExport[key] = CONTRACT_PAYMENT_TYPE_LABELS[value];
        break;

      case CONTRACT_USE_ADDRESS:
        objectToExport[key] = !!value ? '有' : '無';
        break;

      case CONTRACT_INFLOW_SOURCE:
        objectToExport[key] = CONTRACT_INFLOW_SOURCE_LABELS[value];
        break;

      default:
        objectToExport[key] = value;
        break;
    }
  });
};

const setEventScheduleProps = (schedule: EventSchedule, objectToExport: Object): void => {
  Object.entries(schedule).forEach(([key, value]) => {
    switch (key) {
      case CREATED_AT:
      case UPDATED_AT:
        break;

      case EVENT_SCHEDULE_DUE_DATE:
        objectToExport[key] = toDateTimeStr(new Date(value));
        break;

      case EVENT_SCHEDULE_START_AT:
        objectToExport[key] = toDateTimeStr(new Date(value));
        break;

      case EVENT_SCHEDULE_END_AT:
        objectToExport[key] = toDateTimeStr(new Date(value));
        break;

      default:
        objectToExport[key] = value;
        break;
    }
  });
};
