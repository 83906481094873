import { useSafeState, useUnmountRef } from '@atomica.co/components';
import { useEffect } from 'react';
import { Process } from '../../enums/action-enum';
import { toProcessAction } from '../actions/process-action';
import store from '../store';

function useProcess() {
  const unmountRef = useUnmountRef();
  const [process, setProcess] = useSafeState<Process>(unmountRef, store.getState().process);

  useEffect(() => {
    if (!process) return;
    const action = toProcessAction(process);
    store.dispatch(action);
  }, [process]);

  return {
    process,
    setProcess
  };
}

export default useProcess;
